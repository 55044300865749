@mixin common-form-select {
	$parent-selector: &;
	position: relative;
	user-select: none;
	&__select {
		position: absolute;
		top: 0;
		left: -100%;
		height: 0;
		width: 0;
		opacity: 0;
	}
	&__list {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 10;
		display: none;
		box-sizing: border-box;
		width: 100%;
		overflow: auto;
		li {
			box-sizing: border-box;
			width: 100%;
			height: auto;
			line-height: normal;
			cursor: pointer;
			transition: background-color 0.2s ease-out;
			&:first-child {
				display: none;
			}
		}
	}
	&__field {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		line-height: normal;
		cursor: pointer;
		overflow: hidden;
	}
	&__label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&__arrow {
		position: absolute;
		top: 0;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		transition: transform 0.2s ease-out;
		will-change: transform;
	}
}

.form-select {
	$parent-selector: &;
	@include common-form-select;
	&__list {
		max-height: 250px;
		background: $c-white;
		border: 1px solid $c-beige-form-border;
		@include min-screen($breakpoint-sm) {
			max-height: 300px;
		}
		li {
			@extend %specific-form-field;
			background-color: transparent;
			&._selected {
				background-color: $c-beige;
				color: $c-white;
			}
			@include desktop {
				&:not(._selected):hover {
					background-color: $c-beige-form;
				}
			}
		}
	}
	&__field {
		@extend %specific-form-field;
		padding-right: 42px;
		@include min-screen($breakpoint-sm) {
			padding-right: 50px;
		}
		&._opened {
			#{$parent-selector} {
				&__arrow {
					transform: rotate(180deg);
				}
			}
		}
		._float-label & {
			@extend %specific-form-float-field;
		}
	}
	&__label {
		color: $c-gray-placeholder;
		&._selected {
			color: $c-dark;
		}
	}
	&__float-label {
		@extend %common-form-float-label, %specific-form-float-label;
	}
	&__arrow {
		right: 16px;
		width: 10px;
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%238D3332' stroke-width='1.5'/%3E%3C/svg%3E%0A");
		@include min-screen($breakpoint-sm) {
			right: 21px;
		}
	}
	&:not(._once-selected) {
		#{$parent-selector} {
			&__float-label {
				@extend %specific-form-float-label-initial;
			}
		}
	}
}
