.service-cost {
	$block: &;
	margin-top: 20px;

	@include min-screen($breakpoint-sm) {
		margin-top: 25px;
	}
	@include min-screen($breakpoint-md) {
		margin-top: 20px;
	}
	&__title {
		color:#8d3332;
		@extend .title-h4;
		margin-bottom: 25px;
		max-width: 450px;
		@include smoothValue('max-width', 450px, 638px, $breakpoint-xs, $breakpoint-md);
		@include smoothValue('max-width', 638px, 1045px, $breakpoint-md, $breakpoint-lg);

		@include min-screen($breakpoint-xs) {
			margin-bottom: 30px;
		}
		@include min-screen($breakpoint-sm) {
			max-width: 450px;
		}
		@include min-screen($breakpoint-lg) {
			margin-bottom: 25px;
		}
		//@include smoothValue('margin-bottom', 25px, 40px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('margin-bottom', 25px, 50px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('max-width', 450px, 638px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('max-width', 638px, 1045px, $breakpoint-md, $breakpoint-lg);
	}
	&__wrap {
		@include min-screen($breakpoint-md) {
			border-top: 1px solid $c-light-beige;
			padding-top: 20px;
		}
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding-left;
		}
	}
	&__inner {
		@include min-screen($breakpoint-lg) {
			max-width: 12/15 * 100%;
		}
	}
	&__search {
		display: flex;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $c-light-beige;
		@include min-screen($breakpoint-sm) {
		}

		form {
			width: 100%;
			max-width: 405px;
		}
	}
	&__accordion {
		position: relative;
		&._simple {
			margin-top: 0;
			#{$block} {
				&__title {
					font-size: 14px;
					line-height: 1.6;
					@include min-screen($breakpoint-sm) {
						font-size: 16px;
					}
				}
			}
		}
	}
	&__icon {
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		transform: rotate(-135deg);
		color: $c-burgundy;
		align-self: flex-start;
		cursor: pointer;
		user-select: none;
		@extend .transition-move;

		@include min-screen($breakpoint-sm) {
			width: 24px;
			height: 24px;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			top: 20px;
		}
		@include min-screen($breakpoint-lg) {
			//left: 0;
		}
	}
	&__head {
		position: relative;
		width: 100%;
		background-color: #ece7e2;
		display: flex;
		align-items: center;
		padding: 20px 15px;
		box-sizing: border-box;
		border-bottom: 1px solid #ddd8d1;
		cursor: pointer;
	}
	&__line {
		position: relative;
		&._active {
			#{$block} {
				&__title {
					color: $c-burgundy;
				}
				&__body {
					display: flex;
					flex-wrap: wrap;
					overflow: visible;
					white-space: initial;
				}
				&__icon {
					color: $c-beige;
					transform: rotate(0);
				}
				&__text {
					color: $c-dark;
				}
			}
		}
		&:last-child {
			#{$block} {
				&__head {
					border-bottom: none;
				}
			}
		}
	}
	&__title {
		color: $c-burgundy;
	}
	&__subtitle {
		background-color: $c-light-beige;
		display: flex;
		align-items: center;
		padding: 10px;
		width: 100%;
		font-weight: 500;
		line-height: 1.6;
		color: #1b1b1b;
		box-sizing: border-box;
		@include min-screen($breakpoint-xs) {
			padding: 15px;
		}
		@include min-screen($breakpoint-md) {
			padding: 25px 20px;
		}
	}
	&__body {
		display: none;
		font-size: 14px;

		._book {
			font-family: 'Uni Neue Book', sans-serif;
		}

		@include min-screen($breakpoint-md) {
			font-size: 16px;
		}
		&._always-show {
			display: block;
		}
	}
	&__table {
		width: 100%;
		tr {
			box-sizing: border-box;
			td:first-child {
				font-family: 'Uni Neue Book', sans-serif;
			}
		}
		tr + tr {
			border-top: 1px solid $c-light-beige;
		}
		td:not(:first-child) {
			width: 83px;
			text-align: right;
		}
		@include max-screen($breakpoint-xs - 1px) {
			tr {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 10px;
				td:first-child {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}
		@include min-screen($breakpoint-xs) {
			td {
				padding: 15px;
				box-sizing: border-box;
			}
			td:not(:first-child) {
				width: 103px;
			}
		}
		@include min-screen($breakpoint-md) {
			td {
				padding: 25px 20px;
			}
		}
		&._head {
			@include max-screen($breakpoint-xs - 1px) {
				display: none;
			}
			td {
				text-transform: uppercase;
				padding-bottom: 10px;
				font-family: 'Uni Neue Book', sans-serif;
				@include min-screen($breakpoint-md) {
					padding-bottom: 15px;
				}
			}
		}
	}
}
