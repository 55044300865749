.about-team {
  &__wrap {
	border-bottom: 1px solid $c-light-beige;
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding;
	}
	margin-bottom: 30px;
  }
  &__lead-in {
	max-width: 785px;
	font: 300 17px/1.6 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 17px, 19px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-md);
	margin-bottom: 30px;
  }

  &__doctors {
	margin-bottom: 30px;
	@include smoothValue('padding-bottom', 0px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 20px, 50px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-md) {
	  border-bottom: 1px solid $c-light-beige;
	}
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding;
	}
	.catalog-list {
	  @include min-screen($breakpoint-md) {
		grid-template-columns: repeat(3, 1fr);
	  }
	  @include min-screen($breakpoint-fhd) {
		grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	  }
	}

	.doctors-slider {
	  &__info {
		margin-top: 10px;
		width: 303px;
	  }
	  &__name {
		color: $c-burgundy;
		font-size: 20px;
		line-height: 1.4;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	  }
	  &__position {
		font-size: 10px;
		line-height: 1.7;
		margin-top: 10px;
	  }
	}
  }
  &__subheading {
	@extend .tiny-section-mb;
	font: 300 22px/26px 'Museo Sans Cyrl', sans-serif;
	color: $c-burgundy;
	@include smoothValue('font-size', 22px, 36px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-lg) {
	  line-height: 1.3;
	}
  }
  &__leaders {
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding;
	}
  }
  &__list {
	@include min-screen($breakpoint-md) {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
	  @include smoothValue('padding-right', 0px, 65px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

.doctor-info {
  flex-shrink: 0;
  @include min-screen($breakpoint-md) {
	width: 49%;
  }
  @include min-screen($breakpoint-lg) {
	width: 530px;
  }
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  @include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-md);

  &__img {
	width: 60px;
	height: auto;
	@include smoothValue('width', 60px, 150px, $breakpoint-xxs, $breakpoint-sm);
	margin-right: 10px;
	@include smoothValue('margin-right', 10px, 20px, $breakpoint-xxs, $breakpoint-sm);
  }
  &__text {
	@include min-screen($breakpoint-md) {
	  max-width: 360px;
	}
  }
  &__name {
	font: 300 18px/22px 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 18px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('line-height', 22px, 24px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('line-height', 24px, 36px, $breakpoint-sm, $breakpoint-lg);
	color: $c-burgundy;
	margin-bottom: 5px;
	@include smoothValue('margin-bottom', 5px, 15px, $breakpoint-xxs, $breakpoint-sm);
  }
  &__spec {
	@extend .tiny-text;
	@include smoothValue('font-size', 9px, 12px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-sm) {
	  line-height: 14px;
	}
	text-transform: uppercase;
	margin-bottom: 5px;
	@include smoothValue('margin-bottom', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);
  }
  &__position {
	@extend .tiny-text;
	@include smoothValue('font-size', 9px, 10px, $breakpoint-xxs, $breakpoint-sm);
	text-transform: uppercase;
  }
}
