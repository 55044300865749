.our-jewel {
	margin-top: 15px;
	margin-bottom: 15px;
	@include max-screen($breakpoint-sm - 1px) {
		margin-left: calc(-1 * var(--container-indent));
		margin-right: calc(-1 * var(--container-indent));
	}
	@include min-screen($breakpoint-md) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	&__wrap {
		@include waves;
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		//@include smoothValue('padding-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		//@include smoothValue('padding-bottom', 40px, 50px, $breakpoint-sm, $breakpoint-lg);
		&::before {
			background-color: $c-light-beige;
		}
	}
	&__poster {
		margin-left: auto;
		margin-right: auto;
		padding-left: var(--container-indent);
		padding-right: var(--container-indent);
		@include min-screen($breakpoint-xs) {
			@include widthInContainer(12);
		}
		img {
			width: 100%;
			mix-blend-mode: multiply;
		}
	}
}
