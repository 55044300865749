.patient-survey {
  margin-top: 40px;
  @include smoothValue('margin-top', 40px, 50px, $breakpoint-sm, $breakpoint-md);
  margin-bottom: 50px;
  @include smoothValue('margin-bottom', 50px, 40px, $breakpoint-xxs, $breakpoint-xs);
  @include smoothValue('margin-bottom', 40px, 60px, $breakpoint-sm, $breakpoint-md);

  &__title {
	@extend .title-h3;
	color: $c-burgundy;
	padding-bottom: 20px;
	@include smoothValue('padding-bottom', 20px, 17px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 0, 25px, $breakpoint-xxs, $breakpoint-sm);
	border-bottom: 1px solid $c-light-beige;
  }
  &__wrap {
	width: 100%;
	max-width: 710px;
	@include min-screen($breakpoint-md) {
	  max-width: 600px;
	}
	margin: 0 auto;
  }
  &__info {
	font: 400 12px/16px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 12px, 16px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('line-height', 16px, 24px, $breakpoint-xxs, $breakpoint-sm);
	color: #000;
	margin-bottom: 15px;
	@include min-screen($breakpoint-md) {
	  margin-bottom: 10px;
	}
  }
  &__alert {
	margin-top: 25px;
	@include smoothValue('margin-top', 25px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
  }
}
