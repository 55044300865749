.pagination {
	$parent-selector: &;
	&._faq {
		margin-top: -1px;
	}
	&._with-offset {
		#{$parent-selector} {
			&__inner {
				@include min-screen($breakpoint-lg) {
					padding-right: 0;
					padding-left: calc((100% - 15 * var(--col-gutter)) / 14 * #{4} + 3 * var(--col-gutter));
				}
			}
		}
	}
	&__inner {
		padding-top: 20px;
		padding-bottom: 50px;
		border-top: 1px solid $c-light-beige;
	}
	&__row {
		flex-wrap: nowrap;
		justify-content: space-between;
	}
	&__list {
		display: inline-flex;
		flex-wrap: wrap;
		vertical-align: top;
		align-items: center;
	}
	&__info {
		padding-left: 15px;
		padding-right: 50px;
		padding-top: 19px;
		font-family: 'Uni Neue Book', sans-serif;
		font-weight: normal;
		font-size: 14px;
		line-height: 1.6;
		text-align: center;
		color: $c-dark-gray;
	}
	&__info-active {
		padding-right: 3px;
		font-family: 'Uni Neue', sans-serif;
		font-weight: bold;
		color: $c-dark;
	}
	&__select {
		width: 190px;
	}
	&__pages {
		padding-top: 5px;
	}
	&__info,
	&__select {
		display: none;
		flex-shrink: 0;
	}
	&__item {
		@extend .animated;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		min-width: 50px;
		padding: 0 10px;
		box-sizing: border-box;
		font-family: 'Uni Neue', sans-serif;
		font-weight: 500;
		font-size: 18px;
		line-height: 1.6;
		color: #202020;
		cursor: pointer;
		&._next,
		&._prev {
			padding-left: 20px;
			padding-right: 20px;
		}
		&._next {
			margin-left: 15px;
			#{$parent-selector} {
				&__text {
					margin-right: 10px;
				}
			}
		}
		&._prev {
			display: none;
			margin-right: 15px;
			#{$parent-selector} {
				&__text {
					margin-left: 10px;
				}
			}
		}
		&._active {
			pointer-events: none;
			background-color: $c-beige-form;
		}
		._desktop &:hover {
			color: $c-brown;
		}
	}
	&__text {
		display: none;
	}
	&__icon {
		width: 8px;
		height: 13px;
	}
	@include min-screen($breakpoint-sm) {
		&__inner {
			padding-top: 40px;
			padding-bottom: 70px;
		}
		&__info,
		&__select {
			display: block;
		}
	}
	@include min-screen($breakpoint-md) {
		&__inner {
			padding-bottom: 100px;
		}
		&__select {
			width: 230px;
		}
		&__pages {
			min-width: 34%;
		}
		&__text {
			display: block;
		}
		&__icon {
			margin-top: 2px;
		}
	}
	@include min-screen($breakpoint-lg) {
		&__inner {
			@include desktop-container-padding;
		}
	}
}
