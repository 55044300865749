.directions-full-list {
  &__filter-xxs {
	@include min-screen($breakpoint-sm) {
	  display: none;
	}
	width: 100%;
	margin-bottom: 20px;
	z-index: 10;
  }
  &__filter-sm {
	display: none;
	@include min-screen($breakpoint-sm) {
	  display: block;
	  width: 100%;
	}
	margin-bottom: 30px;
  }
  &__search {
	margin-bottom: 30px;
  }
  &__tiles {
	--dir-tile-offset: 15px;
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(-1 * var(--dir-tile-offset) / 2);
	margin-right: calc(-1 * var(--dir-tile-offset) / 2);
	@include min-screen($breakpoint-sm) {
	  --dir-tile-offset: 20px;
	}
	@include min-screen($breakpoint-md) {
	  --dir-tile-offset: 30px;
	}
  }
  &__tile {
	position: relative;
	width: 100%;
	padding: calc(var(--dir-tile-offset) / 2);
	box-sizing: border-box;

	@include min-screen($breakpoint-xs) {
	  width: 1/2 * 100%;
	}
	@include min-screen($breakpoint-sm) {
	  width: 1/3 * 100%;
	}
	@include min-screen($breakpoint-md) {
	  width: 1/4 * 100%;
	}
  }
  &__item {
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 25px;
	@include min-screen($breakpoint-xs) {
	  padding: 20px;
	}
	@include min-screen($breakpoint-md) {
	  padding: 25px;
	}
	width: 100%;
	height: 190px;
	@include smoothValue('height', 190px, 216px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('height', 216px, 280px, $breakpoint-sm, $breakpoint-lg);
  }
  &__info {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 25px;
	box-sizing: border-box;
	@extend .transition-active;

	&_light {
	  background-color: $c-light-gray;
	}
	&_dark {
	  background-color: #f8f6f2;
	}
	&:hover {
	  background-color: #fff;
	}
  }
  &__bg {
	position: absolute;
	z-index: 5;
	top: 0;
	right: 0;
	mix-blend-mode: multiply;
	opacity: .5;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
	@supports not (mix-blend-mode: multiply) {
	  z-index: -1;
	}
	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }
  &__links {
  }

  &__name {
	@extend .title-h6;
	color: $c-burgundy;
	cursor: pointer;
	&:hover {
	  text-decoration: underline;
	}
  }
  &__link-wrap {
	&:not(:last-child) {
	  margin-bottom: 10px;
	}
  }
  &__link {
	position: relative;
	font-weight: 500;
	font-size: 14px;
	line-height: 160%;
	color: $c-dark-gray;

	&::before {
	  position: absolute;
	  content: '';
	  left: 100%;
	  top: 0;
	  bottom: 0;
	  width: 23px;
	  height: 17px;
	  background-image: url("data:image/svg+xml,%3Csvg width='23' height='17' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9.5H22' stroke='%238D3332'/%3E%3Cpath d='M17 4L22.5 9.5L17 15' stroke='%238D3332'/%3E%3C/svg%3E%0A");
	  opacity: 0;
	  transform: translateX(5px);
	  @extend .transition-move;
	}
	&:hover {
	  &::before {
		opacity: 1;
		transform: translateX(10px);
	  }
	}
  }
}
