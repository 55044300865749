//для IE 11

._ie{
	.accordion-faq-list__text._view {
		width: 50%;
		white-space: initial;
		display: block;
		flex: none;

	}

	._active {
		.accordion-faq-list__text._view {
			white-space: normal;
		}

	}

	.accordion-faq-list__title {
		width: 50%;
		white-space: initial;
		display: block;
		flex: none;
	}
}

.accordion-faq {
	@include small-section-mt();

	&__wrap {
		position: relative;
		padding-top: 25px;
		@include smoothValue('padding-top', 25px, 40px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('padding-top', 40px, 50px, $breakpoint-sm, $breakpoint-lg);
		&::before {
			position: absolute;
			content: '';
			z-index: -1;
			left: calc(-1 * var(--container-indent));
			top: 0;
		  	width: calc(100% + 2 * var(--container-indent));
			height: 100%;
			background: $c-light-beige url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		}

		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding;
			padding-bottom: 87px;

			&::before {
				left: 0;
			  	width: 100%;
			}
		}
		@include min-screen($breakpoint-lg) {
			//@include desktop-container-padding-right;
			padding-bottom: 70px;
			&::before {
				width: 100%;
			}
		}
	}
	&__title {
		@extend .title-h3;
		color: $c-burgundy;
		margin-bottom: 20px;
		@include smoothValue('margin-bottom', 20px, 32px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-bottom', 32px, 35px, $breakpoint-sm, $breakpoint-lg);
	}
}

.accordion-faq-list {
	$block: &;
	@include max-screen($breakpoint-sm - 1px) {
		margin-left: calc(-1 * var(--container-indent));
		margin-right: calc(-1 * var(--container-indent));
	}

	&._simple {
		margin-top: 20px;
		margin-left: 0;
		margin-right: 0;
		@include min-screen($breakpoint-md) {
			margin-top: 40px;
		}
		#{$block} {
			&__line {
				padding-left: 0;
				padding-right: 0;
				@include min-screen($breakpoint-sm) {
					padding-left: 15px;
					padding-right: 15px;
					padding-top: 25px;
					padding-bottom: 25px;
				}
				@include min-screen($breakpoint-md) {
					padding-top: 30px;
					padding-bottom: 30px;
				}
			}
			&__icon {
				margin-top: 0;
				@include min-screen($breakpoint-md) {
					margin-top: 8px;
				}
			}
			&__title {
				font-size: 18px;
				font-weight: 500;
				line-height: 1.1;
				@include min-screen($breakpoint-sm) {
					font-size: 20px;
				}
			}
			&__text {
				@include min-screen($breakpoint-sm) {
					padding-left: 48px;
				}
			}
		}
	}
	&._no-mt {
		margin-top: 0;
	}
	&._light-beige {
		#{$block} {
			&__line {
				border-color: $c-light-beige;
			}
		}
	}
	&._faq {
		#{$block} {
			&__icon {
				@include min-screen($breakpoint-sm) {
					left: 25px;
				}
			}
			&__title,
			&__text {
				@include min-screen($breakpoint-lg) {
					padding-left: 65px;
				}
			}
		}
	}
	&__line {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #d3d0ce;
		padding: 20px var(--container-indent);
		text-align: left;
		box-sizing: border-box;

		&:last-child {
			border-bottom: 1px solid #d3d0ce;
		}
		@include min-screen($breakpoint-sm) {
			padding-left: 15px;
			padding-right: 15px;
			align-items: center;
		}
		@include min-screen($breakpoint-lg) {
			align-items: center;
		}

		._desktop &:hover {
			border-top-color: $c-beige;
			border-bottom-color: $c-beige;
			#{$block} {
				&__title,
				&__icon {
					color: $c-beige;
				}
			}
		}
		._desktop &:hover + & {
			border-top-color: $c-beige;
		}

		&._active {
			#{$block} {
				&__title {
					color: $c-burgundy;
				}
				&__text {
					display: flex;
					flex-wrap: wrap;
					overflow: visible;
					white-space: initial;
				}
				&__icon {
					color: $c-beige;
					transform: rotate(0);
				}
				&__text {
					color: $c-dark;
				}
			}
		}
	}
	&__icon {
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		transform: rotate(-135deg);
		color: $c-burgundy;
		align-self: flex-start;
		margin-top: 2px;
		cursor: pointer;
		user-select: none;
		@extend .transition-move;

		@include min-screen($breakpoint-sm) {
			width: 24px;
			height: 24px;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			top: 20px;
		}
		@include min-screen($breakpoint-lg) {
			left: 0;
		}
	}
	&__title {
		flex: 0 1 calc(100% - 18px);
		padding-left: 15px;
		box-sizing: border-box;
		font-size: 20px;
		line-height: 1.2;
		font-family: 'Museo Sans Cyrl', sans-serif;
		align-self: flex-start;
		font-weight: 100;
		cursor: pointer;
		user-select: none;
		@include smoothValue('font-size', 20px, 22px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 22px, 26px, $breakpoint-sm, $breakpoint-lg);

		@include min-screen($breakpoint-sm) {
			flex: 0 1 calc(100% - 24px);
			padding-left: 24px;
		}
		@include min-screen($breakpoint-md) {
			flex: 0 0 50%;
			padding-left: 40px;
		}
		._simple & {
			@include min-screen($breakpoint-md) {
				flex-basis: 100%;
			}
		}
	}
	&__text {
		display: none;
		margin-top: 20px;
		font-family: 'Uni Neue Book', sans-serif;
		color: $c-dark-gray;

		&._view {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			@include min-screen($breakpoint-md) {
				display: block;
				flex: 0 0 50%;
				margin-top: 0;
			}
		}
	}

	&__date {
	  	width: 100%;
		font-size: 14px;
		line-height: 1.6;
		font-family: 'Uni Neue Book', sans-serif;
		color: $c-dark-gray;
		@include min-screen($breakpoint-md) {
			margin-top: 15px;
		}
	}
	&__question {
	  	width: 100%;
		font-size: 14px;
		line-height: 1.6;
		font-family: 'Uni Neue Book', sans-serif;
		font-style: italic;
		margin-top: 10px;
		color: #606060;

		@include min-screen($breakpoint-sm) {
			font-size: 18px;
		}
	}
	&__answer {
		font-size: 14px;
		font-weight: 500;
		font-style: italic;
		font-family: 'Uni Neue', sans-serif;
		margin-top: 20px;
		color: #202020;
		@include min-screen($breakpoint-sm) {
			font-size: 18px;
			margin-top: 30px;
		}
		@include min-screen($breakpoint-md) {
		}
		&._faq {
			@include min-screen($breakpoint-md) {
				width: 7/11 * 100%;
				padding-right: 32px;
				box-sizing: border-box;
			}
		}
	}
	&__more {
		margin-top: 10px;
		width: 100%;
		@include min-screen($breakpoint-sm) {
			margin-top: 15px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 20px;
		}
		.link-with-arrow {
			@include max-screen($breakpoint-sm - 1px) {
				font-size: 14px;
			}
		}
	}

	&__doctor {
		display: flex;
		align-items: center;
		padding-top: 15px;
		margin-top: auto;
		order: 1;
		@include min-screen($breakpoint-md) {
			padding-top: 20px;
			order: 0;
			width: 4/11 * 100%;
		}
	}
	&__avatar {
		width: 60px;
		height: 60px;
		padding-right: 20px;
		flex-shrink: 0;
		@include min-screen($breakpoint-md) {
			width: 80px;
			height: 80px;
			padding-right: 10px;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	&__name {
		@extend .transition-active;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		line-height: 1;
		color: $c-burgundy;
		@include min-screen($breakpoint-md) {
			font-size: 20px;
		}
		&:hover {
			color: $c-beige;
			text-decoration: underline;
		}
	}
	&__position {
		text-transform: lowercase;
		color: $c-dark-gray;
		margin-top: 5px;
		font-size: 12px;
		br {
			display: none;
		}

		@include min-screen($breakpoint-md) {
			font-size: 10px;
			font-family: 'Museo Sans Cyrl', sans-serif;
			text-transform: uppercase;
			br {
				display: block;
			}
		}
	}
}
