.additional {
	padding-top: 46px;
	@include smoothValue('padding-top', 46px, 60px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-top', 76px, 80px, $breakpoint-lg, $breakpoint-fhd);
	@include smoothValue('padding-bottom', 60px, 76px, $breakpoint-lg, $breakpoint-fhd);

	.standard-slider__arrows {
		margin-top: 42px;
	}
	&__wrap {
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding-left;

			&::before {
				left: 0;
			}
		}
		@include min-screen($breakpoint-md) {
			@include desktop-container-padding-right;
		}
		@include min-screen($breakpoint-lg + 1px) {
		}
	}
	&__title {
		@extend .title-h3;
		color: $c-burgundy;
		@include smoothValue('margin-bottom', 90px, 106px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__list {
		margin-top: 28px;
		//@include smoothValue('padding-top', 34px, 40px, $breakpoint-lg, $breakpoint-fhd);
		@include min-screen($breakpoint-lg) {
			margin-top: 0;
		}
	}
}

.additional-list {
	.flickity-viewport {
		overflow: visible;
	}
	.standard-slider__arrows {
		@include min-screen($breakpoint-xs) {
			display: none;
		}
	}
	&__list {
		@include min-screen($breakpoint-xs) {
			&[data-slider-slides]::after {
				content: '';
			}
			display: flex;
			justify-content: space-around;
		}
		@include min-screen($breakpoint-lg + 1px) {
			padding-left: 6%;
		}
	}
	&__slide {
		width: 260px;
		margin-right: 15px;
		@include smoothValue('width', 260px, 320px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('width', 320px, 375px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('width', 375px, 400px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('width', 400px, 670px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__info {
		display: block;
		background-color: $c-light-gray;
		padding: 24px;
		text-align: center;
		@include min-screen($breakpoint-xs) {
			background-color: transparent;
		}
		._desktop &:hover {
			.additional-list__title {
				color: $c-beige;
				text-decoration: underline;
			}
		}
	}
	&__title {
		font-size: 24px;
		line-height: 1.1;
		color: $c-burgundy;
		font-weight: 100;
		font-family: 'Museo Sans Cyrl', sans-serif;
		@extend .transition-active;
		@include max-screen($breakpoint-xs) {
			min-height: 2.2em;
			font-size: 20px;
		}
		@include min-screen($breakpoint-lg) {
			line-height: 1.4;
		}
		@include smoothValue('font-size', 22px, 26px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 26px, 36px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__text {
		margin-top: 20px;
		font-family: 'Uni Neue Book', sans-serif;
		@include min-screen($breakpoint-xs) {
			margin-top: 15px;
		}
		@include min-screen($breakpoint-lg) {
			font-size: 16px;
		}
	}
	&__icon {
		margin-bottom: 50px;
		display: flex;
		justify-content: center;

		@include min-screen($breakpoint-xs) {
			height: 290px;
			align-items: center;
			margin-bottom: 0;
		}
		@include min-screen($breakpoint-lg) {
			margin-bottom: 58px;
			align-items: flex-end;
		}
		@include smoothValue('height', 165px, 198px, $breakpoint-lg, $breakpoint-fhd);
		svg,
		img {
			width: 177px;
			height: 142px;
			object-fit: contain;
			object-position: center bottom;

			._monitor & {
				@include min-screen($breakpoint-xs) {
					width: 182px;
					height: 146px;
				}
				@include min-screen($breakpoint-lg) {
					height: 100%;
					width: auto;
				}
			}
			._human & {
				@include min-screen($breakpoint-xs) {
					width: 130px;
					height: 255px;
				}
				@include min-screen($breakpoint-lg) {
					//height: 100%;
					width: auto;
				}
				@include smoothValue('height', 308px, 370px, $breakpoint-lg, $breakpoint-fhd);
			}
		}
	}
}
