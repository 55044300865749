// для IE 11

._ie {
	.doctors-slider__image {
		&::after {
			background: none;
		}
	}
}
.doctors {
	overflow: hidden;
	margin-top: 46px;
	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding-left;
		}
		&._hidden {
			padding-top: 30px;
			overflow: hidden;
		}
	}
	&.pservices {
		margin-right: 30px
	}
	&__title {
		&._clinics {
			color:#8d3332;
			display: inline-block;
		}
		@extend .title-h4;
		margin-bottom: 25px;
		max-width: 450px;
		@include smoothValue('max-width', 450px, 638px, $breakpoint-xs, $breakpoint-md);
		@include smoothValue('max-width', 638px, 1045px, $breakpoint-md, $breakpoint-lg);

		@include min-screen($breakpoint-xs) {
			margin-bottom: 30px;
		}
		@include min-screen($breakpoint-sm) {
			max-width: 450px;
		}
		@include min-screen($breakpoint-lg) {
			margin-bottom: 25px;
		}
		//@include smoothValue('margin-bottom', 25px, 40px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('margin-bottom', 25px, 50px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('max-width', 450px, 638px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('max-width', 638px, 1045px, $breakpoint-md, $breakpoint-lg);
	}
	&__talign_just {
		text-align-last: justify;
	}
	&__link._clinics {
		padding-right: 0px;
		margin-right: 0px;
		display: inline-block;
	}
}

.standard-slider__nav._next._articles {
	margin-left: 30px;
}
.carousel-cell {
	width: 100%;
}

.doctors-slider {
	$block: &;
	.flickity-viewport {
		overflow: visible;
	}
	//.standard-slider__arrows {
	//	margin-top: 0;
	//}
	&__slide {
		width: 200px;
		margin-right: 30px;
		@include min-screen($breakpoint-sm) {
			margin-right: 25px;
		}
		@include smoothValue('width', 210px, 250px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('margin-right', 45px, 95px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__content {
		&:hover {
			#{$block} {
				&__name {
					color: $c-brown;
					text-decoration: underline;
				}
				&__image {
					&::after {
						opacity: 0;
					}
				}
				&__position {
					color: #373737;
				}
			}
		}
	}
	&__info {
		margin-top: 14px;
	}
	&__image {
		position: relative;
		&::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #f8f6f2;
			mix-blend-mode: multiply;
			opacity: 1;
			@extend .transition-move;

			@supports not (mix-blend-mode: multiply) {
				z-index: -1;
			}
		}
		img {
			width: 100%;
			height: 195px;
			@include smoothValue('height', 210px, 250px, $breakpoint-lg, $breakpoint-fhd);
			object-fit: cover;
			object-position: center;
		}
	}
	&__name {
		@extend .subtitle-18-100, .transition-active;
		&._burgundy {
			color: $c-burgundy;
		}
	}
	&__spec {
		font-size: 12px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		text-transform: uppercase;
		margin-top: 5px;
	}
	&__position {
		@extend .tiny-text, .transition-active;
		margin-top: 6px;
		color: #8c8c8c;
	}
}

.doctors_3col .doctors-slider__slide:last-child {
	margin-right: 0 !important;
}

.doctors_3col .doctors-slider__slide {
	width: calc(33.33% - 30px) !important;
	margin-right: 60px !important;
}

@media only screen and (max-width:1550px) {
	.doctors_3col .doctors-slider__slide {
		width: calc(33.33% - 15px) !important;
		margin-right: 30px !important;
	}
}

@media only screen and (max-width:1250px) {
	.doctors_3col .doctors-slider__slide {
		width: calc(50% - 15px) !important;
		margin-right: 30px !important;
	}
}

@media only screen and (max-width:400px) {
	.doctors_3col .doctors-slider__slide {
		width: 200px !important;
		margin-right: 15px !important;
	}

}

@media only screen and (max-width:767px) {
	.doctors_3col {
		overflow: visible;
	}
	.doctors_3col .doctors__link._clinics {
		margin-bottom: 20px;
	}
}

@media only screen and (min-width:1130px) {
	.doctors.pservices.doctors_3col {
		margin-right: 0 !important;
	}
	.doctors_3col .doctors-slider__list {
		width: calc(100% - 20px);
	}
	.doctors_3col .doctors__link._clinics {
		margin-right: 10px;
	}
}

@media (min-width:1400px) and (max-width:1921px) {
	.doctors_3col .doctors-slider__image img {
		min-height: 285px;
	}
}

@media (min-width:1300px) and (max-width:1399px) {
	.doctors_3col .doctors-slider__image img {
		min-height: 260px;
	}
}

@media (min-width:600px) and (max-width:1130px) {
	.doctors_3col .doctors-slider__image img {
		object-fit: contain;
	}
}

@media (min-width:1130px) and (max-width:1250px) {
	.doctors_3col .doctors-slider__image img {
		min-height: 250px;
	}
}