.form-radio-duo {
	width: 100%;
	height: 60px;
	display: flex;
	border-radius: 3px;
	border: 1px solid #ebe4d9;
	overflow: hidden;

	&__input {
		position: absolute;
		height: 0;
		width: 0;
		visibility: hidden;
	}
	&__radio {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		cursor: pointer;
		&:nth-child(2) span {
			border-left: 1px solid #ebe4d9;
		}
		input:checked ~ span {
			background-color: #f0e9db;
		}
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			text-transform: uppercase;
			user-select: none;
			@extend .transition-active;
		}
	}
}
