.contacts-head {
	position: relative;
	z-index: 2;
	overflow: visible;
	margin-top: 35px;
	@include smoothValue('margin-top', 35px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
	&__wrap {
		padding-bottom: 20px;
		border-bottom: 1px solid $c-light-beige;
		@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
	}
	&__title {
		@extend .title-h3;
		color: $c-burgundy;
	}
	&__select {
		margin-top: 20px;
		@include smoothValue('margin-top', 20px, 24px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 24px, 30px, $breakpoint-sm, $breakpoint-lg);
		.form-select {
			max-width: 350px;
			width: 100%;
			@include min-screen($breakpoint-md) {
				max-width: 530px;
			}
		}
		@include min-screen($breakpoint-xs) {
			display: flex;
			align-items: center;
		}
	}
	&__clear {
		@extend %reset-Button;
		color: $c-dark-gray;
		text-decoration: underline;
		cursor: pointer;
		margin-top: 10px;
		@include min-screen($breakpoint-xs) {
			margin-top: 0;
			margin-left: 25px;
		}
		@include min-screen($breakpoint-sm) {
			font-size: 16px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	&__text {
		@extend .base-text;
		margin-top: 20px;

		._book {
			font-family: 'Uni Neue Book', sans-serif;
		}
	}
}

.contacts-list {
	margin-top: 20px;
	margin-bottom: 70px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('margin-bottom', 70px, 100px, $breakpoint-sm, $breakpoint-lg);
	&__wrap {
		@include min-screen($breakpoint-md) {
			@include desktop-container-padding;
		}
	}
	&__cards {
	}
	&__card {
		& + & {
			margin-top: 20px;
		}
	}
}

.contacts-card {
	$block: &;
	& + & {
		@include max-screen($breakpoint-sm - 1px) {
			#{$block}__wrap {
				padding-top: 20px;
				border-top: 1px solid $c-light-beige;
			}
		}
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		background-color: $c-white;
		@include min-screen($breakpoint-sm) {
			flex-direction: row;
			width: 100%;
			border: 1px solid $c-light-beige;
		}
		@include min-screen($breakpoint-md) {
			justify-content: space-between;
		}
	}
	&__photo {
		height: 220px;
		@include max-screen($breakpoint-sm - 1px) {
			order: -1;
		}
		@include min-screen($breakpoint-xs) {
			height: 260px;
		}
		@include min-screen($breakpoint-sm) {
			height: auto;
			width: 7/16 * 100%;
			padding: 8px;
		}
		@include min-screen($breakpoint-md) {
			width: vw(510px, 1440px);
			max-width: 700px;
		}

		&-wrap {
			position: relative;
			width: 100%;
			height: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include min-screen($breakpoint-sm) {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	&__info {
		margin-top: 20px;
		@include max-screen($breakpoint-sm - 1px) {
			margin-top: 20px;
		}
		@include min-screen($breakpoint-sm) {
			width: 9/16 * 100%;
			padding: 20px;
			margin-top: 0;
		}
		@include min-screen($breakpoint-md) {
			height: vw(304px, 1440px);
			display: flex;
			flex-direction: column;
			padding: 30px;
		}
		@include min-screen($breakpoint-lg) {
			padding: vw(30px, 1440px);
		}
	}
	&__title {
		@extend .subtitle-20-100;
		color: $c-burgundy;
		@include smoothValue('font-size', 26px, 36px, $breakpoint-sm, $breakpoint-lg);
	}
	&__address {
		font-size: 16px;
		margin-top: 15px;
		color: #1b1b1b;
		a {
			color: $c-burgundy;
			font-size: 14px;
			font-family: 'Uni Neue Book', sans-serif;
			@include min-screen($breakpoint-md) {
				font-size: 18px;
				margin-left: 15px;
			}
		}
		br {
			display: none;
			@include max-screen($breakpoint-md - 1px) {
				display: block;
			}
		}
		@include min-screen($breakpoint-md) {
			font-size: 18px;
		}
	}
	&__list {
		margin-top: 15px;
		@include min-screen($breakpoint-md) {
			margin-top: 5px;
			font-size: 16px;
		}
		color: #1b1b1b;
		span {
			font-family: 'Uni Neue Book', sans-serif;
		}
	}
	&__buttons {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			display: flex;
		}
		@include min-screen($breakpoint-md) {
			flex-grow: 1;
			align-items: flex-end;
		}
	}
	&__button {
		& + & {
			@include min-screen($breakpoint-sm) {
				margin-left: 40px;
			}
		}
	}

	&__panel {
		display: none;
		width: 100%;

		&._opened-contact{
			opacity: 1;
			visibility: inherit;
			display: block;
		}

		@include max-screen($breakpoint-sm - 1px) {
			position: fixed;
			z-index: 1001;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $c-white;
			opacity: 0;
		}
		@include min-screen($breakpoint-sm) {
			border-left: 1px solid $c-light-beige;
			border-bottom: 1px solid $c-light-beige;
			border-right: 1px solid $c-light-beige;
		}
	}

	&__map {
		position: relative;
		height: 350px;
		width: 100%;
		border-bottom: 1px solid $c-light-beige;
		@include min-screen($breakpoint-sm) {
			height: 460px;
		}

		[class$='ground-pane'] canvas {
			filter: grayscale(1) opacity(0.5);
		}
	}
}

.contacts-card-directions {
	position: relative;

	@include max-screen($breakpoint-sm - 1px) {
		height: 100%;
		overflow-y: auto;
		padding-top: 25px;
		padding-left: var(--container-indent);
		padding-right: var(--container-indent);
		box-sizing: border-box;
	}

	@include min-screen($breakpoint-sm) {
		padding: 20px;
	}
	@include min-screen($breakpoint-lg) {
		padding: 30px;
	}
	&__close {
		@extend %reset-Button;
		position: absolute;
		right: 15px;
		top: 15px;
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $c-burgundy;
		@extend .transition-active;
		svg {
			width: 15px;
			height: 15px;
		}

		&:hover {
			color: $c-beige;
		}

		@include min-screen($breakpoint-sm) {
			display: none;
		}
	}
	&__title {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 23px;
		line-height: 1.15;
		padding-right: 40px;
		color: $c-burgundy;
		margin-bottom: 24px;
		@include min-screen($breakpoint-sm) {
			display: none;
		}
	}
	&__list {
		--offset: 10px;
		margin-top: calc(-1 * var(--offset));
		margin-bottom: calc(-1 * var(--offset));
		@include min-screen($breakpoint-sm) {
			display: flex;
			flex-wrap: wrap;
		}
	}
	&__item {
		font-size: 14px;
		line-height: 1.6;
		padding-top: var(--offset);
		padding-bottom: var(--offset);
		padding-right: 16px;
		box-sizing: border-box;
		@include min-screen($breakpoint-sm) {
			width: 50%;
		}
		@include min-screen($breakpoint-md) {
			font-size: 16px;
		}
	}
}

.contacts-card-instruction {
	position: relative;
	@include max-screen($breakpoint-sm - 1px) {
		height: 100%;
		overflow-y: auto;
		box-sizing: border-box;
		padding-bottom: 25px;
	}
	&__content {
		padding-left: var(--container-indent);
		padding-right: var(--container-indent);
	}
	&__select {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			display: none;
		}
	}
	&__list {
		display: none;
		flex-wrap: wrap;
		padding-top: 20px;
		@include min-screen($breakpoint-sm) {
			padding-top: 15px;
			padding-bottom: 15px;
			margin-left: -15px;
			margin-right: -15px;
		}
	}
	&__item {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		color: #1b1b1b;
		@include max-screen($breakpoint-sm - 1px) {
			& + & {
				margin-top: 15px;
			}
		}
		@include min-screen($breakpoint-sm) {
			font-size: 16px;
			width: 50%;
			padding: 15px;
			box-sizing: border-box;
		}
	}
	&__buttons {
		position: absolute;
		bottom: 0;
		left: 0;
		display: none;
		@include min-screen($breakpoint-sm) {
			display: flex;
		}
		.button {
			height: 40px;
			background-color: $c-beige;
			&._active {
				pointer-events: none;
				background-color: $c-burgundy;
			}
		}
	}
	.contacts-card-directions__title {
		margin-top: 20px;
		margin-bottom: 20px;
		padding-left: var(--container-indent);
	}
}
