
.price_for-mobile{
	display: none;
}
@media only screen and (max-width: 575px){
	.price_for-mobile{
		display: block;
		transform: translate(135px, 46px);
		font-size: 11px;
	}
}



@media only screen and (max-width: 321px){
	.price_for-mobile{
		display: none!important;
	}
}
// для IE 11

._ie {
	.header._fixed .header__logo svg  {
		max-width: 150px;
	}

	.header._fixed .header__logo img  {
		max-width: 150px;
	}

	.header__logo {
		margin-left: 2%;
		min-width: 20%;
	}

	.header-nav  {
		bottom: 5px;
		left: 22%;
	}

	/*.header-search {
		margin-left:40px;
	}*/
}


@keyframes toggle-show {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

@mixin onOpenMenu() {
	transform: translateY(0);
	opacity: 1;
	._fixed & {
		@include max-screen($breakpoint-md - 1px) {
			display: none;
		}
	}
	@include mq($breakpoint-sm, $breakpoint-md - 1px) {
		._open-menu:not(._fixed) & {
			transform: translateY(-40px);
			opacity: 0;
		}
	}
}

.header {
	$block: &;
	--header-logo-width: 195px;
	--header-bottom-padding: 12px;
	position: absolute;
	top: 0;
	z-index: 999;

	width: 100%;
	padding-top: 40px;
	background-color: $c-white;
	//padding-bottom: 12px;
	transition: transform 0.6s ease-out, opacity 0.6s ease-out;

	@include min-screen($breakpoint-xs) {
		--header-bottom-padding: 25px;
	}

	&._absolute {
		border-bottom: none;
		&:not(._fixed) {
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0.8) 39.84%,
				rgba(255, 255, 255, 0.23) 148.44%
			);
		}
	}

	&._fixed {
		position: fixed;
		animation: toggle-show $default-transition-function $default-transition-duration;
		padding-top: 0;
		padding-bottom: 0;
		//background-color: $c-white;
		@include min-screen($breakpoint-md) {
			height: 70px;
			padding-top: 15px;
			padding-bottom: 15px;
			box-sizing: border-box;
			border-bottom: 1px solid $c-light-beige;
			.container {
				height: 100%;
			}
		}
		@include min-screen($breakpoint-fhd + 1px) {
			--header-logo-width: calc(100vw - 1720px);
		}
		#{$block} {
			&__main {
				@include min-screen($breakpoint-md) {
					height: 100%;
				}
			}
			&__nav {
				margin-top: 0;
			}
			&__logo {
				height: 100%;
				padding: 0;
				a,
				svg,
				img {
					height: 100%;
				}
				svg,
				img {
					width: auto;
				}
			}
			&__contact {
				z-index: 10;
				@include max-screen($breakpoint-md - 1px) {
					display: none;
				}
				@include min-screen($breakpoint-md) {
					position: absolute;
					left: 0;
				}
			}
		}
      
      .header-auth__hidden-laptop {
        display: none;
      }
	}

	@include min-screen($breakpoint-xs) {
		padding-top: 20px;
		padding-bottom: var(--header-bottom-padding);
		border-bottom: 1px solid $c-light-beige;
	}
	@include min-screen($breakpoint-md) {
		--header-logo-width: 240px;
	}
	@include smoothValue('--header-logo-width', 350px, 462px, $breakpoint-lg, $breakpoint-fhd);
	@include min-screen($breakpoint-fhd) {
	  	--header-logo-width: 462px;
	}
	@include max-screen($breakpoint-xs - 1px) {
		&._open-menu .header__nav {
			margin-top: 0;
		}
	}
	@include max-screen($breakpoint-sm - 1px) {
		&._open-menu > .container,
		&._fixed > .container {
			display: none;
		}
		&._open-menu {
			padding-top: 0;
		}
		&._open-menu .header__nav {
			padding-bottom: 0;
		}
	}

	&__main {
	  	@include max-screen($breakpoint-xs - 1px) {
		  	position: relative;
		}
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__contact {
		@extend .transition-move;
		@include onOpenMenu;
		@include max-screen($breakpoint-xs - 1px) {
			width: 100%!important;
			justify-content: space-between;
			margin-top: 10px;
			order: 1;
		}
	}
	&__auth {
		z-index: 999999999999999;
		@extend .transition-move;
		@include onOpenMenu;
	}
	&__lang {
		//margin-left: auto;
	  	grid-area: lang;
		margin-right: 15px;
		@include min-screen($breakpoint-lg) {
		  	margin-right: 25px;
		}
	}
	&__logo {
		position: relative;
		z-index: 2;
		@include min-screen($breakpoint-sm) {
			width: var(--header-logo-width);
		}
		@include min-screen($breakpoint-md) {
			padding-top: 5px;
			padding-bottom: 5px;
		}
		a {
			display: inline-block;
		}
		svg,
		img {
			display: inline-block;
			width: 86px;
			height: 28px;
			@include min-screen($breakpoint-xs) {
				width: 170px;
				height: 56px;
			}
			@include min-screen($breakpoint-lg) {
				width: 198px;
				height: 63px;
			}
		}

		._fixed & {
			@include max-screen($breakpoint-md - 1px) {
				display: none;
			}
		}
	}
	&__link {
	}
	&__button-sos {
	  	grid-area: sos;
	  	position: relative;
	  	top: auto;
	  	//right: 95px;
		right: auto;

		@include min-screen($breakpoint-xs) {
		  	position: relative;
		  	top: 0;
		  	right: 0;
			margin-left: 16px;
		}
		@include min-screen($breakpoint-sm) {
			margin-left: 32px;
			margin-right: 32px;
		}
		@include min-screen($breakpoint-md) {
			margin-left: 27px;
			//margin-right: 0;
		}

		/*._fixed & {
			display: none;
		}*/
	}
	&__close-menu {
		@extend %reset-Button;
		position: absolute;
		z-index: 10;
		top: 60px;
		right: calc(var(--container-indent) + 3px);
		width: 15px;
		height: 15px;
		color: $c-burgundy;
		display: none;
		@include min-screen($breakpoint-xs) {
			top: 70px;
		}
		@include min-screen($breakpoint-sm) {
			width: 20px;
			height: 20px;
			top: 25px;
		}
		@include min-screen($breakpoint-md) {
			top: 120px;
		}

		._fixed & {
			@include min-screen($breakpoint-sm) {
				top: 70px;
			}
			@include min-screen($breakpoint-md) {
				top: 120px;
			}
		}
		._open-menu & {
			display: block;
		}
		._open-search & {
			@include max-screen($breakpoint-sm - 1px) {
				top: 18px;
			}
		}
	}

	&__nav {
		//transform: translateY(0);
		//@extend .transition-move;
		//._fixed & {
		//	@include max-screen($breakpoint-md - 1px) {
		//		display: none;
		//	}
		//}
		//._open-menu:not(._fixed) & {
		//	transform: translateY(-10px);
		//}
	  	.header:not(._fixed) & {
		  @include min-screen($breakpoint-fhd + 100px) {
			padding-left: calc((100vw - 1920px) / 2 - 50px);
		  }
		}
	  	max-width: 1440px;
		margin-top: 13px;
		@include min-screen($breakpoint-xs) {
			margin-top: 21px;
		}
	}
}
.price-ye{
	._fixed & span {
			display: none;
	}
}
@media only screen and (max-width: 575px){
	.price-ye {
		display: none!important;
	}
}


.header-auth {
	display: flex;
	@include min-screen($breakpoint-xs) {
		margin-left: auto;
	}
	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
	  max-width: 30%;
	  flex-wrap: wrap-reverse;
	  justify-content: flex-end;
	}
	._fixed & {
		position: absolute;
		z-index: 10;
		right: calc(var(--container-indent) + 42px);
		top: 50%;
		transform: translateY(-50%);

		&::after {
			position: absolute;
			content: '';
			top: -8px;
			right: -10px;
			height: 40px;
			width: 1px;
			background-color: $c-beige;
			opacity: 0.3;
		}
		@include min-screen($breakpoint-sm) {
			//top: 18px;
		}
		@include min-screen($breakpoint-md) {
			&::after {
				top: -9px;
			}
		}
		@include min-screen($breakpoint-lg) {
			right: calc(var(--container-indent) + 48px);
			&::after {
				right: -15px;
				top: -13px;
			}
		}
	}

  	&__wrap {
	  	display: flex;
		gap: 10px;
		  @include screen($breakpoint-sm, $breakpoint-md - 1px) {
			margin-bottom: 13px;
		  }
	}
	&__link {
		display: flex;
		align-items: center;
		font-size: 11px;
		line-height: 1;
		color: $c-dark;
		@extend .transition-active;
		& + & {
		  	margin-left: 9px;
		  	@include min-screen($breakpoint-sm) {
			  margin-left: 25px;
			}
			@include max-screen($breakpoint-md - 1px) {
				._fixed & {
					margin-left: 10px;
				}
			}
		}
		&:hover {
			text-decoration: underline;
			color: $c-beige;
		}
		@include min-screen($breakpoint-xs) {
			font-size: 14px;
		}
		@include smoothValue('font-size', 14px, 16px, $breakpoint-lg, $breakpoint-fhd);

		._fixed & span {
			@include max-screen($breakpoint-lg - 1px) {
				//display: none;
			}
		}
	  	.header:not(._fixed) &._appointment {
			@include max-screen($breakpoint-xs - 1px) {

				border-right: 1px solid rgba(189, 164, 127, .3);
				padding-right: 12px;
				svg {
				  display: none;
				}
				span {
					text-decoration: underline;
					&:hover, &:active {
					  	text-decoration: none;
					}
				}
			}
		}
	  	.header:not(._fixed) &._login,
		.header:not(._fixed) &._whatsapp {
			span {
				@include max-screen($breakpoint-xs - 1px) {
				  	display: none;
				}
			}
		}
	  	&._whatsapp {
			//position: absolute;
			position: inherit;
		  	z-index: 15;
			//bottom: -42px;
			bottom: 3px;
			right: -2px;
		  	@include smoothValue('right', -2px, 178px, $breakpoint-xxs, 500px);
			@include min-screen($breakpoint-xs) {
				position: relative;
				bottom: 0;
				right: 0;
			  	margin-right: 9px;
			}
		  	@include min-screen($breakpoint-sm) {
			  	margin-right: 0;
			}
		  	@include min-screen($breakpoint-md) {
			  	margin-right: 25px;
			}
		  	@include screen($breakpoint-md, $breakpoint-lg - 1px) {
			  	.header-auth__hidden-laptop {
				  	display: none;
				}
			}

			._fixed & {
			  	display: none;
			}
		}
	}
	&__icon {
		._fixed & {
			@include min-screen($breakpoint-lg) {
				display: none;
			}
		}
		&._checked {
			width: 14px;
			height: 12px;
			margin-right: 6px;
			color: $c-burgundy;
			//@include max-screen($breakpoint-md - 1px) {
			//	display: none;
			//}
		}

		&._person {
			width: 24px;
			height: 24px;
			color: $c-burgundy;
		}
	  	&._whatsapp {
		  	width: 19px;
		  	height: 19px;
		  	margin-right: 5px;
		}
	}
}
.header-lang {
	position: relative;
	display: flex;
	align-items: center;
	color: $c-burgundy;
	cursor: pointer;
	user-select: none;

	&__popup {
		@include popupContainer();
		@extend .transition-move;
		position: absolute;
		z-index: 10;
		left: 50%;
		//top: calc(100% + 3px);
		width: auto;
		background-color: $c-beige;
		border-top: 3px solid $c-burgundy;
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, 10px);
		padding-top: 6px;
		padding-bottom: 6px;

		@include max-screen($breakpoint-xs - 1px) {
			right: auto;
			left: 0;
			margin-right: var(--container-indent);
			margin-left: auto;
		}

		&::before {
			position: absolute;
			content: '';
			bottom: calc(100% + 3px);
			left: 0;
			right: 0;
			width: 0;
			height: 0;
			margin-left: auto;
			margin-right: auto;
			border-bottom: 6px solid $c-burgundy;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
		}
		._open & {
			display: block;
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, 0);
		}
	}

	&__item {
		display: block;
		padding: 8px 22px;
		color: $c-white;
		@extend .transition-active;

		&:hover {
			text-decoration: underline;
		}
	}

	svg {
		width: 10px;
		height: 7px;
		transform: rotate(0deg);
		margin-left: 2px;
		@extend .transition-move;
	}
	&._open svg {
		transform: rotate(180deg);
	}

	._fixed & {
		@include min-screen($breakpoint-md) {
			position: absolute;
			left: calc(var(--header-logo-width) - 32px);
			top: calc(50% + 19px);
			transform: translateY(-50%);
			&::after {
				position: absolute;
				content: '';
				top: -14px;
				left: -20px;
				height: 40px;
				width: 1px;
				background-color: $c-beige;
				opacity: 0.3;
			}
		}
		@include min-screen($breakpoint-lg) {
			left: 215px;
		}
		@include min-screen($breakpoint-fhd + 1px) {
			left: calc(var(--header-logo-width) * 0.7 + 6px);
		}
	}
}
.header-contact {

	position: relative;
	z-index: 10;
	width: max-content;
	display: flex;
	align-items: center;
	font-size: 11px;
  	@include smoothValue('font-size', 14px, 16px, $breakpoint-lg, $breakpoint-fhd);
	line-height: 1;
	@include min-screen($breakpoint-xs) {
		width: auto;
		font-size: 14px;
	}
	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
	  	max-width: 40%;
	  	display: grid;
	  	grid-template-areas: "phone sos lang"
							 "order order order";
	  	grid-template-columns: repeat(3, minmax(30px, max-content));
	}
  	&__phone,
	&__incoming {
		._fixed & {
		  	display: none;
		}
	}
	&__phone {
	  	grid-area: phone;
	  	margin-right: 18px;
		color: $c-burgundy;
		&:hover {
			text-decoration: underline;
		}
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
		  margin-right: 0;
		}
	  	@include min-screen($breakpoint-lg) {
		  	margin-right: 25px;
		}
	}
  	&__incoming {
	  	grid-area: order;
	  	width: max-content;
	  	display: inline-flex;
	  	align-items: center;
	  	cursor: pointer;
	  	&:hover {
		  	text-decoration: underline;
		  	color: $c-beige;
		}
	  	@include max-screen($breakpoint-xs - 1px) {
		  	text-decoration: underline;
		  	&:hover, &:active {
			  	text-decoration: none;
			}
		}
	  	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
		  	margin-top: 13px;
		}
	  	svg {
		  	display: none;
		  	margin-right: 6px;
		  	width: 14px;
		  	height: 14px;
		  	@include min-screen($breakpoint-sm) {
			  	display: inline-block;
			}
		}
	}
}

.header-search {
	@extend %reset-Button;
	@extend .transition-active;
	width: 24px;
	height: 24px;
	color: $c-burgundy;
	&:hover {
		color: $c-brown;
	}
	&._active{
		display: none;
	}
	//._open-search & {
	//	@include max-screen($breakpoint-sm - 1px) {
	//		display: none;
	//	}
	//}
}

.header-nav {
	@extend .transition-move;
	._fixed & {
		padding-top: 12px;
		padding-bottom: 12px;
		border-top: 1px solid $c-light-beige;
		border-bottom: 1px solid $c-light-beige;
		@include min-screen($breakpoint-sm) {
			padding-top: 17px;
			padding-bottom: 17px;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			top: 50%;
			border: none;
			padding: 0;
			transform: translateY(-50%);
			height: 70px;
			align-items: center;
			.header-nav__wrap {
				height: 100%;
				align-items: center;
			}
		}
	}
	@include max-screen($breakpoint-xs - 1px) {
		border-top: 1px solid $c-light-beige;
		border-bottom: 1px solid $c-light-beige;
		padding-top: 13px;
		padding-bottom: 13px;
	}
	@include min-screen($breakpoint-md) {
		position: absolute;
		bottom: var(--header-bottom-padding);
		left: var(--header-logo-width);
		width: calc(100% - var(--header-logo-width));

		.header._open-menu:not(._fixed) & {
			transform: translateY(-30px);
		}
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
	}
}

.header-placeholder {
	height: 178px;
	@include min-screen($breakpoint-sm) {
		height: 152px;
	}
	@include min-screen($breakpoint-md) {
		height: 118px;
	}
	@include min-screen($breakpoint-lg) {
		height: 125px;
	}
}

@media only screen and (max-width: 375px){

	/*.header__button-sos{
		@include smoothValue('right', 111px, -85px, $breakpoint-xxs, 500px);
	}*/

	.absolute-position{
		position: absolute!important;
		top: 44px;
		left: 255px;
	}


}

.header-auth__link._action_bttn {

	justify-content: center;
	text-decoration: none;
	color: white;
	width: 120px;
	background-color: #8D3332;

}


._mobile {
	display: none;
}
._desctop {
	display: flex;
}

@media only screen and (max-width: 387px){

	.menu__text._mobile{
		font-size: 11px!important;
	}
}

@media only screen and (min-width: 376px) and (max-width: 500px ) {

	//.header__button-sos{
	//	//@include smoothValue('right', 130px, -85px, $breakpoint-xxs, 500px);
	//	position:relative;
	//	top:auto;
	//	right: auto;
	//	//margin-left: 25%;
	//}

	.header-auth__icon._whatsapp{
		margin-right: 8px;
	}
	.absolute-position{
		position: absolute!important;
		top: 44px;
		left: 255px;
	}
}

@media only (max-width: 1024px ) {

	.header-auth__link._left_border {
		border-right: none;
		padding-right: 0px;
	}

	.header-auth__link._action_bttn {
		font-size: 11px !important;
		width: 86px !important;
		height: 36px !important;
		padding-right: unset !important;
		span {
			text-decoration: none !important;
		}
	}

	._mobile {
		display: flex;
	}
	._desctop {
		display: none;
	}
}

