.filter {
	border-bottom: 1px solid $c-light-beige;
	margin-top: 20px;
	width: 100%;
	@include min-screen($breakpoint-md) {
		border-bottom: none;
		margin-top: 0;
	}
	&__wrap {
		padding-bottom: 20px;
		margin-top: -20px;
	}
	&__line {
		margin-top: 20px;
	}
	&__container {
		display: none;
		margin-bottom: 20px;
		@include min-screen($breakpoint-md) {
			display: block !important;
		}
		&._always-show {
			display: block;
		}
	}
	&__controls {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	&__show {
		@extend %reset-Button;
		display: flex;
		align-items: center;

		&._opened {
			.open {
				display: none;
			}
			.close {
				display: initial;
			}
		}
		.close {
			display: none;
		}

		@include min-screen($breakpoint-md) {
			display: none;
		}

		svg {
			width: 25px;
			height: 25px;
		}
		span {
			position: relative;
			display: inline-block;
			margin-left: 5px;
			font-family: 'Uni Neue Book', sans-serif;
			font-size: 16px;
			&::before {
				position: absolute;
				content: '';
				top: 100%;
				width: 100%;
				border-bottom: 1px dashed currentColor;
			}
		}
	}
	&__clear {
		@extend %reset-Button;
		position: relative;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 16px;
		&:disabled {
			color: #d3d0ce;
			&::before {
				opacity: 0;
			}
		}
		&:hover {
			&::before {
				opacity: 0;
			}
		}
		&::before {
			position: absolute;
			content: '';
			top: 100%;
			width: 100%;
			border-bottom: 1px dashed currentColor;
			@extend .transition-move;

			@include min-screen($breakpoint-md) {
				border-bottom-style: solid;
			}
		}
		span._hide-mobile {
			@include max-screen($breakpoint-xs - 1px) {
				display: none;
			}
		}
	}
}

.filter-item {
	position: relative;
	.g-delimeter {
		background-color: #eae5e2;
		flex-shrink: 0;
	}
	&__popup {
		@extend .transition-move;
		position: absolute;
		top: 100%;
		z-index: 30;
		width: 100%;
		display: flex;
		flex-direction: column;
		height: calc(var(--vh, 1vh) * 80);
		max-height: 430px;
		background-color: $c-white;
		border: 1px solid #eae5e2;
		box-sizing: border-box;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-5px);
		@include min-screen($breakpoint-md) {
			min-width: 450px;
		}
		._open & {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}
	&__scroll-container {
		height: 0;
		flex: 1 1 100%;
		overflow-y: auto;
	}
	&__checkbox {
		padding: 18px 20px;
		@extend .transition-active;
		&:hover {
			background-color: $c-beige-form;
			.form-checkbox__box {
				border-color: #eae5e2;
			}
		}
		.form-checkbox__box {
			border: 1px solid transparent;
		}
		.form-checkbox__label {
			color: #373737;
			font-size: 16px;
		}
	}
	&__apply {
		padding: 20px;
		border-top: 1px solid #eae5e2;
		button {
			min-width: 143px;
		}
	}
}
.filter-item-selected {
	display: flex;
	flex-wrap: wrap;
	&__item {
		display: flex;
		align-items: center;
		height: 30px;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 5px;
		margin-right: 5px;
		background-color: #f0e9db;
		box-sizing: border-box;
		font-size: 12px;
		span {
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&__btn {
		@extend %reset-Button;
		@extend .transition-active;
		margin-left: 15px;
		color: $c-burgundy;
		&:hover {
			color: $c-brown;
		}
	}
}
