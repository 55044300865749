.mobile-container-margin {
	@include max-screen($breakpoint-xs - 1px) {
		margin-left: -5px;
		margin-right: -5px;
		width: auto;
	}
}

.doctor-card {
	position: relative;
	margin-top: 20px;
	overflow: hidden;
	@include smoothValue('margin-top', 20px, 27px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 27px, 35px, $breakpoint-sm, $breakpoint-lg);
	&__wrap {
		//display: flex;
		//flex-direction: column;
		//width: 100%;
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__col {
		&._desktop-photo {
			display: none;
			@include min-screen($breakpoint-sm) {
				display: block;
			}
		}
		&._content {
			@include min-screen($breakpoint-lg) {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
	&__sphere {
		position: absolute;
		z-index: -1;
		left: 50%;
		opacity: 0.4;
		width: 350px;
		img {
			width: 100%;
		}
		@include min-screen($breakpoint-sm) {
			left: 100%;
			top: 0;
			transform: translate(-50%, -8%);
		}
		@include smoothValue('width', 350px, 500px, $breakpoint-md, $breakpoint-sm);
	}
	&__photo {
		@extend .mobile-container-margin;
		margin-top: 15px;
		grid-column: 2 / 1;
		order: -1;
		@include smoothValue('margin-top', 0px, 15px, $breakpoint-sm, $breakpoint-lg);

		img {
			width: 100%;
		}
		&._hidden-desktop {
			@include min-screen($breakpoint-sm) {
				display: none;
			}
		}
	}
	&__name {
		@extend .title-h3;
		color: $c-burgundy;
	}
	&__position {
		@extend .subtitle-18-300;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			margin-top: 15px;
		}
	}
	&__desc {
		font-family: 'Uni Neue Book', sans-serif;
		margin-top: 10px;
		max-width: 500px;
		font-size: 14px;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			margin-top: 17px;
		}
	}
	&__categories {
		margin-top: 10px;
	}
	&__info-list {
		position: relative;
		padding-top: 15px;
		margin-top: 20px;
		&::before {
			position: absolute;
			content: '';
			left: -5px;
			top: 0;
			width: calc(100% + 10px);
			height: 1px;
			background-color: $c-light-beige;
		}
	}
	&__cta {
		@extend .mobile-container-margin;
		margin-top: 25px;
		button {
			width: 100%;
		}
		.button._mobile {
			margin-top: 15px;
		}
		@include min-screen($breakpoint-xs) {
			width: 250px;

			.button._mobile {
				display: none;
			}
		}
	}
}

.doctor-card-categories {
	@include min-screen($breakpoint-sm) {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid $c-light-beige;
	}
	&__item {
		& + & {
			margin-top: 12px;
		}
	}
	&__wrap {
		display: inline-flex;
		align-items: center;
		text-decoration: underline;
		color: $c-dark;
		@extend .transition-active;

		&:hover {
			text-decoration: none;
			color: $c-brown;
		}
	}
	&__icon {
		flex-shrink: 0;
		align-self: flex-start;
		svg {
			width: 30px;
			height: 30px;
		}
	}
	&__text {
		padding-left: 10px;
		@include min-screen($breakpoint-lg) {
			font-size: 16px;
		}
	}
}
