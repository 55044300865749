@mixin float-label-container {
	display: block;
	position: relative;
}

@mixin float-label {
	label,
	> span.placeholder {
		position: absolute;
		left: 0;
		top: -20px;
		cursor: text;
		font-size: 12px;
		opacity: 1;
		transition: all 0.2s;
		font-family: 'Uni Neue Book', sans-serif;
		@content;
	}
	span {
		user-select: none;
		color: #a29f9a;
		._dark-form & {
			color: #606060;
		}
	}
}

@mixin float-label-input {
	&::placeholder {
		opacity: 1;
		transition: all 0.2s;
	}
	&:placeholder-shown:not(:focus)::placeholder {
		opacity: 0;
	}
}

@mixin float-label-scaled {
	&:placeholder-shown:not(:focus) + * {
		//font-size: 150%;
		//opacity: 0.5;
		@content;
	}
}

.float-input {
	$block: &;
	&:hover {
		#{$block} {
			&__line {
				background-color: $c-dark-gray;
				._dark-form & {
					background-color: #606060;
				}
			}
		}
	}
	&__label {
		@include float-label-container;
		@include float-label;

		&._textarea textarea {
			@include float-label-scaled {
				top: 0;
			}
		}

		&:focus {
			border-bottom-color: #a58a63;
		}

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		textarea {
			display: block;
			width: 100%;
			resize: none;
			padding-top: 12px;
			height: 22px;
			padding-bottom: 12px;
		}

		input,
		select,
		textarea {
			@include float-label-input;
			@extend %reset-input;
			width: 100%;
			height: 30px;
			font-size: 18px;
			font-family: 'Uni Neue Book', sans-serif;
			color: #1b1b1b;

			._big-search & {
				font-size: 18px;
				line-height: 1;
				@include min-screen($breakpoint-sm) {
					font-size: 20px;
				}
				@include smoothValue('font-size', 20px, 28px, $breakpoint-md, $breakpoint-lg);
			}

			&:focus ~ #{$block}__line {
				background-color: $c-brown;
				._dark-form & {
					background-color: #7f6847;
				}
			}

			@include float-label-scaled {
				top: 4px;
				font-size: 18px;

				@media (max-width: 767px) {
					font-size: 16px;
				}
				._big-search & {
					font-size: 18px;
					line-height: 1;
					@include min-screen($breakpoint-sm) {
						font-size: 20px;
					}
					@include smoothValue('font-size', 20px, 28px, $breakpoint-md, $breakpoint-lg);
				}
			}

			&:focus {
				outline: none;
			}
		}
	}
	&__line {
		position: absolute;
		display: block;
		width: 100%;
		height: 1px;
		background-color: #333333;
		margin-top: 13px;
		@extend .transition-active;

		._big-search & {
			height: 2px;
		}
		._dark-form {
		}
	}
}
