.birth-fs {
  max-width: 1920px;
  margin-top: 26px;
  @include min-screen($breakpoint-md) {
	margin: 25px auto 30px auto;
	@include smoothValue('margin-bottom', 30px, 40px, $breakpoint-md, $breakpoint-lg);
	box-sizing: content-box;
	padding-left: var(--container-indent);
	padding-right: var(--container-indent);
  }

  &__top {
	position: relative;
	overflow: hidden;
	background: url('../images/backgrounds/waves-left.png'), linear-gradient($c-light-beige, $c-light-beige);
	background-blend-mode: multiply;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top right;
	padding: 25px 15px 20px 15px;
	@include smoothValue('padding-top', 25px, 31px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 31px, 50px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 20px, 54px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 54px, 46px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-left', 15px, 28px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 28px, 45px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('padding-right', 15px, 28px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-right', 28px, 45px, $breakpoint-sm, $breakpoint-md);
	@include min-screen($breakpoint-md) {
	  @include desktop-container-padding;
	}

	border-bottom: 1px solid #e9e1d8;
  }
  &__bg {
	position: absolute;
	z-index: 1;
	bottom: 50px;
	right: 5px;
	@include smoothValue('bottom', 50px, -70px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('right', 5px, 135px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('bottom', -70px, -110px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('right', 135px, 380px, $breakpoint-sm, $breakpoint-lg);

	background: url('../images/backgrounds/baby.png');
	@include hdpi(1.5) {
	  background-image: url('../images/backgrounds/baby@2x.png');
	}
	background-size: cover;
	width: 250px;
	height: 220px;
	@include smoothValue('width', 250px, 450px, $breakpoint-xxs, $breakpoint-xs);
	@include smoothValue('height', 220px, 335px, $breakpoint-xxs, $breakpoint-xs);
	@include smoothValue('width', 450px, 570px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('height', 335px, 425px, $breakpoint-sm, $breakpoint-lg);

  }
  &__text {
	position: relative;
	z-index: 200;
  }
  &__title, &__euros {
	color: $c-burgundy;
	font: 400 23px/1.15 'Kudryashev Headline', sans-serif;
	@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-sm) {
	  line-height: 120%;
	}
  }
  &__title {
	margin-bottom: 5px;
	@include smoothValue('margin-bottom', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 10px, 20px, $breakpoint-sm, $breakpoint-lg);
  }
  &__subtitle {
	max-width: 190px;
	margin-bottom: 36px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 0;
	}
	@include min-screen($breakpoint-md) {
	  max-width: none;
	}
	@extend .base-text;
	font-weight: 500;
	line-height: 160%;
  }
  &__calc {
	width: 100%;
	@include min-screen($breakpoint-sm) {
	  width: auto;
	  display: flex;
	  position: absolute;
	  bottom: 27px;
	  right: 28px;
	  @include smoothValue('bottom', 27px, 38px, $breakpoint-sm, $breakpoint-lg);
	  @include smoothValue('right', 28px, 45px, $breakpoint-sm, $breakpoint-md);
	  @include smoothValue('right', 45px, 65px, $breakpoint-md, $breakpoint-lg);
	}
  }
  &__price {
	margin-bottom: 8px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 0;
	  margin-right: 30px;
	}
  }
  &__euros {
	margin-right: 12px;
	@include min-screen($breakpoint-sm) {
	  display: block;
	  margin-right: 0;
	  margin-bottom: 9px;
	  @include smoothValue('margin-bottom', 9px, 5px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__rub {
	color: $c-burgundy;
	font: 400 14px/1.6 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-sm) {
	  display: block;
	}
  }
  &__button {
	width: 100%;
	@include min-screen($breakpoint-xs) {
	  width: 159px;
	  margin: auto;
	  @include smoothValue('width', 159px, 183px, $breakpoint-sm, $breakpoint-lg);
	}
	margin-bottom: 14px;
	@include smoothValue('margin-bottom', 14px, 11px, $breakpoint-xxs, $breakpoint-sm);
	position: relative;
	z-index: 200;
	@include min-screen($breakpoint-md) {
	  height: 60px;
	}

	svg {
	  display: inline-block;
	  width: 16px;
	  height: 16px;
	  margin-right: 10px;
	}
  }
  &__cart-detail {
	position: relative;
	z-index: 500;
	display: block;
	width: max-content;
	color: $c-burgundy;
	font-size: 14px;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);
	line-height: 130%;
	border-bottom: 1px dashed currentColor;
	margin: 0 auto;
	cursor: pointer;
	@extend .transition-active;
	&:hover {
	  border-color: transparent;
	}
  }

  &__tabs {
	background-color: #F2EFEC;
	position: relative;
	@extend .container;

	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: 0;
	  width: 55px;
	  height: 100%;
	  background: linear-gradient(to right, transparent, #F2EFEC);
	}
	@include min-screen($breakpoint-sm) {
	  .tab-head__list {
		justify-content: space-between;
	  }
	}
	@include min-screen($breakpoint-md) {
	  .tab-head__list {
		justify-content: flex-start;
	  }
	  &::after {
		display: none;
	  }
	}
	@include min-screen($breakpoint-lg) {
	  margin: 0;
	  padding: 0;
	}
	.tab-head {
	  margin-top: 0;
	}
	.tab-head__item {
	  padding-left: 5px;
	  padding-right: 5px;
	  @include smoothValue('padding-left', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('padding-right', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);
	  &:not(:first-child) {
		@include min-screen($breakpoint-md) {
		  margin-left: 25px;
		}
	  }

	  svg {
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: text-bottom;
		margin-left: 5px;
		@include smoothValue('margin-left', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);
	  }
	}
  }
}
