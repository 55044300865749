.search-header {
	padding-top: 20px;
	padding-bottom: 30px;
	&__sausage-menu {
		display: none;
	}
	&__select {
		margin-bottom: 20px;
	}
	&__info {
		font-family: 'Uni Neue Book', sans-serif;
		font-weight: normal;
		font-size: 14px;
		line-height: 1.6;
		color: $c-dark-gray;
		span {
			color: $c-dark;
		}
	}
	@include min-screen($breakpoint-sm) {
		padding-top: 30px;
		&__sausage-menu {
			display: block;
			margin-bottom: 10px;
		}
		&__select {
			display: none;
		}
	}
	@include min-screen($breakpoint-md) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
