.warning {
  margin-top: -40px;
  @include min-screen($breakpoint-xs) {
	margin-top: -20px;
  }
  margin-bottom: 20px;
  width: 100%;
  background: #F2EFEC;
  border-left: 5px solid #8D3332;
  box-sizing: border-box;
  @include min-screen($breakpoint-lg) {
	border-left: 10px solid #8D3332;
  }

  &__head {
	@extend .container;
	max-width: none;
	padding-top: 10px;
	padding-bottom: 10px;
	@include min-screen($breakpoint-lg) {
	  padding-top: 25px;
	  padding-bottom: 15px;
	}
	@include min-screen($breakpoint-fhd + 100px) {
	   padding-left: 0;
	}
	display: flex;
	justify-content: space-between;
	font: 500 12px/16px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('line-height', 16px, 22px, $breakpoint-sm, $breakpoint-lg);
  }
  &__main {
	padding-bottom: 10px;
	@include min-screen($breakpoint-sm) {
	  padding-bottom: 15px;
	}
	@include min-screen($breakpoint-lg) {
	  padding-bottom: 25px;
	}
  }
  &__title {
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	text-transform: uppercase;
	color: #1B1B1B;
	@include min-screen($breakpoint-fhd + 100px) {
	  margin-left: calc((100vw - 2020px) / 2);
	  padding-left: 50px;
	}
  }
  &__close {
	cursor: pointer;
	font-size: 0;
	color: #8D3332;
	@extend .transition-active;
	&:hover {
	  text-decoration: underline;
	}
	svg {
	  display: inline-block;
	  width: 15px;
	  height: 15px;
	  margin-left: 10px;
	  color: currentColor;
	  vertical-align: middle;

	  @include min-screen($breakpoint-lg) {
		width: 20px;
		height: 20px;
	  }
	}
  }
  &__heading {
	font: 500 14px/1.6 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-lg) {
	  line-height: 1.1;
	}
  }
  &__text {
	max-width: 1726px;
	font: 400 12px/16px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('line-height', 18px, 20px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-md) {
	  & span {
		display: block;
	  }
	}
	& strong {
	  display: block;
	  font-weight: 500;
	}

	p {
	  margin: 10px 0;
	  &:first-child{ margin-top: 0; }
	  &:last-child{ margin-bottom: 0; }
	}

	a{
	  color: #8D3332;
	}
  }
}

.header._fixed .warning, ._open-menu .warning {
  display: none;
}
