.resume-text {
  	@extend .tiny-section-mt;

	.title-h4 {
		color: $c-burgundy;
		margin-bottom: 10px;
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}

		* + & {
			margin-top: 24px;
			@include min-screen($breakpoint-sm) {
				margin-top: 30px;
			}
			@include min-screen($breakpoint-md) {
				margin-top: 40px;
			}
		}
		@include min-screen($breakpoint-md) {
			font-size: 26px;
		}
	}
	&__title {
		font-family: 'Kudryashev Headline', sans-serif;
		line-height: 1.2;
		color: $c-burgundy;
		margin-bottom: 10px;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}
		&.title-h4 {
			margin-top: 0;
		}
	}
	p + p,
	p + ul,
	ul + p {
		margin-top: 15px;
	}
	.styled-ul {
		@include min-screen($breakpoint-sm) {
			li {
				padding-left: 50px;
				&::before {
					left: 20px;
				}
			}
			li + li {
				margin-top: 20px;
			}
		}
	}
	.g-bold {
		font-family: 'Uni Neue', sans-serif;
	}
}
