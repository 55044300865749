//для IE 11

._ie {
	.directions-slider__bg {
		display: none;
	}
}

.directions {
	.standard-slider__arrow {
		&:hover {
			color: $c-burgundy;
		}
	}
	&__wrap {
		position: relative;
		padding-top: 36px;
		padding-bottom: 50px;
		&::before {
			position: absolute;
			content: '';
			z-index: -1;
			left: calc(-1 * var(--container-indent));
			top: 0;
			width: calc(100% + 2 * var(--container-indent));
			height: 100%;
			background: $c-block-beige-bg url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		}
		@include smoothValue('padding-top', 30px, 50px, $breakpoint-xs, $breakpoint-sm);
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding-left;
			padding-bottom: 70px;

			&::before {
				left: 0;
			}
		}
		@include min-screen($breakpoint-lg) {
			padding-bottom: 100px;
		}
		@include smoothValue('padding-top', 92px, 56px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding-top', 108px, 108px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__section-wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
		padding-top: 20px;
		@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		border-bottom: 1px solid #f1edea;
	}
	&__title {
		@extend .title-h2;
		@include max-screen($breakpoint-xs - 1px) {
			font-size: 38px;
			line-height: 45px;
		}

		color: $c-white;
	}
	&__section-title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 15px;
		@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-md);
	}
	&__text {
		@extend .subtitle-20-100;
		margin-top: 16px;
		@include smoothValue('margin-top', 16px, 36px, $breakpoint-xs, $breakpoint-sm);
		@include min-screen($breakpoint-md) {
			transform: translateX(calc(-1 * var(--col-gutter)));
		}
		@include min-screen($breakpoint-lg) {
			transform: translateX(var(--col-gutter));
		}
	}
	&__link {
		margin-top: 20px;
		@include smoothValue('margin-top', 20px, 30px, $breakpoint-xs, $breakpoint-sm);
		@include min-screen($breakpoint-md) {
			transform: translateX(calc(-1 * var(--col-gutter)));
		}
		@include min-screen($breakpoint-lg) {
			transform: translateX(var(--col-gutter));
			margin-top: 58px;
		}
	}
	&__inline-link {
		@extend .simple-link;
		color: $c-burgundy;
	}
	&__section-link {
		margin-top: 15px;
		margin-bottom: 30px;
		text-align: center;
		@include smoothValue('margin-top', 15px, 30px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__form {
		margin-top: 53px;

		@include smoothValue('margin-top', 53px, 162px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-top', 162px, 115px, $breakpoint-sm, $breakpoint-md);
		@include min-screen($breakpoint-xs) {
			max-width: 307px;
		}
		@include smoothValue('max-width', 433px, 522px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('margin-top', 100px, 136px, $breakpoint-lg, $breakpoint-fhd);

		input::placeholder {
			color: $c-dark;
		}
	}
	&__section-form {
		margin-bottom: 30px;
	}
	&__slider {
		margin-top: 32px;
		@include smoothValue('margin-top', 32px, 50px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-top', 50px, 40px, $breakpoint-sm, $breakpoint-md);
		@include min-screen($breakpoint-lg) {
			margin-top: 60px;
		}
	}
	//only rendered on desktop
	&__section-arrows {
		right: 0;
		@include max-screen($breakpoint-md) {
			display: none;
		}
	}
	//&__slider-wrap {
	//  @include min-screen($breakpoint-lg) {
	//	overflow-x: hidden;
	//  }
	//} //Hides overflow-y as well -- fix??
	&__caption {
		@extend .text-17-300;
		margin-bottom: 30px;
	}
}

.directions-slider {
	transition: transform $default-transition-duration $default-transition-function,
		opacity $default-transition-duration $default-transition-function;

	.flickity-viewport {
		overflow: visible;
	}
	&__slide {
		position: relative;
		margin-right: 15px;
		@include min-screen($breakpoint-xs) {
			margin-right: 25px;
		}
		@include smoothValue('margin-right', 25px, 45px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__section-slide {
		position: relative;
		margin-right: 15px;
		@include smoothValue('margin-right', 15px, 30px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__content {
		width: 260px;
		padding-top: 100%;
		@include min-screen($breakpoint-xs) {
			width: 200px;
		}
		@include smoothValue('width', 230px, 300px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__section-content {
		width: 240px;
		padding-top: 100%;
		@include min-screen($breakpoint-xs) {
			width: 200px;
		}
		@include smoothValue('width', 240px, 280px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__info {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 25px;
		box-sizing: border-box;
		@extend .transition-active;

		@include min-screen($breakpoint-sm) {
			padding: 22px;
		}

		._desktop & {
			background-color: #f3f2ed;
			&:hover {
				background-color: #fff;
			}
		}
	}
	&__bg {
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		mix-blend-mode: multiply;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background: url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		@supports not (mix-blend-mode: multiply) {
			z-index: -1;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__list {
		&-item {
			display: block;
			& + & {
				margin-top: 10px;
			}
		}
	}

	&__name {
		font-family: 'Museo Sans Cyrl', sans-serif;
		flex-grow: 1;
	  	word-break: break-word;
		color: $c-burgundy;
		font-size: 18px;
		line-height: 1.2;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__link {
		position: relative;
		font-weight: 500;
		font-size: 14px;
		line-height: 160%;
		color: $c-dark-gray;

		&::before {
			position: absolute;
			content: '';
			left: 100%;
			top: 0;
			bottom: 0;
			width: 23px;
			height: 17px;
			background-image: url("data:image/svg+xml,%3Csvg width='23' height='17' viewBox='0 0 23 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9.5H22' stroke='%238D3332'/%3E%3Cpath d='M17 4L22.5 9.5L17 15' stroke='%238D3332'/%3E%3C/svg%3E%0A");
			opacity: 0;
			transform: translateX(5px);
			@extend .transition-move;
		}

		._desktop &:hover {
			&::before {
				opacity: 1;
				transform: translateX(10px);
			}
		}
	}
}
