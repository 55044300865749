.about {
	@include small-section-mt();

	&:last-child {
	  	@include large-section-mb;
	  	.g-delimeter {
		  display: none;
		}
	;
	}

	&__wrap {
		@include min-screen($breakpoint-md) {
			display: flex;
		}
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__title, h2 {
		@extend .title-h3;
		color: $c-burgundy;
	}
	&__text {
		@extend .base-text;
		line-height: 1.6;
		margin-top: 10px;
		@include min-screen($breakpoint-sm) {
			margin-top: 20px;
		}
		@include min-screen($breakpoint-lg) {
			max-width: 91%;
		}
		&._specialization {
			margin-top: 0;

		  	h2 {
			  	font: 300 20px/1.4 'Museo Sans Cyrl', sans-serif;
			  	@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
			  	color: $c-burgundy;

				margin-bottom: 10px;
			  	@include min-screen($breakpoint-sm) {
				  	margin-bottom: 15px;
				}
				@include min-screen($breakpoint-md) {
				  	margin-bottom: 20px;
				}
				&:not(:first-child) {
					margin-top: 20px;
					@include min-screen($breakpoint-sm) {
					  	margin-top: 30px;
					}
					@include min-screen($breakpoint-md) {
					  	margin-top: 40px;
					}
				}
			}
		  	ul {
			  @extend .styled-ul;
			}
		  	ol {
			  @extend .styled-ol;
			}
			.styled-ul,
			ul {
				@include min-screen($breakpoint-sm) {
					li {
						padding-left: 30px;
						&::before {
							left: 2px;
						}
					}
					li + li {
						margin-top: 20px;
					}
				}
			}
			.styled-ul + p,
			ul + p {
				margin-top: 1.6em;
			}

			img {
				@include mobile {
					width: 100% !important;
					height: auto !important;
				}
			}
		}
	}
	&__info {
		@include min-screen($breakpoint-md) {
			width: 10/14 * 100%;
		}
	}
	&__people {
		margin-top: 30px;
		@include min-screen($breakpoint-sm) {
			margin-top: 42px;
		}
		@include min-screen($breakpoint-md) {
			width: 4/14 * 100%;
		}
		&._specialization {
			margin-top: 0;
			@include max-screen($breakpoint-md - 1px) {
				display: none;
			}
		}
	}
	&__more {
		display: none;
		margin-top: 1.6em;
	}
	&__btn {
		@extend %reset-Button;
		display: flex!important;
		align-items: baseline;
		margin-top: 10px;
		text-decoration: underline;
		font-size: 14px;

		@include min-screen($breakpoint-lg) {
			font-size: 16px;
		}

		&:hover {
			text-decoration: none;
		}

		@include min-screen($breakpoint-lg) {
			margin-top: 20px;
		}
		&._book {
			font-family: 'Uni Neue Book', sans-serif;
			&._opened {
				color: $c-beige;
			}
		}
		&._opened {
			.open {
				display: none;
			}
			.close {
				display: initial;
			}
		}
		.close {
			display: none;
		}
	}
	&__shevron {
		//position: absolute;
		//content: '';
		width: 9px;
		height: 7px;
		margin-left: 5px;
		transform: rotate(180deg);
		color: $c-burgundy;
		@extend .transition-move;
		._opened & {
			transform: rotate(0);
			color: $c-beige;
		}
	}
}

.about-people {
	font-family: 'Museo Sans Cyrl', sans-serif;
	@include min-screen($breakpoint-xs) {
		text-align: center;
	}
	@include min-screen($breakpoint-lg) {
		text-align: left;
	}
	&__photo {
		width: 250px;
		margin-bottom: 10px;
		img {
			width: 100%;
		}
		@include min-screen($breakpoint-xs) {
			margin-left: auto;
			margin-right: auto;
		}
		@include min-screen($breakpoint-lg) {
			margin-left: 0;
		}
	}
	&__name {
		font-size: 26px;
		line-height: 1.6;
		font-weight: 300;
		color: $c-burgundy;
	}
	&__position {
		font-size: 12px;
		line-height: 1.25;
		font-weight: 300;
		text-transform: uppercase;
	}
	&__text {
		margin-top: 10px;
		color: $c-dark-gray;
		font-weight: 300;
		font-size: 10px;
		line-height: 1.7;
		text-transform: uppercase;
		@include min-screen($breakpoint-xs) {
			max-width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
		@include min-screen($breakpoint-md) {
			max-width: 290px;
		}
		@include min-screen($breakpoint-lg) {
			margin-left: 0;
		}
	}
}
