.article-videos-list {
	//margin-top: 20px;
	//@include min-screen($breakpoint-lg) {
	//	margin-top: 40px;
	//}
	&__wrap {
		padding-top: 20px;
		border-top: 1px solid $c-light-beige;
		@include min-screen($breakpoint-sm) {
			padding-top: 30px;
		}
		@include min-screen($breakpoint-md) {
			padding-top: 0;
			border-top: none;
		}
	}
	&__item {
		padding-top: 25px;
		border-top: 1px solid $c-light-beige;
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 25px;
		}
		@include min-screen($breakpoint-lg) {
			margin-top: 30px;
			padding-top: 30px;
		}
		&:first-child {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
		}
	}
	&__poster {
		width: 100%;
		height: 176px;
		max-width: 595px;
		@include smoothValue('height', 176px, 335px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 335px, 440px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			height: vw(440px, 1440px);
			max-height: 560px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@include min-screen($breakpoint-md) {
			max-width: initial;
		}
	}
	&__video._article {
		margin-left: 0;
		height: 176px;
		@include smoothValue('height', 176px, 335px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 335px, 440px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-md) {
			max-width: initial;
		}
		@include min-screen($breakpoint-lg) {
			height: vw(440px, 1440px);
			max-height: 560px;
		}
	}
	&__link {
		@extend .transition-active;
		display: inline-block;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		font-size: 18px;
		margin-top: 15px;
		@include smoothValue('margin-top', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 20px, 25px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('font-size', 18px, 22px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 22px, 26px, $breakpoint-sm, $breakpoint-lg);
		&:hover {
			text-decoration: underline;
			color: $c-beige;
		}
	}
	&__date {
		font-size: 14px;
		margin-top: 5px;
		font-family: 'Uni Neue Book', sans-serif;
	}
}
