.news-list {
	&__line {
		.ticket {
			margin-top: 0;
			margin-bottom: 0;
		}
		& + & {
			padding-top: 25px;
			margin-top: 20px;
			border-top: 1px solid $c-light-beige;
			@include min-screen($breakpoint-sm) {
				padding-top: 30px;
				margin-top: 30px;
			}
		}
		&:last-child {
			margin-bottom: 25px;
			@include min-screen($breakpoint-sm) {
				margin-bottom: 30px;
			}
		}
	}

	&__aside {
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			display: flex;
			justify-content: space-between;
		}
	}
	&__search {
		width: vw(340px, $breakpoint-lg);
		@include min-screen($breakpoint-xs) {
			min-width: 340px;
		}
	}
	&__years {
		margin-top: 20px;
		width: 150px;
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			margin-top: 0;
		}
	}
	&__info {
		@include max-screen($breakpoint-md - 1px) {
			display: none;
		}
	}
	&__see-all {
		margin-top: 25px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			text-align: center;
		}
	}
}

.news-list-item {
	display: flex;
	flex-wrap: wrap;
	@include min-screen($breakpoint-xs) {
		flex-wrap: nowrap;
	}
	&__image {
		flex: 0 0 100%;
		@include min-screen($breakpoint-xs) {
			flex-basis: 255px;
		}
		@include max-screen($breakpoint-xs - 1px) {
			margin-bottom: 15px;
		}
		img {
			width: 100%;
			max-height: 180px;
			object-fit: contain;
		}
	}
	&__content {
		flex-grow: 1;
		@include min-screen($breakpoint-xs) {
			padding-left: 25px;
		}
		&._no-pl {
			padding-left: 0;
		}
	}
	&__title {
		font-family: 'Museo Sans Cyrl', sans-serif;
		text-decoration: none;
		font-size: 18px;
		font-weight: 100;
		@include min-screen($breakpoint-md) {
			font-weight: 300;
			font-size: 20px;
		}
		&:hover {
			text-decoration: underline;
		}
		&._big {
			@include min-screen($breakpoint-sm) {
				font-size: 20px;
			}
			@include min-screen($breakpoint-md) {
				font-size: 26px;
			}
		}
	}
	&__date {
		color: $c-dark-gray;
		font-size: 14px;
		font-family: 'Uni Neue Book', sans-serif;
		margin-top: 5px;
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			font-size: 12px;
			margin-top: 10px;
		}
	}
	&__text {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		margin-top: 15px;
		@include min-screen($breakpoint-sm) {
			margin-top: 20px;
		}
		@include min-screen($breakpoint-md) {
			font-size: 16px;
		}
	}
}
