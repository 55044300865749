@mixin hover-effect($block) {
	#{$block} {
		&__bg {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: $c-white !important;
			border-color: $c-light-beige;
		}

		&__top {
			//bottom: calc(100% - 4em);
			//@include min-screen($breakpoint-md) {
			//	bottom: calc(100% - 5em);
			//}
			&._left {
				padding-left: 120px;
				text-align: right;
			}
		}

		&__title {
			margin-top: 0;
			&._left {
				text-transform: uppercase;
			}
		}

		&__desc {
			opacity: 0;
			transition: none;
		}

		&__bottom {
			transform: translateY(0);
			opacity: 1;
			&._shifting {
				margin-left: calc(-1 * var(--list-offset) / 2);
				margin-bottom: calc(-1 * var(--list-offset) / 2);
			}
		}

		&__icon {
			border-color: $c-light-beige;
			&._shifting {
				top: calc(20px - (var(--list-offset) / 2));
				left: calc(20px - (var(--list-offset) / 2));
			}
		}
	}
}

.programs-list {
	$block: &;
	margin-top: 20px;
	padding-bottom: 50px;
	@include smoothValue('padding-bottom', 50px, 70px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 70px, 100px, $breakpoint-sm, $breakpoint-lg);

	&._section {
		margin-top: 30px;
		@include smoothValue('margin-top', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 40px, 50px, $breakpoint-sm, $breakpoint-lg);
		#{$block} {
			&__blocks {
				margin-top: 7.5px;
			}
		}
	}
  	&._small-pb {
  		padding-bottom: 40px;
	  	@include smoothValue('padding-bottom', 40px, 70px, $breakpoint-sm, $breakpoint-lg);
	  	@include smoothValue('padding-bottom', 20px, 25px, $breakpoint-xxs, $breakpoint-sm);
	  	@include smoothValue('margin-top', 25px, 35px, $breakpoint-sm, $breakpoint-lg);
	}
	&._with-pagination {
		@include tiny-section-pb();
	}
	&._no-pb {
		padding-bottom: 0;
		overflow: visible;
		& .programs-list-blocks {
			margin-bottom: calc(-1 * var(--list-offset) / 2);
		}
	}
	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding();
		}
	}
  	&__heading {
		font: 300 22px/1.2 'Museo Sans Cyrl', sans-serif;
		color: $c-burgundy;
		@include smoothValue('font-size', 22px, 36px, $breakpoint-xxs, $breakpoint-sm);
		@include min-screen($breakpoint-sm) {
		  	line-height: 1.3;
		}
		&:not(._no-mb) {
		  	margin-bottom: 20px;
		  	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
		}
		margin-top: 30px;
		@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
	}
	&__sausage-menu {
		@include max-screen($breakpoint-sm - 1px) {
			display: none;
		}
	}
	&__select {
		@include min-screen($breakpoint-sm) {
			display: none;
		}
	}
	&__blocks {
		margin-top: 20px;
	}
}

@mixin smoothValueTablet($prop, $min, $max, $minViewPort: 320px, $maxViewPort: $breakpoint-lg) {
	@media (min-width: $minViewPort) and (max-width: $maxViewPort) {
		#{$prop}: calc(
						#{$min} + #{strip-unit($max - $min)} *
						((100vw - #{$minViewPort}) / (#{strip-unit($maxViewPort - $minViewPort)}))
		);
	}
}

.programs-list-blocks {
	$block: &;
	--list-offset: 15px;
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(-1 * var(--list-offset) / 2);
	margin-right: calc(-1 * var(--list-offset) / 2);
	@include min-screen($breakpoint-sm) {
		--list-offset: 20px;
	}
	@include min-screen($breakpoint-lg) {
		--list-offset: 40px;
	}

	&._offset-large {
		@include min-screen($breakpoint-sm) {
			--list-offset: 30px;
		}
		@include min-screen($breakpoint-lg) {
			--list-offset: 35px;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		padding: calc(var(--list-offset) / 2);
		box-sizing: border-box;

		&:not(._lg-only-effect):hover,
		&:not(._lg-only-effect):focus {
			@include hover-effect($block);
		}

		&._lg-only-effect {
			@include min-screen($breakpoint-md) {
				&:hover,
				&:focus {
					@include hover-effect($block);
				}
			}
		}

		&._tablet-size-1 {
			#{$block} {
				&__title {
					@include smoothValueTablet('font-size', 16px, 20px, $breakpoint-sm, $breakpoint-md);
				}
			}
		}

		&._tablet-size-2 {
			#{$block} {
				&__title {
					@include smoothValueTablet('font-size', 16px, 26px, $breakpoint-sm, $breakpoint-md);
				}
			}
		}

		&._tablet-size-3 {
			#{$block} {
				&__title {
					@include smoothValueTablet('font-size', 16px, 26px, $breakpoint-sm, $breakpoint-md);
				}
			}
		}

		&._desktop-size-1 {
			#{$block} {
				&__title {
					@include smoothValue('font-size', 20px, 18px, $breakpoint-md, $breakpoint-lg);
				}
			}
		}

		&._desktop-size-2 {
			#{$block} {
				&__title {
					@include smoothValue('font-size', 26px, 24px, $breakpoint-md, $breakpoint-lg);
				}
			}
		}

		&._desktop-size-3 {
			#{$block} {
				&__title {
					@include smoothValue('font-size', 26px, 28px, $breakpoint-md, $breakpoint-lg);
				}
			}
		}

		@include min-screen($breakpoint-sm) {
			&._tablet-size-1 {
				width: 7/16 * 100%;
			}
			&._tablet-size-2 {
				width: 9/16 * 100%;
			}
			&._tablet-size-3 {
				width: 100%;
			}
			&._tablet-size-1-of-2 {
				width: 50%;
			}
		}
		@include min-screen($breakpoint-md) {
			&._desktop-size-1 {
				width: 1/3 * 100%;
			}
			&._desktop-size-1-of-3 {
				width: 1/3 * 100%;
			}
			&._desktop-size-2 {
				width: 2/3 * 100%;
			}
			&._desktop-size-3 {
				width: 100%;
			}
			&._desktop-size-1-of-4 {
				width: 25%;
			}
			&._desktop-size-2-of-4 {
				width: 50%;
			}
			&._desktop-size-3-of-4 {
				width: 75%;
			}
			&._desktop-size-4-of-4 {
				width: 100%;
			}
		}
	}

	&__bg {
		position: absolute;
		z-index: -1;
		left: calc(var(--list-offset) / 2);
		top: calc(var(--list-offset) / 2);
		width: calc(100% - var(--list-offset));
		height: calc(100% - var(--list-offset));
		transition: all $default-transition-function $default-transition-duration;
		border: 1px solid transparent;

		&::after {
			position: absolute;
			content: '';
			z-index: 5;
			top: 0;
			left: 0;
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			background: url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		}
		&._right {
			transform: scaleX(-1);
			&::after {
				background-size: 75%;
			}
		}
	}

	&__wrap {
		position: relative;
		height: 240px;
		&._short {
			height: 210px;
			@include smoothValue('height', 210px, 220px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('height', 220px, 240px, $breakpoint-sm, $breakpoint-lg);
		}
		&._tiny {
			height: 180px;
			@include smoothValue('height', 180px, 188px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('height', 188px, 210px, $breakpoint-sm, $breakpoint-lg);
		}
	}

	&__top {
		position: absolute;
		bottom: 15px;
		right: 20px;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding-left: 120px;
		text-align: right;
		transition: all $default-transition-duration $default-transition-function;
		margin-top: 0;
		margin-bottom: auto;
		box-sizing: border-box;

		&._left {
			padding-left: 40px;
			text-align: left;
		}
		&._mb-small {
			bottom: 3px;
			@include smoothValue('bottom', 3px, 15px, $breakpoint-xxs, $breakpoint-sm);
		}
	}

	&__bottom {
		position: absolute;
		bottom: 15px;
		left: 20px;
		transform: translateY(50px);
		opacity: 0;
		@extend .transition-move;
	}

	&__icon {
		position: absolute;
		top: 20px;
		left: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		border: 1px solid $c-light-beige;
		overflow: hidden;
		background-color: $c-white;
		border: 1px solid transparent;

		&._shifting {
			transition: all $default-transition-duration $default-transition-function;
		}
		&._modal {
			left: 30px;
			border: 1px solid $c-light-beige;
			@include min-screen($breakpoint-sm) {
				top: 30px;
				left: 60px;
				width: 100px;
				height: 100px;
			}
			svg {
				@include smoothValue('width', 36px, 51px, $breakpoint-xxs, $breakpoint-sm);
				@include smoothValue('height', 36px, 51px, $breakpoint-xxs, $breakpoint-sm);
			}
		}

		&::after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 200%;
			height: 200%;
			background: url('../images/backgrounds/waves-small.png') right top no-repeat;
			background-size: cover;
			mix-blend-mode: multiply;
		}

		svg {
			width: 36px;
			height: 36px;
		}
	}

	&__title {
		padding-top: 20px;
		font-size: 16px;
		text-transform: uppercase;
		margin-top: auto;

		&._left {
			max-width: 540px;
			@extend .title-h6;
			text-transform: none;
			margin-bottom: 10px;
		}
		&._modal {
			padding-top: 0;
			margin-bottom: 33px;
			@include smoothValue('margin-bottom', 33px, 55px, $breakpoint-xxs, $breakpoint-sm);
			margin-left: auto;
			text-align: right;
			font-size: 12px;
			@include smoothValue('font-size', 12px, 22px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('line-height', 16px, 30px, $breakpoint-xxs, $breakpoint-sm);
		}
	}

	&__desc {
		@extend .transition-move;
		text-transform: uppercase;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		font-size: 10px;

		&._left {
			@extend .tiny-text;
			@include min-screen($breakpoint-sm) {
				font-size: 10px;
			}
		}

		&._gold {
			line-height: 1.7;
			color: #A58A63;
		}

		& svg {
			display: inline-block;
			width: 17px;
			height: 17px;
			margin-right: 5px;
			vertical-align: text-bottom;
		}
	}

	&__text {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		line-height: 1.6;
		max-width: 460px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		&._with-mb {
			margin-bottom: 20px;
		}
		&._modal {
			max-width: 425px;
			font-size: 12px;
			margin-bottom: 25px;
			@include min-screen($breakpoint-sm) {
				font-size: 18px;
			}
		}
	}

	&__link {
		margin-top: 20px;
		color: $c-burgundy;
		span {
			font-size: 14px;
			line-height: 1.6;
		}
		@include min-screen($breakpoint-sm) {
			font-size: 18px;
		}
	}

	&__show-more {
		position: absolute;
		z-index: 10;
		top: calc(20px + var(--list-offset) / 2);
		right: calc(20px + var(--list-offset) / 2);
		width: 30px;
		height: 30px;
		background-color: $c-gray;
		border-radius: 50%;
		border: 1px solid #ddd8d1;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg {
			width: 8px;
			height: 15px;
		}
		@include min-screen($breakpoint-md) {
			display: none;
		}
	}
}

.programs-services-blocks {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
	--paddings: 0;
	margin-left: calc(var(--paddings) / 2 * -1);
	margin-right: calc(var(--paddings) / 2 * -1);
	@include min-screen($breakpoint-xs) {
		--paddings: 15px;
	}
	@include min-screen($breakpoint-sm) {
		--paddings: 30px;
	}

	&__item {
		margin-top: 15px;
	  	width: 100%;
		padding-left: calc(var(--paddings) / 2);
		padding-right: calc(var(--paddings) / 2);
		box-sizing: border-box;
		@include min-screen($breakpoint-sm) {
			margin-top: var(--paddings);
		}
		@include min-screen($breakpoint-md) {
			margin-top: 25px;
		}

		.tiles-item {
			height: 210px;
			width: 100%;
			@include min-screen($breakpoint-xs) {
				height: 220px;
			}
			@include min-screen($breakpoint-md) {
				height: 250px;
			}

			&__wrap {
				display: flex;
				flex-direction: column;
			}
		}

		.tiles-item__title {
			margin-top: auto;
			font-size: 18px;
			@include min-screen($breakpoint-xs) {
				font-size: 20px;
			}
		}

		@include screen($breakpoint-xs, $breakpoint-md - 1px) {
			width: 50%;
			&:nth-child(3n) {
				width: 100%;
			}
		}
		@include min-screen($breakpoint-md) {
			width: 25%;
			&:nth-child(6n - 3) {
				width: 50%;

				.tiles-item__title {
					font-size: 26px;
				}
			}
			&:nth-child(6n - 2) {
				width: 50%;

				.tiles-item__title {
					font-size: 26px;
				}
			}
		}
	}
}
