@import 'grid-mix';
@import 'breakpoints';
@import 'visibility';

$breakpoint-fhd: 1920px;
$breakpoint-lg: 1440px;
$breakpoint-lg-2: 1280px;
$breakpoint-md: 1024px;
$breakpoint-sm: 768px;
$breakpoint-xs: 576px;
$breakpoint-xxs: 320px;


$cols-total: 16;

$grid-breakpoints: (
	xxs: 0,
	xs: (
		$breakpoint-xs - 1,
	),
	sm: (
		$breakpoint-sm - 1,
	),
	md: (
		$breakpoint-md - 1,
	),
	lg: (
		$breakpoint-lg - 1,
	),
) !default;

$container-max-widths: (
	xxs: $container-max-width,
	xs: $container-max-width,
	sm: $container-max-width,
	md: $container-max-width,
	lg: $container-max-width,
) !default;

$grid-columns: $cols-total !default;

$grid-gutter-widths: (
	xxs: $indent-col-xs,
	xs: $indent-col-xs,
	sm: $indent-col-sm,
	md: $indent-col-md,
	lg: $indent-col-lg,
) !default;

.skin {
	@include make-container();
	@include make-container-max-widths();
}

.skin-fluid {
	@include make-container();
}

// Generate grid classes
//@include make-grid-classes();

// Generate 10-columns grid
@include make-grid-classes(16, $grid-gutter-widths, $grid-breakpoints, grid);

// Generate visibility classes
//@include make-visibility-classes();
