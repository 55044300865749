.emc-clinic {
	position: relative;
	$block: &;
	@include min-screen($breakpoint-md) {
		overflow: hidden;
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		padding-top: 92px;
		position: relative;
		padding-bottom: 47px;
		&::before {
			position: absolute;
			content: '';
			z-index: -1;
			left: calc(-1 * var(--container-indent));
			top: 0;
			width: calc(100% + 2 * var(--container-indent));
			height: 100%;
			background: $c-light-beige url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		}
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding-left;

			&::before {
				left: 0;
			}
		}
		@include min-screen($breakpoint-lg) {
			padding-bottom: 65px;
		}
		@include smoothValue('padding-top', 92px, 56px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding-top', 92px, 108px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__title {
		@extend .title-h2;
		color: $c-burgundy;

		@include max-screen($breakpoint-xs - 1px) {
			font-size: 38px;
			line-height: 45px;
		}

		span {
			color: $c-beige;
		}
	}
	&__text {
		@extend .subtitle-20-100;
		margin-top: 16px;
		line-height: 1.3;
		@include max-screen($breakpoint-xs - 1px) {
			span {
				display: block;
			}
		}
		@include min-screen($breakpoint-sm) {
			max-width: 55%;
			margin-top: 20px;
			br {
				display: none;
			}
		}
		@include min-screen($breakpoint-fhd) {
			max-width: 820px;
		}
	}
	&__advantages {
		margin-top: 27px;
		@include min-screen($breakpoint-sm) {
			margin-top: 0;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 21px;
		}
		@include smoothValue('margin-top', 126px, 247px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('margin-top', 247px, 304px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__search {
		margin-top: 50px;
		order: 2;

		@include max-screen($breakpoint-sm - 1px) {
			order: 2;
		}
		@include min-screen($breakpoint-sm) {
			max-width: 320px;
			margin-top: 60px;
			order: 0;
			.form-search {
				&__text {
					display: none;
				}
			}
		}
		@include min-screen($breakpoint-lg) {
			margin-top: 80px;
		}
		@include smoothValue('margin-top', 80px, 116px, $breakpoint-lg, $breakpoint-fhd);
		@include min-screen($breakpoint-lg + 1px) {
			max-width: 522px;
		}
	}
	&__image {
		position: relative;
		//mix-blend-mode: darken;
		//margin-top: -98px;
		margin-right: calc(-1 * var(--container-indent));
		width: 120%;
		opacity: 0.5;
		@include smoothValue('height', 264px, 431px, $breakpoint-xxs, $breakpoint-sm);

		@include smoothValue('height', 431px, 446px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('width', 648px, 670px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('height', 446px, 618px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('width', 670px, 928px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('height', 618px, 749px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('width', 928px, 1124px, $breakpoint-lg, $breakpoint-fhd);

		@include min-screen($breakpoint-sm) {
			margin-top: -98px;
			margin-left: 25%;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			right: -10%;
			top: 140px;
		}
		@include min-screen($breakpoint-lg) {
			right: -4%;
		}

		canvas {
			width: 100%;
		}

	  	&._fallback {
		  	background-image: url('../images/backgrounds/building-emc.png');
		  	background-size: cover;
		  	background-repeat: no-repeat;
		  	background-position: center top;
		  	mix-blend-mode: multiply;
			@include hdpi(1.5) {
			  	background-image: url('../images/backgrounds/building-emc@2x.png');
			}
		  	@include min-screen($breakpoint-md) {
			  background-size: contain;
			}
		}
	}
	&__link {
		margin-top: 22px;
		&._hide-desktop {
			@include min-screen($breakpoint-sm) {
				display: none;
			}
		}
	}
}

.emc-clinic-advantages {
	counter-reset: factors-counter;
	--item-margin: 25px;
	margin-bottom: calc(-1 * var(--item-margin));
	@include min-screen($breakpoint-md) {
		--item-margin: 22px;
	}
	&__item {
		display: flex;
		margin-bottom: var(--item-margin);
		@include max-screen($breakpoint-sm - 1px) {
			&::before {
				counter-increment: factors-counter;
				content: counter(factors-counter);
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				background-color: $c-beige;
				color: $c-burgundy;
				border-radius: 50%;
				margin-right: 15px;
				font-family: 'Kudryashev Headline', sans-serif;
				font-weight: 500;
				font-size: 18px;
			}
		}

		&-wrap {
			width: 100%;
		}
	}

	&__title {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 22px;
		line-height: 1.3;
		color: $c-beige;
		@include min-screen($breakpoint-lg) {
			font-weight: 300;
		}
		@include smoothValue('font-size', 22px, 26px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 26px, 28px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__text {
		font-family: 'Uni Neue Book', sans-serif;
		@include smoothValue('font-size', 16px, 20px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 18px, 24px, $breakpoint-lg, $breakpoint-fhd);

		@include min-screen($breakpoint-lg) {
			br {
				display: none;
			}
		}
	}
}
