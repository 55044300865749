.cart-birth {
  font: 400 14px/1.3 'Uni Neue', sans-serif;
  @include min-screen($breakpoint-sm) {
	font-size: 16px;
	line-height: 1.5;
  }
  &__section {
	&:not(._no-padding) {
	  padding: 8px 10px 9px 10px;
	  @include min-screen($breakpoint-sm) {
		padding-top: 15px;
		padding-bottom: 11px;
	  }
	}
	&:not(:last-child) {
	  border-bottom: 1px solid #D3D0CE;
	}
	&._centered {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	}
  }
  &__title {
	font: 400 23px/1.2 'Kudryashev Headline', sans-serif;
	color: $c-burgundy;
	margin-bottom: 18px;
	@include min-screen($breakpoint-sm) {
	  font-size: 38px;
	  margin-bottom: 15px;
	}
  }
  &__icon {
	margin-bottom: 6px;

	svg {
	  width: 60px;
	  height: 60px;
	}

	@include min-screen($breakpoint-sm) {
	  margin-bottom: 11px;

	  svg {
		width: 72px;
		height: 72px;
	  }
	}
  }
  &__services {
	color: #1b1b1b;
	display: inline-block;
	border-bottom: 1px dashed currentColor;
	cursor: pointer;
	transition: border-color $default-transition-duration $default-transition-function;
	&:hover {
	  border-color: transparent;
	}
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 5px;
	  line-height: 1.3;
	}
  }
  &__line {
	display: flex;
	justify-content: space-between;
	&:first-child {
	  margin-bottom: 5.5px;
	  @include min-screen($breakpoint-sm) {
		margin-bottom: 7.5px;
	  }
	}
	&._justify-end {
	  justify-content: flex-end;
	}
  }
  &__text {
	&._thick {
	  font-weight: 500;
	}
	&._book {
	  font-family: 'Uni Neue Book', sans-serif;
	}
	svg {
	  display: inline-block;
	  margin-left: 11px;
	  width: 13px;
	  height: 13px;
	  color: $c-burgundy;
	  cursor: pointer;

	  @include min-screen($breakpoint-sm) {
		width: 10px;
		height: 10px;
	  }
	}
  }
  &__button {
	width: 213px;
	margin: 15px auto;
  }
  &__offer {

  }
}
