.form-search {
	$parent-selector: &;
	&__text {
		margin-bottom: 50px;
		font-family: 'Uni Neue Book', sans-serif;
		line-height: 1.3;
	}
	&__line {
		position: relative;
	}
	&__input {
		input {
			padding-right: 20px;
			box-sizing: border-box;
		}
	}
	&__button-submit {
		@extend %reset-Button;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 15px;
		color: $c-brown;
		@extend .transition-active;
		&:hover {
			color: $c-burgundy;
		}

		._dark-form & {
			color: #7f6847;
			&:hover {
				color: $c-burgundy;
			}
		}

		svg {
			width: 24px;
			height: 24px;
		}
	}
	&__link {
		margin-top: 28px;
		@include smoothValue('margin-top', 14px, 30px, $breakpoint-sm, $breakpoint-lg);
	}
	._page-search & {
		#{$parent-selector} {
			&__button-submit {
				right: 15px;
				bottom: 0;
				color: $c-burgundy;
				&:hover {
					color: $c-brown;
				}
			}
		}
		.form-field {
			padding-right: 54px;
		}
	}
}

.form-search-autocomplete {
	position: absolute;
	z-index: 10;
	display: none;
	width: 100%;
	top: calc(100% - 1px);
	background-color: $c-white;
	padding: 20px;
	border: 1px solid $c-beige-form;
	box-sizing: border-box;
	max-height: 295px;
	overflow-y: auto;
	@extend .transition-move;

	&__main-title {
		margin-top: 24px;
		color: #373737;
		font-weight: bold;
		font-size: 16px;
		&:first-child {
			margin-top: 0;
		}
	}
	&__line {
		display: inline-block;
		margin-top: 10px;
		width: 100%;
	}
	&__link {
		display: inline-block;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	&__subtitle {
		display: block;
		font-size: 14px;
		font-weight: bold;
		color: #373737;
	}
	&__desc {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
	}
}
