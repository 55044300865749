@mixin common-form-file {
	position: relative;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	user-select: none;
	&__input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
	}
}

.form-file {
	@include common-form-file;
	padding: 13px 19px 13px 11px;
	border: 1px solid #f0e9db;
	font-family: 'Uni Neue', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	text-decoration: underline;
	color: $c-burgundy;
	&__icon {
		margin-right: 13px;
		flex-shrink: 0;
	}
	@include desktop {
		&:hover {
			text-decoration: none;
		}
	}
}
