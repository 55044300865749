.tab-head {
	$block: &;
	margin-top: 20px;
	border-top: 1px solid $c-light-beige;
	border-bottom: 1px solid $c-light-beige;
	--padding: 16px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('--padding', 16px, 22px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 80px, $breakpoint-sm, $breakpoint-lg);

	&._compact {
		background-color: transparent;
		border-top: 1px solid $c-light-beige;
		border-bottom: 1px solid $c-light-beige;
		#{$block} {
		}
	}

	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__outer {
		overflow: hidden;
		margin-left: calc(-1 * var(--container-indent));
		margin-right: calc(-1 * var(--container-indent));
	}
	&__list {
		display: flex;
		overflow-x: auto;
		padding-bottom: 16px;
		margin-bottom: -16px;
		padding-left: var(--container-indent);
		padding-right: var(--container-indent);
	}
	&__item {
		position: relative;
		flex-shrink: 0;
		padding: var(--padding) 5px;
		text-transform: uppercase;
		font-size: 12px;
		@extend .transition-active;
		@include smoothValue('font-size', 12px, 15px, $breakpoint-xxs, $breakpoint-sm);
		@include min-screen($breakpoint-sm) {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
		@include smoothValue('padding-left', 7.5px, 15px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('padding-right', 7.5px, 15px, $breakpoint-sm, $breakpoint-lg);

		& + & {
			margin-left: 5px;
		}
		&:last-child {
			//padding-left: var(--container-indent);
			//padding-right: var(--container-indent);
		}
		&::after {
			position: absolute;
			content: '';
			left: 0;
			bottom: 0;
			width: 100%;
			height: 4px;
			background-color: #c4b197;
			opacity: 0;
		}
		&:hover {
			color: $c-burgundy;
		}
		&._active {
			pointer-events: none;
			color: $c-burgundy;
			&::after {
				opacity: 1;
			}
		}
	}
}
