.tiles {
	$block: &;
	--tile-gap: 15px;
	margin-top: 20px;
	@include min-screen($breakpoint-sm) {
		margin-top: 30px;
		--tile-gap: 30px;
	}
	@include min-screen($breakpoint-md) {
		--tile-gap: 35px;
	}
	&:not(._col-4) {
		.tiles-item {
			&__wrap {
				@include screen($breakpoint-sm, $breakpoint-md - 1px) {
					display: flex;
					align-items: center;
				}
			}
			&__title {
				@include screen($breakpoint-sm, $breakpoint-md - 1px) {
					margin-left: 20px;
					margin-top: 0;
				}
				@include max-screen($breakpoint-md - 1px) {
					br {
						display: none;
					}
				}
				@include min-screen($breakpoint-md) {
					min-height: 2.8em;
				}
			}
		}
	}
	&._col-4 {
		#{$block} {
			&__wrap {
				padding-top: 20px;
				@include min-screen($breakpoint-sm) {
					padding-top: 30px;
				}
			}
		}
		@include min-screen($breakpoint-md) {
			--tile-gap: 30px;
		}
		.tiles-item {
			@include min-screen($breakpoint-xs) {
				width: 50%;
			}
			@include min-screen($breakpoint-md) {
				width: 25%;
			}
			&__wrap {
				display: flex;
				flex-direction: column;
				min-height: 180px;
				@include smoothValue('min-height', 180px, 220px, $breakpoint-xxs, $breakpoint-sm);
				@include smoothValue('min-height', 220px, 250px, $breakpoint-sm, $breakpoint-lg);
			}
		}
	}
	&._program-common {
		margin-top: 20px;
		@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
		#{$block} {
			&__wrap {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__title {
	  	margin-bottom: 20px;
		@include min-screen($breakpoint-md) {
		  	margin-bottom: 30px;
		}
	}
	&__list {
		margin: calc(-1 * var(--tile-gap) / 2);
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		padding: calc(var(--tile-gap) / 2);
	}
}

.tiles-item {
	$block: &;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	@include min-screen($breakpoint-md) {
		width: 1/3 * 100%;
	}
	&._program:not(._col-4) {
		a {
			display: block;
		}
		@include min-screen($breakpoint-sm) {
			width: 1/3 * 100%;
		}
		#{$block} {
			&__title {
				min-height: initial;
				margin-top: 65px;
				color: $c-burgundy;
				margin-left: 0;
			}
			&__small-text {
				@extend .tiny-text;
				margin-top: 10px;
			}
		}
	}
	&._program:not(._col-4)._program-common {
		#{$block} {
			&__title {
				margin-top: 10px;
				@include min-screen($breakpoint-sm) {
					margin-top: 30px;
				}
				@include smoothValue('margin-top', 30px, 65px, $breakpoint-sm, $breakpoint-lg);
			}
			&__small-text {
				font-size: 10px;
			}
		}
	}
	&._catalog {
		width: auto;
		#{$block} {
			&__wrap {
				display: block;
			}
		}
	}
	&__wrap {
		position: relative;
		padding: 15px 15px 20px;
		height: 100%;
		box-sizing: border-box;
		&::before {
			position: absolute;
			content: '';
			z-index: 0;
			top: 0;
			left: 0;
			mix-blend-mode: multiply;
			width: 100%;
			height: 100%;
			transform: scaleX(-1);
			background: url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		}
		@include min-screen($breakpoint-sm) {
			padding: 20px 20px 30px;
		}

		&:hover {
			#{$block} {
				&__arrow {
					svg {
						transform: translateX(10px);
					}
				}
			}
		}
	}
	&__circle {
		position: relative;
		z-index: 2;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $c-white;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 38px;
		line-height: 1;
		vertical-align: bottom;
		color: $c-beige;
		@include smoothValue('font-size', 38px, 48px, $breakpoint-xxs, $breakpoint-sm);

		._mac & div {
			transform: translateY(4px);
		}
		._win & div {
			transform: translateY(-4px);
		}

		svg {
			width: 36px;
			height: 36px;
		}
	}
	&__title {
		position: relative;
		margin-top: 10px;
		color: #373737;
		@extend .title-h5;
		font-weight: 300;
		&._small {
			font-size: 18px;
			@include min-screen($breakpoint-sm) {
				font-size: 20px;
				margin-top: 20px;
			}
			@include min-screen($breakpoint-md) {
				margin-top: 24px;
			}
		}
	}
	&__link {
		position: relative;
		display: flex;
		justify-content: flex-end;
		margin-top: 9px;
		.link-with-arrow {
			font-size: 14px;
			@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
		}
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			margin-left: auto;
			margin-top: 0;
		}
	}
	&__arrow {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
		align-items: flex-end;
		svg {
			width: 23px;
			height: 17px;
			color: $c-burgundy;
			transform: translateX(0);
			@extend .transition-move;
		}
	}
	&__small-text {
		color: #606060;
		font-size: 8px;
		text-transform: uppercase;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		@include min-screen($breakpoint-md) {
			font-size: 10px;
		}
	}
}
