.reviews-list {
	$block: &;
	margin-top: 20px;
	@include min-screen($breakpoint-md) {
		margin-top: 0;
	}
	&__item {
		padding-bottom: 20px;
		border-bottom: 1px solid $c-light-beige;
		& + & {
			margin-top: 20px;
		}
		&:first-child {
			border-top: 1px solid $c-light-beige;
			padding-top: 20px;
		}
		&-wrap {
			@include min-screen($breakpoint-sm) {
				position: relative;
				padding-left: 60px;
			}
		}
		&._single {
			border-bottom: none;
			padding-bottom: 0;
			padding-top: 20px;
			margin-top: 20px;
			border-top: 1px solid $c-light-beige;
			@include min-screen($breakpoint-sm) {
				padding-top: 25px;
				margin-top: 25px;
			}
			@include min-screen($breakpoint-md) {
				margin-top: 40px;
				padding-top: 0;
				border-top: none;
			}
			.article-author__photo {
				max-width: 80px;
				max-height: 80px;
			}
			#{$block} {
				&__text {
					font-size: 17px;
					@include smoothValue('font-size', 17px, 23px, $breakpoint-xs, $breakpoint-sm);
				}
			}
		}
	}
	&__icon {
		width: 30px;
		@include min-screen($breakpoint-sm) {
			position: absolute;
			left: 0;
			top: 8px;
		}
	}
	&__title {
		margin-top: 20px;
		font-size: 17px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		@include smoothValue('font-size', 17px, 23px, $breakpoint-xs, $breakpoint-sm);
	}
	&__text {
		margin-top: 15px;
		font-size: 14px;
		font-family: 'Uni Neue Book', sans-serif;
		font-style: italic;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-xs, $breakpoint-sm);
		&._museo {
			font-family: 'Museo Sans Cyrl', sans-serif;
			font-weight: 300;
			font-style: normal;
		}
	}
	&__author {
		margin-top: 20px;
		font-size: 14px;
		font-family: 'Uni Neue Book', sans-serif;
		font-style: italic;
		color: $c-dark-gray;
	}
}
