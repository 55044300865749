.alert {
  padding: 16px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &._danger {
	background-color: #EAE5E2;
  }
  &._danger &__icon {
	background-color: $c-burgundy;
	color: #fff;
  }
  &._danger &__text {
	color: $c-burgundy;
  }

  &__icon {
	margin-right: 13px;
	@include min-screen($breakpoint-md) {
	  margin-right: 8px;
	}
	font: 400 20px/24px 'Museo Sans Cyrl', sans-serif;
	color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	text-align: center;
  }
  &__text {
	@extend .title-h5;
  }
}
