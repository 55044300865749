.invisible-container {
	position: absolute;
	top: -9999px;
	left: -9999px;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1;
}

.no-select {
	user-select: none;
}

.no-pe {
	pointer-events: none;
}

._dragging,
._dragging * {
	cursor: ew-resize;
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
	cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAACVBMVEUAAAD///8AAABzxoNxAAAAAXRSTlMAQObYZgAAADFJREFUCNdjQAeLuhgYmCJTFzAwhYbOYGCYGhrBwAQiOEMhRAJIIgEk0cDAoAoj4AAA6jIM345/MPUAAAAASUVORK5CYII='),
		auto;
}

._draggable,
._draggable * {
	cursor: ew-resize;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAAD///8AAADAwMAJ6vS1AAAAAXRSTlMAQObYZgAAAEVJREFUCNctxLENgDAMRcEndxkkFfMYQUeTAqZwCQN8j8CcxBLFHVhMzwXtHFHd2D7eKlnkScpXJN/oPmt/B1YhD+gV8AEVfxL3aRgilwAAAABJRU5ErkJggg=='),
		auto;
}

.static-text {
	p {
		margin-bottom: 1.4em;
	}
	img {
		max-width: 100%;
	}
}

@keyframes rotating {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Linear png spinner */
%spinner {
	width: 20px;
	height: 20px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAALVBMVEUAAABra2toaGhsbGxra2tsbGxra2tsbGxsbGxsbGxra2tsbGxra2tra2tsbGzKmJSYAAAAD3RSTlMAQw0aW4c2vq2YechqK+kA+co8AAAAd0lEQVQI12NgYGDyKGeAAr8pggwMCiAWZxOQgLA9GGCAsQhCMzEwaCdAxQwYXGHSRgwTYUxlhk0wJhvDBgSzAG4UQxOCGQZjmjHIwJjJDJoGUKYAA2MAVOkFBgYviLAgiBsMYrFcAJGpzgwMhmchzpMKXXUQ6HIArngPcG/qBmMAAAAASUVORK5CYII=');
	opacity: 1;
	animation: rotating 0.65s linear infinite;
}

.animated {
	@extend .transition-active;
	&:hover {
		@extend .transition-hover;
	}
}

.transition-active {
	transition: color $default-transition-duration $default-transition-function,
		background $default-transition-duration $default-transition-function,
		border-color $default-transition-duration $default-transition-function;
}

.transition-move {
	transition: transform $default-transition-duration $default-transition-function, opacity ease 0.2s;
}

.transition-hover {
	transition: none;
}

.force3D {
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
}

%overflow-y {
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.g-bold {
	font-weight: bold;
}

.accent {
	color: $c-beige;
}

.styled-ul {
	li {
		position: relative;
		padding-left: 40px;
		&::before {
			position: absolute;
			content: '';
			left: 5px;
			top: 0.7em;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $c-burgundy;
		}
	}
	li + li {
		margin-top: 10px;
	}
	&._with-padding {
		@include min-screen($breakpoint-sm) {
			padding-left: 22px;
		}
	}
}

.styled-ol {
  &>li {
	position: relative;
	padding-left: 40px;
	counter-increment: count;
	&::before {
	  position: absolute;
	  content: counter(count) '. ';
	  left: 5px;
	  top: 0;
	  color: $c-burgundy;
	  font-weight: bold;
	}
  }
  li + li {
	margin-top: 10px;
  }
}

.simple-text {
	font-family: 'Uni Neue Book', sans-serif;
	font-size: 14px;
	line-height: 1.6;
	p + p {
		margin-top: 1.6em;
		@include min-screen($breakpoint-sm) {
			margin-top: 1.3em;
		}
	}
	div {
		font-size: 16px !important;
	}
	@include min-screen($breakpoint-sm) {
		font-size: 16px;
		line-height: 1.5;
	}

	.styled-ul li {
		padding-left: 20px;
	}
}

.simple-link {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
		color: $c-beige;
	}

	&._burgundy {
		color: $c-burgundy;
		text-decoration: none;
		&:hover {
			color: $c-beige;
			text-decoration: underline;
		}
	}
	&._beige {
		color: $c-beige;
		text-decoration: none;
		&:hover {
			color: $c-burgundy;
			text-decoration: underline;
		}
	}
	&._dark {
		font-family: 'Uni Neue', sans-serif;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&._book {
		font-family: 'Uni Neue Book', sans-serif;
	}
	&._tdu {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.g-counter {
	position: relative;
	display: inline-block;
	margin-left: 10px;
	color: $c-white;
	font-size: 10px;
	line-height: 1;
	vertical-align: middle;
	margin-top: -4px;
	font-family: 'Uni Neue', sans-serif;
	@include min-screen($breakpoint-sm) {
		font-size: 14px;
	}
	&::before {
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		content: '';
		width: 100%;
		height: 16px;
		background-color: #c4b197;
		border: 1px solid #bda47f;
		transform: translate(-50%, -50%);
		padding-left: 7px;
		padding-right: 7px;
		border-radius: 32px;

		@include min-screen($breakpoint-sm) {
			height: 20px;
		}
	}
	&._big {
		margin-top: 0;
		font-size: 14px;
	  	margin-left: 4px;
	  	padding: 3px 7px;
	  	box-sizing: border-box;
		background-color: #c4b197;
		border: 1px solid #bda47f;
	  	border-radius: 20px;

		&::before {
			width: 0;
			height: 0;
		  	border: none;
		  	opacity: 0;
		}
		@include min-screen($breakpoint-md) {
			padding: 5px 8px;
		}
	}
}

.g-delimeter {
	width: 100%;
	height: 1px;
	background-color: $c-light-beige;
}

.g-visible-md {
	@include max-screen($breakpoint-md - 1px) {
		display: none;
	}
}
.g-hidden-md {
	@include min-screen($breakpoint-md) {
		display: none;
	}
}
.g-visible-xs {
  @include max-screen($breakpoint-xs - 1px) {
	//display: none;
  }
}
.g-hidden-xs {
	@include min-screen($breakpoint-xs) {
		display: none;
	}
}

.mobile-container-margin {
	@include max-screen($breakpoint-xs - 1px) {
		margin-left: -5px;
		margin-right: -5px;
		width: auto;
	}
}

.visually-hidden {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
}


// fit youtube videos on mobile
iframe {
	@include mobile {
		width: 100%;
		height: 40vh;
	}
}