.competencies {
  	&_full-list {
		margin-bottom: 35px;
	  	@include smoothValue('margin-bottom', 35px, 55px, $breakpoint-xxs, $breakpoint-sm);
	  	@include smoothValue('margin-bottom', 55px, 85px, $breakpoint-sm, $breakpoint-lg);
	}
	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
		padding-top: 20px;
		@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		border-bottom: 1px solid #f1edea;
	}
	&__title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 15px;
		@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__caption {
		@extend .text-17-300;
		margin-bottom: 30px;
	}
	&__inline-link {
		@extend .simple-link;
		color: $c-burgundy;
	}

	&__tiles {
		$block: &;
		--comp-tile-offset: 15px;
		display: flex;
		flex-wrap: wrap;
		margin-left: calc(-1 * var(--comp-tile-offset) / 2);
		margin-right: calc(-1 * var(--comp-tile-offset) / 2);
		@include min-screen($breakpoint-sm) {
			--comp-tile-offset: 20px;
		}
		@include min-screen($breakpoint-md) {
			--comp-tile-offset: 30px;
		}
	  	&_full-list {
		  margin-left: calc(-1 * var(--comp-tile-offset));
		  margin-right: calc(-1 * var(--comp-tile-offset));
		}
	}
	&__tile {
		position: relative;
		width: 100%;
		padding: calc(var(--comp-tile-offset) / 2);
		box-sizing: border-box;

		@include min-screen($breakpoint-xs) {
			width: 8/16 * 100%;
		}
		@include min-screen($breakpoint-md) {
			width: 1/3 * 100%;
		}
	}
	&__item {
	    padding: calc(var(--comp-tile-offset) / 2);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 200px;
		@include smoothValue('height', 200px, 220px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 220px, 240px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__bg {
		position: absolute;
		z-index: -1;
		left: calc(var(--comp-tile-offset) / 2);
		top: calc(var(--comp-tile-offset) / 2);
		width: calc(100% - var(--comp-tile-offset));
		height: calc(100% - var(--comp-tile-offset));
	  	overflow: hidden;
	}
  	&__img {
	  	position: absolute;
	  	z-index: 1;
	  	bottom: -50px;
	  	left: -15px;
	  	opacity: 0.15;
	  	mix-blend-mode: multiply;
	  	transition: all $default-transition-function $default-transition-duration;
	}
	&__icon {
		position: relative;
	  	margin-top: 25px;
	  	margin-left: 20px;
		width: 70px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $c-white;
	  	border: 1px solid #ece7e2;
		border-radius: 50%;

		svg {
			width: 36px;
			height: 36px;
		}
	}
	&__text {
		@extend .title-h6;
		color: $c-burgundy;
		cursor: pointer;
	  	position: relative;
	  	z-index: 2;
	  	margin: 0 30px 30px 30px;

		&:hover {
			text-decoration: underline;
		}
	  	&:hover + .competencies__bg {
		  background-color: #fff!important;
		}
	  	&:hover + .competencies__bg img {
		  opacity: .5;
		}

		&_subtitled svg {
		  display: inline;
		  height: 15px;
		  width: 15px;
		  margin-right: 10px;
		}
	}
	&__sub {
	  font: 300 14px/1.25 'Museo Sans Cyrl', sans-serif;
	  color: $c-dark-gray;
	  display: block;
	  margin-bottom: 6px;
	}
	&__link {
		text-align: center;
		margin: 30px auto;
	}
}
