.our-team {
  position: relative;
  overflow: hidden;
  background-image: url("../images/backgrounds/dna-bg.png"), linear-gradient(#ebe4d9, #ebe4d9);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @supports (background-blend-mode: multiply) {
	background-blend-mode: multiply;
	@include min-screen($breakpoint-xs) {
	  background-size: contain;
	  background-position: right;
	}
}
  padding: 20px 15px;
  @include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
  @include smoothValue('padding-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
  @include smoothValue('padding-bottom', 20px, 29px, $breakpoint-xxs, $breakpoint-sm);
  @include smoothValue('padding-bottom', 29px, 50px, $breakpoint-sm, $breakpoint-lg);
  @include smoothValue('padding-left', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
  @include smoothValue('padding-left', 20px, 65px, $breakpoint-sm, $breakpoint-lg);
  margin-top: 30px;
  @include smoothValue('margin-top', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);
  border-bottom: 1px solid $c-light-beige;

  &__title {
	@extend .title-h4;
	color: $c-burgundy;
	margin-bottom: 15px;
	@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
	z-index: 20;
  }
  &__text {
	@extend .text-17-300;
	margin-bottom: 39px;
	@include smoothValue('margin-bottom', 39px, 78px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-md) {
	  max-width: 55%;
	}
	z-index: 20;
  }
  &__inline-link {
	@extend .simple-link;
	color: $c-burgundy;
  }
  &__button {
	width: 100%;
	@include min-screen(321px) {
	  width: 219px;
	}
  }
}
