.preloader {
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	opacity: 0;
	background-color: $c-white;
	transition: opacity 0.75s $default-transition-function;

	svg {
		width: 120px;
		height: auto;
		opacity: 0;
		transition: opacity 0.7s $default-transition-function;
		@include smoothValue('width', 120px, 240px, $breakpoint-sm, $breakpoint-lg);
	}

	._loading-started & {
		opacity: 1;

		svg {
			opacity: 1;
		}
	}

	._loaded & {
		pointer-events: none;
	}

	._loaded & svg {
		opacity: 0;
		visibility: hidden;
	}
}
