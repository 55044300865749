.video-block {
	$parent-selector: &;
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@extend .mobile-container-margin;
	@include min-screen($breakpoint-md) {
		margin-top: 20px;
	}
	@include min-screen($breakpoint-lg) {
		margin-top: 40px;
		margin-bottom: 50px;
	}
	&__wrap {
		padding-bottom: 20px;
		border-bottom: 1px solid $c-light-beige;
		@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('padding-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-md) {
			padding-top: 20px;
			border-top: 1px solid $c-light-beige;
		}
		@include min-screen($breakpoint-lg) {
			padding-top: 40px;
		}
	}
	&__poster {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 170px;
		background: url('../images/backgrounds/video-poster@2x.jpg') center 0 no-repeat;
		background-size: cover;
		max-width: 595px;
		margin-left: auto;
		margin-right: auto;
		@include smoothValue('height', 170px, 335px, $breakpoint-xxs, $breakpoint-sm);
		&::before {
			position: absolute;
			//content: '';
			width: 100%;
			height: 100%;
			background: #1b1b1b;
			opacity: 0.8;
		  	pointer-events: none;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&._small {
			height: 96px;
			width: 170px;
			max-width: 100%;
			margin-left: 0;
			#{$parent-selector} {
				&__play {
					width: 28px;
					height: 28px;
					svg {
						width: 7px;
						height: 10px;
						transform: translateX(1px);
					}
				}
			}
		}
	}
	&__play {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 50px;
		height: 50px;
		background-color: $c-burgundy;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		@include smoothValue('width', 50px, 75px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 50px, 75px, $breakpoint-xxs, $breakpoint-sm);
		svg {
			width: 12px;
			height: 17px;
			transform: translateX(2px);
			@include smoothValue('width', 12px, 18px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('height', 17px, 25px, $breakpoint-xxs, $breakpoint-sm);
		}
	}
}
