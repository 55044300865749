._zero-ease {
	transition-duration: 0s !important;
}

._fade-up {
	transform: translate(0, 20px);
	opacity: 0;
	will-change: transform;
}

._fade-down {
	transform: translate(0, -20px);
	opacity: 0;
	will-change: transform;
}

._fade-left {
	transform: translateX(30px);
	opacity: 0;
	will-change: transform;
}

._fade {
	opacity: 0;
}

._animated {
	transition: transform 0.8s ease-in-out, opacity 0.8s ease-out;
	transform: translate(0, 0);
	opacity: 1;
}
