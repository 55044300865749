//для IE 11

._ie{
	.program-common-head__tile {
		margin-top: 80px
	}
}


.program-common-head {
	$block: &;
	margin-top: 20px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
	&._bottom {
		@include tiny-section-mt();
		.program-common-head__tile:first-child {
			margin-top: 0;
		}
		.program-common-head__tile:nth-child(2) {
			@include min-screen($breakpoint-sm) {
				margin-top: 0;
			}
		}
	}
	&__small-title {
		color: $c-burgundy;

		&._big {
			@include min-screen($breakpoint-sm) {
				font-size: 22px;
			}
			@include min-screen($breakpoint-md) {
				font-size: 36px;
				font-weight: 100;
			}
		}
	}
	&__tile {
		margin-top: 15px;
		@include smoothValue('margin-top', 15px, 30px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-md) {
			&._left {
				padding-right: 20px;
			}
			&._right {
				padding-left: 20px;
			}
		}
		#{$block}._bottom & {
			@include min-screen($breakpoint-sm) {
				&._left {
					padding-right: 20px;
				}
				&._right {
					padding-left: 20px;
				}
			}
		}
		&._small {
			height: 200px;
			box-sizing: border-box;

			@include min-screen($breakpoint-lg) {
				height: 210px;
			}
			.program-common-head-tile__wrap {
				min-height: initial;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
			}
			.link-with-arrow {
				font-size: 14px;
				@include min-screen($breakpoint-lg) {
					font-size: 16px;
				}
			}
		}
		&._wide {
			min-height: 460px;
			@include min-screen($breakpoint-sm) {
				min-height: 400px;
			}
			@include min-screen($breakpoint-lg) {
				min-height: 412px;
			}
			.program-common-head-tile__btn {
				position: static;
				margin-top: auto;
			}
			.program-common-head-tile__wrap {
				display: flex;
				flex-direction: column;
				height: 100%;
				box-sizing: border-box;
			}
		}
	}
	&__link {
	  	@include tiny-section-pb();
		padding-top: 15px;
		@include smoothValue('padding-top', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	  	@include smoothValue('padding-top', 20px, 25px, $breakpoint-sm, $breakpoint-lg);
		border-bottom: 1px solid $c-light-beige;
		text-align: center;
	}
}

.program-common-head-banner {
	position: relative;
	color: $c-white;
	&__wrap {
		position: relative;
		padding: 25px;
		box-sizing: border-box;
		overflow: hidden;
		@include smoothValue('padding-left', 25px, 75px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding-top', 25px, 44px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding-top', 44px, 80px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 54px, 70px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			height: 366px;
		}
		@include min-screen($breakpoint-lg-2) {
			display: flex;
			justify-content: space-between;
			height: 340px;
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__main {
		@include min-screen($breakpoint-lg-2) {
			padding-left: vw(155px, $breakpoint-lg);
		}
	}
	&__bottom {
		margin-top: 75px;
		@include smoothValue('margin-top', 75px, 30px, $breakpoint-xs, $breakpoint-sm);
		@include min-screen($breakpoint-sm) {
			max-width: 469px;
		}
		@include min-screen($breakpoint-lg-2) {
			max-width: 352px;
			padding-right: vw(105px, $breakpoint-lg);
		}
	}
	&__title {
		color: $c-white;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 48px;
		line-height: 1;
		font-weight: 500;
		//@include smoothValue('font-size', 31px, 54px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('font-size', 48px, 54px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('font-size', 54px, 70px, $breakpoint-sm, $breakpoint-lg);

		span {
			color: $c-burgundy;
			font-family: 'Playfair Display', serif;
			font-style: italic;
			font-feature-settings: 'pnum' on, 'lnum' on;
			font-size: 1.25em;
		}
	}
	&__subtitle {
		font-size: 20px;
		line-height: 1.4;
		margin-top: 12px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	}
	&__text {
		font-size: 14px;
		@include min-screen($breakpoint-sm) {
			font-size: 16px;
		}
		span {
			color: $c-burgundy;
		}
	}
	&__btn {
		margin-top: 24px;
		@include smoothValue('margin-top', 24px, 30px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-top', 30px, 24px, $breakpoint-sm, $breakpoint-lg);
		.button {
			width: 100%;
			@include min-screen($breakpoint-xs) {
				width: auto;
				min-width: 201px;
			}
		}
	}
}

.program-common-head-tile {
	&__wrap {
		position: relative;
		background-color: $c-light-beige;
		padding: 25px;
	  	min-height: 384px;
	  	box-sizing: border-box;
		display: flex;
		flex-direction: column;
	  	justify-content: space-between;
		@include smoothValue('padding', 25px, 35px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding', 35px, 45px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			min-height: 252px;
		  	display: block;
		}
		@include min-screen($breakpoint-md) {
			height: 100%;
			display: flex;
		}
		.form-search__line {
			margin-top: 45px;
			@include min-screen($breakpoint-sm) {
				margin-top: 30px;
			}
			@include min-screen($breakpoint-lg-2) {
				margin-top: 75px;
				max-width: 450px;
			}
			input {
				background-color: $c-white;
			}
		}
		&._cost {
			background: $c-light-beige url('../images/backgrounds/cost.jpg') right bottom 0 no-repeat;
			background-size: auto 80%;
			@include min-screen($breakpoint-md) {
				background-size: auto 100%;
			}
		}
		&._online-pay {
			background: #f3f2ed url('../images/backgrounds/online-pay.jpg') right bottom 0 no-repeat;
			background-size: auto 80%;
			@include min-screen($breakpoint-md) {
				background-size: auto 100%;
			}
		}
		&._check-symptom {
			background: $c-light-beige url('../images/backgrounds/check-symptom.jpg') right bottom 0
				no-repeat;
			background-size: auto 80%;
			@include min-screen($breakpoint-md) {
				background-size: auto 100%;
			}
		}
	}
	&__text {
		margin-top: 15px;
		font-family: 'Uni Neue Book', sans-serif;
		@include min-screen($breakpoint-sm) {
			max-width: 500px;
		}
		&._first {
			@include min-screen($breakpoint-sm) {
				max-width: 280px;
			}
			@include min-screen($breakpoint-md) {
				max-width: 450px;
			}
		}
	}
  	&__inline {
	  	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
		  	display: flex;
		  	justify-content: space-between;
		  	align-items: flex-end;
		  	& form {
			  width: 325px;
			  margin-right: 30px;
			}

		  	& .program-common-head-tile__btn {
			  	position: static;
			  	.button {
					width: 283px;
					height: 60px;
				}
			}
		}
	  	@include min-screen($breakpoint-md) {
		  	& form {
			  	width: 100%;
			}
		  	& .form-search__line {
			  max-width: none;
			}
		}
	}
	&__btn {
		margin-top: auto;
		@include min-screen($breakpoint-sm) {
			position: absolute;
			right: 35px;
			top: 0;
		  	margin-top: 40px;
		}
		@include min-screen($breakpoint-md) {
			position: static;
			margin-top: auto;
		}
		&._small {
			.button {
				height: 50px;
			}
		}
	  	&._full-width {
		  	margin-top: 23px;
		  	@include min-screen($breakpoint-sm) {
			  	margin-top: 0;
			}
			@include min-screen($breakpoint-md) {
			  	margin-top: 25px;
			}
		}
		.button {
			width: 100%;
			@include min-screen($breakpoint-xs) {
				width: auto;
				min-width: 173px;
			}
			@include min-screen($breakpoint-md) {
				height: 60px;
				min-width: 203px;
			}
		}
	}
}

.program-common-head-decors {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $c-block-beige-bg;
	@include waves;

	img {
		width: 100%;
	}
	&__sphere {
		position: absolute;
		top: -50%;
		right: -70%;
		mix-blend-mode: multiply;
		opacity: 0.5;
		width: 580px;
		height: 100%;
		z-index: 2;
		@include smoothValue('width', 580px, 680px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('width', 680px, 780px, $breakpoint-sm, $breakpoint-lg);

		@include min-screen($breakpoint-xs) {
			right: 10%;
			top: auto;
			bottom: 80%;
		}
		@include min-screen($breakpoint-sm) {
			right: auto;
			left: -10%;
		}
		@include min-screen($breakpoint-md) {
			left: -10%;
			bottom: 120%;
		}
	}
}

.program-common-head-slider {
  &__heading {
	@extend .title-h5;
	color: $c-burgundy;
	margin-top: 23px;
	margin-bottom: 20px;
	@include smoothValue('margin-top', 23px, 40px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 40px, 60px, $breakpoint-sm, $breakpoint-lg);
  }

  &__list:not(.flickity-enabled) {
	@include min-screen($breakpoint-sm) {
	  display: flex;
	}

	.program-common-head-slider__slide {
	  display: none;

	  &:first-child,
	  &:nth-child(2),
	  &:nth-child(3) {
		display: block;
	  }

	  &:first-child,
	  &:nth-child(2) {
		margin-bottom: 15px;
	  }
	}
  }

  &__slide {
	@include min-screen($breakpoint-sm) {
	  &:not(:last-child) {
		margin-right: 20px;
		@include smoothValue('margin-right', 20px, 40px, $breakpoint-sm, $breakpoint-lg);
	  }
	}

	.program-common-head-slider & .tiles-item {
	  width: 100%;
	  height: 210px;

	  @include min-screen($breakpoint-sm) {
		width: 223px;
		height: 240px;
		@include smoothValue('width', 223px, 305px, $breakpoint-sm, $breakpoint-lg);
	  }

	  .tiles-item__wrap {
		display: flex;
		flex-direction: column;
		padding: 25px 20px;
	  }

	  .tiles-item__circle {
		flex-shrink: 0;
	  }

	  .tiles-item__title {
		font-size: 18px;
		@include min-screen($breakpoint-lg) {
		  font-size: 20px;
		}
		margin-top: auto;
		margin-bottom: 10px;
		color: #373737;
	  }

	  .tiles-item__small-text {
		font-size: 9px;
		@include min-screen($breakpoint-lg) {
		  font-size: 10px;
		}
	  }
	}
  }

  .standard-slider__arrows {
	display: none;
  }

  &__list.flickity-enabled + .standard-slider__arrows {
	display: flex;
  }
}
