.article-doctor-header {
	margin-top: 15px;
	margin-bottom: 20px;
	@include min-screen($breakpoint-sm) {
		margin-top: 35px;
		margin-bottom: 30px;
	}
	@include min-screen($breakpoint-lg) {
		margin-bottom: 45px;
	}
	&__wrap {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid $c-light-beige;
		border-bottom: 1px solid $c-light-beige;
		@include min-screen($breakpoint-sm) {
			display: flex;
			flex-shrink: 0;
			padding-top: 0;
			border-top: none;
		}
	}
	&__info {
		display: flex;
		flex-grow: 1;
	}
	&__photo {
		height: 50px;
		width: 50px;
		flex-shrink: 0;
		@include smoothValue('width', 50px, 135px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('width', 135px, 150px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('height', 50px, 135px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 135px, 150px, $breakpoint-sm, $breakpoint-lg);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__people {
		box-sizing: border-box;
		padding-left: 12px;
		@include smoothValue('padding-left', 12px, 20px, $breakpoint-xxs, $breakpoint-sm);
		@include min-screen($breakpoint-sm) {
			padding-right: 50px;
		}
	}
	&__name {
		@extend .title-h5;
		color: $c-burgundy;
	}
	&__position {
		@extend .title-h6;
		margin-top: 5px;
		margin-bottom: 5px;
		@include min-screen($breakpoint-md) {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
	&__desc {
		@extend .small-text;
		font-family: 'Uni Neue Book', sans-serif;
		@include min-screen($breakpoint-md) {
			max-width: 90%;
		}
	}

	&__button {
		margin-top: 20px;
		flex-shrink: 0;
		@include min-screen($breakpoint-xs) {
			width: 220px;
		}
		@include smoothValue('width', 220px, 250px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			margin-top: 0;
		}
		button {
			width: 100%;
		}
	}
}
