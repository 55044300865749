._ie {
	.catalog-list {
		display: flex;
		flex-wrap: wrap;
	}

	.tiles-item {
		width: 30%;
		margin: 15px;
	}
}
.catalog {
	//@extend .section-mb;
	margin-top: 20px;
	overflow: visible;
	&__wrap {
		@include min-screen($breakpoint-sm) {
			border-top: 1px solid $c-light-beige;
			padding-top: 20px;
		}
	}
	&__subtitle {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 20px;
		margin-top: 30px;
		@include min-screen($breakpoint-sm) {
			margin-bottom: 30px;
			margin-top: 60px;
		}
	}
	&__list {
		margin-top: 20px;
		&._mt-0 {
			@include min-screen($breakpoint-md) {
				margin-top: 0;
			}
		}
		&._faq {
			margin-top: -1px;
		}
		&._review {
			margin-top: -1px;
			max-width: 785px;
			.reviews-list {
				margin-top: 0;
			}
		}
		&._history {
			@include min-screen($breakpoint-md) {
				margin-top: 0;
			}
		}
	}
	&__main {
		@include min-screen($breakpoint-md) {
			padding-left: calc(var(--col-gutter) + 10px);
		}
	}
}

.catalog-info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 20px;
	@include min-screen($breakpoint-md) {
		border-bottom: 1px solid $c-light-beige;
		padding-bottom: 20px;
		padding-top: 0;
	}
	&._search {
		@include max-screen($breakpoint-md - 1px) {
			margin-bottom: 20px;
		}
	}

	&__item {
		width: 100%;
		@include min-screen($breakpoint-sm) {
			width: 50%;
		}
		@include max-screen($breakpoint-sm - 1px) {
			& + & {
				margin-top: 20px;
			}
		}
		&._search {
			@include min-screen($breakpoint-sm) {
				max-width: vw(260px, $breakpoint-sm);
			}
			@include min-screen($breakpoint-md) {
				max-width: vw(405px, $breakpoint-lg);
			}
		}
		&._search-doctor {
			@include min-screen($breakpoint-sm) {
				max-width: vw(340px, $breakpoint-sm);
			}
			@include min-screen($breakpoint-md) {
				max-width: vw(405px, $breakpoint-lg);
			}
		}
	}
	&__result {
		font-size: 18px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		color: $c-burgundy;
		@include min-screen($breakpoint-md) {
			padding-left: 25px;
			font-size: 20px;
		}
	}
}

.catalog-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px;
	margin-top: 20px;
	@include min-screen($breakpoint-lg) {
		margin-top: 40px;
	}
	&._program-list {
		grid-gap: 15px;
		@include min-screen($breakpoint-sm) {
			grid-gap: 20px;
			grid-template-columns: 1fr 1fr 1fr;
		}
		@include min-screen($breakpoint-md) {
			grid-gap: 25px;
		}
	}
	&__card {
		.doctors-slider__image {
			width: 250px;

		  	img {
			  	height: 250px;
			}
		}
	}
}
