.button-sos {
	//position: relative;

	svg {
		width: 26px;
		height: 20px;
	}

	&__btn {
		text-transform: none;
		color:#333333;
		font-weight: 100;
		font-size: 14px!important;
		width: 148px!important;
		//width: 52px;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: unset;
		border: 1px solid #BDA47F;
		background-color: #fff;
		box-shadow: unset;
		border-radius: unset;
		opacity: 1;
		padding: 0;
		@extend .transition-move;

		._open & {
			background: $c-beige;
		}
		&:hover {
			opacity: 0.9;
		}
	}

	&__popup {
		@include popupContainer();
		@extend .transition-move;
		left: 50%;
		width: 280px;
		background-color: $c-burgundy;
		border-top: 3px solid $c-beige;
		transform: translate(-50%, 10px);

		@include min-screen(1700px) {
			width: 300px;
		}

		&-wrap {
			border-radius: 5px;
			overflow: hidden;
		}

		&::before {
			position: absolute;
			content: '';
			bottom: calc(100% + 3px);
			left: 0;
			right: 0;
			width: 0;
			height: 0;
			margin-left: auto;
			margin-right: auto;
			border-bottom: 6px solid $c-beige;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
		}

		._open & {
			transform: translate(-50%, 0);
			opacity: 1;
			visibility: visible;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 18px 20px 20px;
		background-color: $c-burgundy;
		color: $c-dark-beige;
		@extend .transition-active;

		&:hover {
			color: $c-white;
			text-decoration: underline;
		}

		& + & {
			position: relative;
			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: #c4b197;
				opacity: 0.2;
			}
		}

		svg {
			margin-right: 11px;
		}
	}
}

@media only (max-width: 1024px ) {
	.button-sos {
		//position: relative;

		svg {
			width: 26px;
			height: 20px;
		}

		&__btn {

			text-transform: none;
			color:#333333;
			font-weight: 100;
			font-size: 12px!important;
			width: 100%!important;
			padding: 0px 8px;
			//width: 52px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: unset;
			border: 1px solid #BDA47F;
			background-color: #fff;
			box-shadow: unset;
			border-radius: unset;
			opacity: 1;
		}
	}
}
