.compare-products {
  --img-offset: 14px;
  @include smoothValue(--img-offset, 14px, 21px, $breakpoint-xxs, $breakpoint-sm);
  &__title {
	color: $c-burgundy;
	font: normal 23px/115% 'Kudryashev Headline', sans-serif;
	@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
  }
  &__head {
	display: flex;
	justify-content: space-between;
	padding-bottom: 30px;
	border-bottom: 1px solid $c-light-beige;
  }
  &__item {
	width: calc((1 / 3) - (var(--img-offset) * 2 / 3));
	&:not(:last-child) {
	  margin-right: var(--img-offset);
	}
  }
  &__image {
	width: 100%;
	min-height: 86px;
	position: relative;
	margin-bottom: 5px;
	@include smoothValue('margin-bottom', 5px, 10px, $breakpoint-xxs, $breakpoint-sm);

	img {
	  width: 100%;
	  object-fit: cover;
	  position: center;
	}
	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(rgba($c-beige, .3), rgba($c-beige, .3)),
	  linear-gradient(rgba($c-dark-gray, .2), rgba($c-dark-gray, .2));
	  background-blend-mode: color;
	  mix-blend-mode: color;
	}
  }
  &__name {
	font: 300 14px/1.4 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 14px, 18px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 18px, 20px, $breakpoint-sm, $breakpoint-md);
	@include min-screen($breakpoint-sm) {
	  line-height: 25px;
	}
  }
  &__table {
	margin-bottom: 30px;
	@include smoothValue('margin-bottom', 28px, 41px, $breakpoint-sm, $breakpoint-lg);
  }
  &__line {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	padding: 10px 11px;
	@include smoothValue('padding-left', 10px, 15px, $breakpoint-xxs, $breakpoint-sm);
	border-bottom: 1px solid $c-light-beige;
	&._thick {
	  @extend .base-text;
	  font-weight: 700;
	  color: #1b1b1b;
	}
	&._gray {
	  background-color: $c-light-gray;
	}
  }
  &__cell {
	width: 33.33333%;
	font: 400 12px/16px 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('line-height', 16px, 21px, $breakpoint-xxs, $breakpoint-sm);
	&._merge {
		@include max-screen($breakpoint-xs - 1) {
		  &:first-child {
			width: 66.66667%;
		  }
		  &:nth-child(2) {
			display: none;
		  }
	  }
	}
	&._burgundy {
	  @extend .title-h6;
	  color: $c-burgundy;
	}
  }
  &__caption {
	@extend .title-h6;
	text-align: center;
	margin-bottom: 26px;
	@include smoothValue('margin-bottom', 26px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 20px, 34px, $breakpoint-sm, $breakpoint-lg);
	svg {
	  display: inline-block;
	  width: 17px;
	  height: 17px;
	  vertical-align: middle;
	  margin-right: 8px;
	}
  }
  &__features {
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(82px, 1fr));
	grid-column-gap: 4px;
	grid-row-gap: 20px;
	@include min-screen($breakpoint-xs) {
	  grid-template-columns: repeat(auto-fill, 125px);
	}
	@include min-screen($breakpoint-sm) {
	  grid-template-columns: repeat(auto-fill, 127px);
	  grid-column-gap: 0;
	}
	@include min-screen($breakpoint-md) {
	  grid-template-columns: repeat(auto-fill, 158px);
	  grid-column-gap: 15px;
	}
	@include min-screen($breakpoint-lg) {
	  grid-column-gap: 22px;
	}
	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
	  margin-left: -9px;
	  margin-right: -29px;
	}
  }
  &__feature {
	width: 82px;
	@include min-screen($breakpoint-sm) {
	  width: 127px;
	}
	@include min-screen($breakpoint-md) {
	  width: 158px;
	}
	flex-grow: 0;
	flex-shrink: 0;
  }
  &__icon {
	width: 60px;
	height: 60px;
	@include smoothValue('width', 60px, 80px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('height', 60px, 80px, $breakpoint-sm, $breakpoint-lg);
	border-radius: 50%;
	border: 1px solid #ECE7E2;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 10px auto;

	svg {
	  width: 23px;
	  height: 25px;
	}
  }
  &__feature-text {
	font: 500 12px/16px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-sm, $breakpoint-lg);
	text-align: center;
	margin: 0 auto;
	word-break: break-word;
  }
}
