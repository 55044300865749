.advantages-list {

  &__wrap {
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding;
	}
  }
  &__heading {
	@extend .title-h4;
	@include smoothValue('font-size', 20px, 22px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 22px, 36px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 30px;
	@include smoothValue('margin-bottom', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 40px, 70px, $breakpoint-sm, $breakpoint-lg);
  }
  &__list {
	padding-bottom: 18px;
	@include smoothValue('padding-bottom', 18px, 25px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 25px, 30px, $breakpoint-sm, $breakpoint-lg);
	border-bottom: 1px solid $c-light-beige;
	column-count: 2;
	column-width: 290px;
	column-gap: 50px;
	@include smoothValue('column-gap', 50px, 85px, $breakpoint-sm, $breakpoint-lg);
  }
  &__point {
	display: flex;
	align-items: center;
	font: 400 14px/1.25 'Uni Neue', sans-serif;
	color: #1b1b1b;
	@include smoothValue('font-size', 14px, 20px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 30px;
	@include smoothValue('margin-bottom', 30px, 35px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 35px, 50px, $breakpoint-sm, $breakpoint-lg);
  }
  &__icon {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 60px;
	height: 60px;
	@include min-screen($breakpoint-md) {
	  width: 94px;
	  height: 94px;
	}
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #f1f1f1;
	margin-right: 20px;
	&::before {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: url('../images/backgrounds/waves.png') top left;
	  background-size: cover;
	  mix-blend-mode: multiply;
	  opacity: .2;
	}

	& svg {
	  width: 40px;
	  height: 40px;
	  @include min-screen($breakpoint-md) {
		width: 65px;
		height: 65px;
	  }
	}
  }
}
