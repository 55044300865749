.health-360-banner {
	position: relative;
	z-index: 2;
	margin-top: 20px;
	margin-bottom: -40px;
	overflow: hidden;
	@include smoothValue('margin-top', 20px, 110px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 146px, 160px, $breakpoint-lg, $breakpoint-fhd);
	@include min-screen($breakpoint-lg) {
		margin-bottom: -80px;
	}
	&__wrap {
		position: relative;
	}
	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 170px;
		padding-left: 35px;
		@include min-screen($breakpoint-sm) {
			padding-top: 45px;
			padding-bottom: 52px;
			box-sizing: border-box;
		}
		@include smoothValue('padding-left', 35px, 85px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('padding-top', 45px, 75px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('padding-bottom', 52px, 97px, $breakpoint-md, $breakpoint-lg);

		@include smoothValue('padding-left', 85px, 95px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('padding-top', 78px, 80px, $breakpoint-lg, $breakpoint-fhd);
		@include smoothValue('padding-bottom', 130px, 115px, $breakpoint-lg, $breakpoint-fhd);
		&::before {
			position: absolute;
			z-index: -1;
			left: 0;
			content: '';
			width: calc(100% + var(--container-indent));
			height: 100%;
			background-color: $c-block-beige-bg;
		}
	}
	&__title {
		color: $c-white;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 31px;
		line-height: 1.2;
		font-weight: 500;
		//@include smoothValue('font-size', 31px, 54px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('font-size', 31px, 56px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 56px, 70px, $breakpoint-lg, $breakpoint-fhd);

		span {
			color: $c-burgundy;
			font-family: 'Playfair Display', serif;
			font-style: italic;
			font-feature-settings: 'pnum' on, 'lnum' on;
			font-size: 1.25em;
		}
	}
	&__link {
		color: $c-white;
		margin-top: 6px;
		@include smoothValue('margin-top', 18px, 20px, $breakpoint-lg, $breakpoint-fhd);
	}
}
