.filter-alphabet {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__option {
	font: 500 18px/1.7 'Uni Neue', sans-serif;
	cursor: pointer;
	transition: all $default-transition-duration $default-transition-function;

	&._selected {
	  color: #fff;
	  background-color: $c-beige-bg;
	  box-sizing: border-box;
	  padding: 0 15px;
	  border-radius: 50px;
	}
  }
}
