%reset-Button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;

	outline: none;

	cursor: pointer;
}

%reset-input {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	outline: none;
	border-radius: 0;
}

@mixin popupContainer() {
	position: absolute;
	z-index: 10;
	left: 0;
	top: calc(100% + 12px);
	opacity: 0;
	visibility: hidden;
	transform: translateY(-5px);
	--top-offset: 15px;

	._open & {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}

	&::after {
		position: absolute;
		content: '';
		left: 0;
		bottom: 100%;
		width: 100%;
		height: var(--top-offset);
	}
}
