.news {
	margin-top: 40px;
	margin-bottom: 31px;
	@include smoothValue('margin-bottom', 31px, 80px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-top', 20px, 28px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('padding-top', 36px, 48px, $breakpoint-lg, $breakpoint-fhd);

	&._article {
		padding-top: 0;
		margin-bottom: 0;
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 40px;
		}
	}
	&._last {
		margin-bottom: 20px;
		@include min-screen($breakpoint-sm) {
			margin-bottom: 30px;
		}
		@include min-screen($breakpoint-md) {
			margin-bottom: 40px;
		}
	}
	&__wrap {
		position: relative;
		&._article {
			border-top: 1px solid $c-light-beige;
			padding-top: 20px;
			@include min-screen($breakpoint-sm) {
				padding-top: 30px;
				padding-left: 0;
				padding-right: 0;
			}
			@include min-screen($breakpoint-md) {
				.news__read-all {
					margin-top: 40px;
				}
			}
			@include min-screen($breakpoint-lg) {
				@include desktop-container-padding;
				padding-top: 40px;
			}
		}
		&._no-border {
			border: none;
			padding-top: 0;
		}
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding;

			&::before {
				left: 0;
			}
		}
		@include min-screen($breakpoint-md) {
			padding-right: 0;
		}
	}
	&__list {
		margin-top: 15px;
		@include smoothValue('margin-top', 54px, 64px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('margin-top', 108px, 98px, $breakpoint-lg, $breakpoint-fhd);

		&._article {
			@include min-screen($breakpoint-sm) {
				margin-top: 20px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 40px;
			}
		}
	}
	&__item {
		& + & {
			margin-top: 20px;
		}
		&._big {
			@include min-screen($breakpoint-xs) {
				margin-top: 0;
			}
		}
		&._info.col {
			margin-top: 0;

			@include min-screen($breakpoint-lg) {
				flex-basis: vw(360px, $breakpoint-lg);
				max-width: initial;
				margin-left: auto;
			}
		}
	}
	&__line {
		display: block;
		& + & {
			margin-top: 35px;
			@include min-screen($breakpoint-md) {
				margin-top: 24px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 40px;
			}
		}
	}

	&__title {
		@extend .title-h4;
		color: $c-burgundy;
	}

	&__read-all {
		margin-top: 20px;
		@include min-screen($breakpoint-xs) {
			text-align: center;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			z-index: 2;
			right: 0;
			top: 0;
		}
	}
}

.news-item {
	position: relative;
	$block: &;
	&._big {
		#{$block} {
			&__wrap {
				background-color: $c-light-gray;
				padding: 20px 15px 30px;

				@include min-screen($breakpoint-xs) {
					background-color: transparent;
					padding: 0;
				}
			}
			&__date {
				@include smoothValue('margin-top', 24px, 25px, $breakpoint-sm, $breakpoint-md);
				@include smoothValue('margin-top', 36px, 42px, $breakpoint-lg, $breakpoint-fhd);
			}
		}
	}

	&._listing {
		position: relative;
		&::before {
			position: absolute;
			top: 0;
			left: calc(var(--col-gutter) / 2);
			height: 1px;
			width: calc(100% - var(--col-gutter));
			opacity: 0.3;
			background-color: $c-beige;
		}
		@include min-screen($breakpoint-xs) {
			margin-top: 42px;
			padding-top: 30px;
			&::before {
				content: '';
			}
		}
		@include min-screen($breakpoint-md) {
			margin-top: 0;
			padding-top: 0;
			&::before {
				content: none;
			}
		}
	}
	&._first {
		margin-top: 0;
		padding-top: 0;
		&::before {
			content: none;
		}
	}

	&._article-horizontal {
		padding-top: 0;
		margin-top: 0;
		&::before {
			display: none;
		}
		@include min-screen($breakpoint-md) {
			display: flex;
			.news__line {
				margin-top: 0;
				width: 1/3 * 100%;
				padding-right: 32px;
			}
			.news-item__name {
				@include min-screen($breakpoint-md) {
					font-size: 20px;
				}
			}
		}
	}

	&._article {
		padding-top: 0;
		margin-top: 0;
		&::before {
			display: none;
		}
		@include min-screen($breakpoint-md) {
			.news-item__name {
				@include min-screen($breakpoint-md) {
					font-size: 20px;
				}
			}
		}
	}

	&__wrap {
		box-sizing: border-box;
		@include min-screen($breakpoint-xs) {
			height: 100%;
		}

		.video-block__poster {
			margin-bottom: 15px;
			margin-left: 0;
			@include min-screen($breakpoint-sm) {
				margin-bottom: 20px;
			}
		}
	}

	&__icon {
		margin-bottom: 26px;
		@include smoothValue('margin-bottom', 54px, 58px, $breakpoint-sm, $breakpoint-md);
	  	@include min-screen($breakpoint-md) {
		  	max-width: 245px;
		}
		@include min-screen($breakpoint-lg) {
		  	max-width: 320px;
		}
		@include min-screen($breakpoint-fhd) {
		  	max-width: 415px;
		}
		svg,
		img {
			display: block;
			width: 140px;
			height: 140px;
			@include smoothValue('width', 140px, 160px, $breakpoint-lg, $breakpoint-fhd);
			@include smoothValue('height', 140px, 160px, $breakpoint-lg, $breakpoint-fhd);
		  	object-fit: contain;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__type {
		text-transform: uppercase;
		font-size: 10px;
		margin-bottom: 5px;
		line-height: 1;
	}
	&__title {
		font-size: 22px;
		line-height: 1.2;
		font-weight: 300;
		font-family: 'Museo Sans Cyrl', sans-serif;
		@extend .transition-active;
		@include smoothValue('font-size', 22px, 26px, $breakpoint-md, $breakpoint-lg);

		._desktop &:hover {
			color: $c-beige;
			text-decoration: underline;
			.news-item__date {
				text-decoration: none;
			}
		}
	}
	&__date {
		margin-top: 10px;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		color: $c-dark-gray;
	}

	&__name {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		font-size: 18px;
		line-height: 1;
		@extend .transition-active;
		@include min-screen($breakpoint-md) {
			font-size: 20px;
		}
		@include smoothValue('font-size', 20px, 26px, $breakpoint-lg, $breakpoint-fhd);

		._desktop &:hover {
			color: $c-beige;
			text-decoration: underline;
		}
	}
}
