.doctor-questions-page-button {
	margin-top: 20px;
	width: 100%;
	max-width: 390px;
	@include min-screen($breakpoint-sm) {
		position: absolute;
		right: 0;
		top: -10px;
		margin-top: 0;
		max-width: 198px;
	}
	@include min-screen($breakpoint-md) {
		display: none;
	}
	&._faq {
		right: var(--container-indent);
		display: block;
		top: -12px;
		@include min-screen($breakpoint-lg) {
			top: -8px;
		}
	}
	&._static {
		position: static;
		@include min-screen($breakpoint-md) {
			margin-top: 0;
		}
	}
	&._bottom {
		top: 4px;
	}
}
