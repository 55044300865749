.history-list {

  &__wrap {
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding;
	}
  }
  &__lead-in {
	max-width: 785px;
	font: 300 17px/1.6 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 17px, 19px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-md);
	padding-bottom: 25px;
	@include smoothValue('padding-bottom', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
	border-bottom: 1px solid $c-light-beige;
	margin-bottom: 25px;
	@include smoothValue('margin-bottom', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
  }
  &__section {
	max-width: 1060px;
	&:not(:last-child) {
	  margin-bottom: 20px;
	  @include smoothValue('margin-bottom', 25px, 40px, $breakpoint-xs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 40px, 60px, $breakpoint-sm, $breakpoint-md);
	}
	@include min-screen($breakpoint-md) {
	  display: flex;
	}

	&._small-margin {
	  margin-bottom: 5px;
	  @include smoothValue('margin-bottom', 5px, 20px, $breakpoint-xs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 20px, 40px, $breakpoint-sm, $breakpoint-md);
	}
  }
  &__year {
	border-radius: 50%;
	box-sizing: border-box;
	border: 1px solid $c-dark-beige;
	width: 90px;
	height: 90px;
	@include smoothValue('width', 90px, 120px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('height', 90px, 120px, $breakpoint-xxs, $breakpoint-sm);
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0 auto 15px auto;
	@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-md) {
	  margin: 0 50px 0 0;
	  @include smoothValue('margin-right', 50px, 150px, $breakpoint-md, $breakpoint-lg);
	}

	& span {
	  margin-top: -5px;
	  font: 400 30px/1 'Kudryashev Headline', sans-serif;
	  @include smoothValue('font-size', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);
	  color: $c-burgundy;
	}
  }
  &__text {
	&._no-left {
	  @include min-screen($breakpoint-md) {
		margin-left: 170px;
		@include smoothValue('margin-left', 170px, 270px, $breakpoint-md, $breakpoint-lg);
	  }
	}
	& *:not(:last-child) {
	  margin-bottom: 15px;
	  @include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-md);
	}
  }
  &__general {
	font: 300 18px/22px 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 18px, 19px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('line-height', 22px, 28px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-sm) {
	  line-height: 150%;
	}
  }
  &__caption {
	font: 400 14px/1.6 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);
  }
  &__highlight {
	@extend .subtitle-18-300;
	@include smoothValue('line-height', 22px, 25px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-lg) {
	  line-height: 25px;
	}
	background-color: #f8f6f4;
	padding: 15px;
	@include smoothValue('padding', 15px, 35px, $breakpoint-xxs, $breakpoint-sm);

	&._with-logo {
	  display: flex;
	  flex-direction: column;
	  @include smoothValue('padding-right', 35px, 55px, $breakpoint-xxs, $breakpoint-sm);

	  span {
		display: block;
		margin-bottom: 10px;
	  }
	}
  }

  &__gallery {
	justify-self: flex-end;
	max-width: calc(785px + (var(--container-indent) * 2));
	//@include smoothValue('max-width', 885px, 1145px, $breakpoint-lg, $breakpoint-fhd);
	margin-top: -5px;
	@include smoothValue('margin-top', -5px, -20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', -20px, -30px, $breakpoint-sm, $breakpoint-md);
	margin-left: calc(-1 * var(--container-indent));
	margin-right: calc(-1 * var(--container-indent));
	margin-bottom: 15px;
	@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-lg);

	@include min-screen($breakpoint-md) {
	  margin-left: calc(170px - var(--container-indent));
	  @include smoothValue('padding-left', 0px, 100px, $breakpoint-md, $breakpoint-lg);
	}

	& .slider-gallery {
	  margin-top: 0;

	  &__wrap {
		max-width: initial;
	  }
	}

	& .slider-gallery-main__image img {
	  @include min-screen($breakpoint-lg) {
		height: 589px;
	  }
	}

	& .slider-gallery-main__arrows {
	  @include min-screen($breakpoint-md) {
		display: none;
	  }
	}
  }

  &__logo {
	align-self: center;
	width: 260px;
  }
}
