.slider-reviews {
	margin-top: 30px;
	padding-bottom: 45px;
	@include smoothValue('margin-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
	&__wrap {
		position: relative;
		padding-top: 30px;
		border-top: 1px solid $c-light-beige;
		@include smoothValue('padding-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__title {
		@extend .subtitle-20-100;
		color: $c-burgundy;
	}
	&__slider {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}
		@include min-screen($breakpoint-lg) {
			margin-top: 40px;
		}
	}
	&__more {
		color: $c-burgundy;
		&:hover {
			text-decoration: underline;
		}
	}
	&__see-all {
		border-top: 1px solid $c-light-beige;
		padding-top: 15px;
		margin-top: 10px;
		text-align: center;
		@include max-screen($breakpoint-xs - 1px) {
			.link-with-arrow {
				font-size: 14px;
			}
		}
		@include min-screen($breakpoint-sm) {
			width: 550px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
			padding-top: 30px;
		}
		@include min-screen($breakpoint-lg) {
			position: absolute;
			width: auto;
			top: 50px;
			right: 0;
			margin-top: 0;
			padding-top: 0;
			border: none;
		}
	}
}

.slider-reviews-slider {
	position: relative;
	.flickity-viewport {
		@include max-screen($breakpoint-sm - 1px) {
			overflow: visible;
		}
	}
	&__list {
		@include screen($breakpoint-sm, $breakpoint-lg - 1px) {
			width: 550px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__item {
		width: 100%;
		margin-right: var(--container-indent);
		@include min-screen($breakpoint-lg) {
			width: calc((100% - 90px) / 2);
			margin-right: 90px;
		}
	}
	&__title {
		font-size: 14px;
		font-weight: 500;
		@include min-screen($breakpoint-md) {
			font-size: 16px;
		}
		@include min-screen($breakpoint-lg) {
			font-size: 18px;
		}
	}
	&__text {
		margin-top: 10px;
		font-family: 'Uni Neue Book', sans-serif;
		@include min-screen($breakpoint-md) {
			font-size: 14px;
		}
		@include min-screen($breakpoint-lg) {
			font-size: 16px;
		}
	}
	&__people {
		@extend .small-text;
		font-family: 'Uni Neue Book', sans-serif;
		margin-top: 15px;
		font-style: italic;
		color: $c-dark-gray;
	}
	@include sliderArrows();
}
