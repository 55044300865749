.o-header-banner {
  margin-top: 10px;
  margin-bottom: 85px;
  @include smoothValue('margin-bottom', 50px, 100px, $breakpoint-sm, $breakpoint-lg);
  overflow: visible;
  @include min-screen($breakpoint-md) {
	padding: 0 var(--container-indent);
  }
  @include smoothValue('margin-top', 10px, 20px, $breakpoint-xxs, $breakpoint-sm);
  @include smoothValue('margin-top', 20px, 25px, $breakpoint-sm, $breakpoint-md);

  &__wrap {
	position: relative;
	padding-top: 25px;
	@include smoothValue('padding-top', 25px, 45px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 45px, 60px, $breakpoint-sm, $breakpoint-lg);
	padding-left: 15px;
	@include smoothValue('padding-left', 15px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 30px, 65px, $breakpoint-sm, $breakpoint-lg);
	padding-bottom: 100px;
	@include min-screen($breakpoint-sm) {
	  padding-bottom: 70px;
	}
	@include smoothValue('padding-bottom', 70px, 105px, $breakpoint-sm, $breakpoint-lg);
  }
  &__bg {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: rgba($c-beige, .1);
	mix-blend-mode: hard-light;
	&::after {
	  content: '';
	  position: absolute;
	  z-index: 5;
	  bottom: -10%;
	  @include smoothValue('bottom', -10%, 0, $breakpoint-xxs, $breakpoint-sm);
	  left: 17%;
	  @include smoothValue('left', 17%, 40%, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('left', 40%, 20%, $breakpoint-sm, $breakpoint-lg);
	  width: 100%;
	  height: 100%;
	  background: url('../images/sphere-main-fs/sphere-main-fs.png') no-repeat;
	  background-position: top left;
	  background-size: 180%;
	  @include smoothValue('background-size', 180%, 100%, $breakpoint-xxs, $breakpoint-sm);
	  opacity: .4;
	  mix-blend-mode: multiply;
	  @include min-screen($breakpoint-sm) {
		background-position-y: bottom;
		background-size: 100%;
		bottom: 0;
	  }
	  @include min-screen($breakpoint-md) {
		background-size: 65%;
	  }
	}
  }
  &__heading {
	font: 400 23px/1.15 'Kudryashev Headline', sans-serif;
	@include smoothValue('font-size', 23px, 32px, $breakpoint-xxs, $breakpoint-xs);
	@include smoothValue('font-size', 32px, 38px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 38px, 48px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('font-size', 48px, 54px, $breakpoint-lg, $breakpoint-fhd);
	@include min-screen($breakpoint-sm) {
	  line-height: 1.2;
	}
	color: $c-burgundy;
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 35px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 35px, 40px, $breakpoint-sm, $breakpoint-md);
  }
  &__lead-in {
	font: 400 14px/1.6 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 75px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 35px;
	}
	@include smoothValue('margin-bottom', 35px, 40px, $breakpoint-md, $breakpoint-lg);
  }
  &__icons {
	@include min-screen($breakpoint-sm) {
	  display: flex;
	}
  }
  &__icon {
	height: 40px;
	width: 120px;
	border: 1px solid rgba(27, 27, 27, .1);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 10;
	&:not(:last-child) {
	  margin-bottom: 15px;
	}
	@include min-screen($breakpoint-sm) {
	  &:not(:last-child) {
		margin-right: 15px;
		margin-bottom: 0;
	  }
	}
	@include min-screen($breakpoint-md) {
	  margin-right: 10px;
	}
	& svg {
	  height: 25.6px;
	  width: 101px;
	}
  }
  &__phone {
	position: absolute;
	width: 150px;
	@include smoothValue('width', 150px, 195px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('width', 195px, 272px, $breakpoint-sm, $breakpoint-lg);
	top: 43%;
	@include min-screen($breakpoint-sm) {
	  top: 45px;
	}
	@include smoothValue('top', 45px, 70px, $breakpoint-sm, $breakpoint-lg);
	right: 15px;
	@include smoothValue('right', 15px, 60px, $breakpoint-xxs, $breakpoint-xs);
	@include smoothValue('right', 60px, 48px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('right', 48px, 85px, $breakpoint-sm, $breakpoint-lg);

	& img {
	  width: 100%;
	}
  }
}
