.fullscreen-menu {
	position: absolute;
	z-index: -1;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: $c-gray;
	box-sizing: border-box;
	padding-top: 40px;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	@extend .transition-move;

	@include min-screen($breakpoint-xs) {
		padding-top: 50px;
	}
	@include screen($breakpoint-sm, $breakpoint-md - 1px) {
		.header:not(._fixed) & {
			padding-top: 155px;
		}
	}
	@include min-screen($breakpoint-md) {
		padding-top: 100px;
	}

	._open-menu & {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	&__col {
		&._main-col {
			@include screen($breakpoint-md, $breakpoint-fhd + 1px) {
				margin-left: var(--header-logo-width);
			}
		}
	}

	&__wrap {
		overflow-y: auto;
		max-height: 100%;
		padding-bottom: 27px;
		padding-top: 20px;
		box-sizing: border-box;
	}
	&__lk {
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		@include min-screen($breakpoint-sm) {
			padding-top: 0;
			border-top: none;
			margin-top: 0;
		}
		&-wrap {
			display: flex;
			align-items: center;
		}
		a {
			align-items: center;
		}
		svg {
			width: 24px;
			height: 24px;
			color: $c-beige;
		}
	}
	&__list {
		position: absolute;
		top: 0;
		visibility: hidden;
		opacity: 0;
		transform: translateY(10px);
		@extend .transition-move;
		&._active {
			position: static;
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
	}
	&__link {
		font-size: 14px;
		line-height: 1.6;
		@include smoothValue('font-size', 14px, 16px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 16px, 18px, $breakpoint-lg, $breakpoint-fhd);
		&:hover {
			text-decoration: underline;
		}
		&._big {
			@include smoothValue('font-size', 14px, 18px, $breakpoint-xs, $breakpoint-sm);
			@include smoothValue('font-size', 18px, 22px, $breakpoint-md, $breakpoint-lg);
		}
	}
	&__item {
		& + & {
			margin-top: 15px;
			@include smoothValue('margin-top', 15px, 25px, $breakpoint-md, $breakpoint-lg);
		}
		&._big + &._big {
			@include min-screen($breakpoint-sm) {
				margin-top: 25px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 35px;
			}
			@include smoothValue('margin-top', 35px, 45px, $breakpoint-lg, $breakpoint-fhd);
		}
	}
	&__service-info {
		margin-top: 40px;
	}
	&__search {
		margin-top: 30px;
		margin-bottom: 40px;
		@include min-screen($breakpoint-sm) {
			margin-top: 0;
			margin-bottom: 0;
		}
		@include smoothValue('max-width', 350px, 365px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('max-width', 365px, 490px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('max-width', 490px, 668px, $breakpoint-lg, $breakpoint-fhd);
	}
}

.service-info {
	position: relative;
	height: 200px;
	background-color: $c-white;
	padding: 15px 20px;
	overflow: hidden;
	&::before {
		position: absolute;
		content: '';
		left: 40%;
		bottom: 15%;
		width: 300px;
		height: 300px;
		background: $c-white right top no-repeat;
		background-size: 100% auto;
		._webp & {
			background-image: url('../images/sphere-main-fs/sphere-main-fs.webp');
		}
		._no-webp & {
			background-image: url('../images/sphere-main-fs/sphere-main-fs.png');
		}
	}
	&__wrap {
		position: relative;
		z-index: 2;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	&__title {
		@extend .main-title;
		font-size: 30px;
		@include min-screen($breakpoint-sm) {
			font-size: 23px;
		}
		@include smoothValue('font-size', 23px, 37px, $breakpoint-md, $breakpoint-lg);
	}
	&__subtitle {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.4;
	}
	&__desc {
		margin-top: auto;
		font-size: 14px;

		& > span {
			@include max-screen($breakpoint-lg - 1px) {
				display: none;
			}
		}

		.link-with-arrow {
			color: $c-beige;
			font-size: inherit;
		}
	}
}


#menu-banner-visit, #menu-banner-services {
	margin-top: 40px;
	width: 100%;
	img {
		width: 100%;
		height: auto;
	}
}