//для IE 11

._ie{
	.resume-review-tile__text {
		p {
			width: 22vw;
		}
	}
}

* {
	padding: 0;
	margin: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
	width: 100%;
	height: 100%;
	cursor: default;
	// -webkit-text-size-adjust: 100%;
	// -webkit-font-smoothing: antialiased;
}

@mixin mobile {
	@at-root html._mobile #{&} {
		@content;
	}
}

body {
	font: normal 14px/1.6 Uni Neue, sans-serif;
	color: $c-dark;
	background: #fff;
	@include mobile {
		-webkit-text-size-adjust: 100%;
	}
}

section,
footer {
	overflow: hidden;
}

img,
fieldset {
	border: 0;
}

fieldset legend {
	display: none;
}

h1 {
  font-weight: normal;
}

ul,
li {
	list-style-type: none;
}

img {
	vertical-align: top;
}

a {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}

table {
	border: 0;
	border-collapse: collapse;
	td {
		padding: 0;
		vertical-align: top;
	}
}

input,
select,
textarea {
	@include box;
}

input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

input[type='search'] {
	-webkit-appearance: textfield;
}

::selection {
	background: #5f5f5f;
	color: #ffffff;
	text-shadow: none;
}

::-moz-selection {
	background: #5f5f5f;
	color: #ffffff;
	text-shadow: none;
}

svg {
	display: block;
	fill: transparent;
}

button {
	user-select: none;
	border-radius: 0;
}
