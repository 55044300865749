@mixin tiny-section-mt() {
	margin-top: 20px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
}
@mixin tiny-section-pt() {
	padding-top: 20px;
	@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
}
@mixin tiny-section-mb() {
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
}
@mixin tiny-section-pb() {
	padding-bottom: 20px;
	@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
}

@mixin small-section-mt() {
	margin-top: 30px;
	@include smoothValue('margin-top', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 40px, 80px, $breakpoint-sm, $breakpoint-lg);
}

@mixin section-mb() {
	margin-bottom: 50px;
	@include smoothValue('margin-bottom', 50px, 70px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 70px, 100px, $breakpoint-sm, $breakpoint-lg);
}

@mixin large-section-mb() {
  margin-bottom: 90px;
  @include smoothValue('margin-bottom', 90px, 100px, $breakpoint-xxs, $breakpoint-sm);
}

.large-section-mb {
  @include large-section-mb;
}

.section-mb {
	@include section-mb;
}

.tiny-section-mt {
	@include tiny-section-mt;
}
.tiny-section-mb {
	@include tiny-section-mb;
}
