.directions-common {
	margin-top: 35px;
	@include smoothValue('margin-top', 35px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);

	&__title {
		@extend .title-h3;
		color: $c-burgundy;
		padding-bottom: 20px;
		border-bottom: 1px solid $c-light-beige;
	}
}
