.aside-popular {
	border: 1px solid $c-light-beige;
	margin-top: 30px;
	&._no-mt {
		margin-top: 0;
	}
	&__wrap {
		position: relative;
		@include waves;
		padding: 25px;
		&::before {
			background-color: $c-white;
		}
	}
	&__title {
		font-size: 20px;
		font-weight: 100;
		line-height: 1.15;
		font-family: 'Museo Sans Cyrl', sans-serif;
		color: $c-burgundy;
		margin-bottom: 25px;
	}
	&__item {
		& + & {
			margin-top: 20px;
			@include min-screen($breakpoint-md) {
				margin-top: 30px;
			}
		}
		&._with-border {
			margin-top: 0;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $c-light-beige;
		}
	}
	&__type {
		font-size: 10px;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	&__link {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 16px;
		text-decoration: none;
		padding-top: 5px;
		padding-bottom: 5px;
		@extend .transition-active;
		&:hover {
			text-decoration: underline;
		}
	}
	&__date {
		font-family: 'Uni Neue Book', sans-serif;
		margin-top: 10px;
		color: $c-dark-gray;
	}
	&__all {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
		.link-with-arrow {
			font-size: 14px;
			@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
		}
	}
}
