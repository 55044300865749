.article-others {
	$block: &;
	margin-top: 20px;
	margin-bottom: 50px;
	@include min-screen($breakpoint-sm) {
		margin-top: 30px;
		margin-bottom: 70px;
	}
	@include min-screen($breakpoint-md) {
		margin-top: 50px;
		margin-bottom: 100px;
	}
	&._beige {
		background-color: #f8f6f4;
		#{$block} {
			&__wrap {
				border: none;
			}
		}
	}
	&__title {
		@extend .title-h4;
		color: $c-burgundy;
	}
	&__wrap {
		position: relative;
		border-top: 1px solid $c-light-beige;
		padding-top: 20px;
		@include min-screen($breakpoint-sm) {
			padding-top: 30px;
			padding-left: 0;
			padding-right: 0;
			.article-others__read-all {
				margin-top: 30px;
			}
		}
		@include min-screen($breakpoint-md) {
			@include desktop-container-padding;
			padding-top: 30px;
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		margin-top: 23px;
		@include max-screen($breakpoint-md - 1px) {
			overflow-x: auto;
			padding-left: var(--container-indent);
			margin-left: calc(-1 * var(--container-indent));
			margin-right: calc(-1 * var(--container-indent));
			padding-bottom: 16px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 30px;
		}
	}
	&__video._article {
		height: 128px;
		width: 100%;
		@include smoothValue('height', 128px, 220px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			height: vw(220px, $breakpoint-lg);
			max-height: 300px;
		}
	}
	&__read-all {
		margin-top: 20px;
		display: none;
		@include min-screen($breakpoint-sm) {
			position: absolute;
			z-index: 2;
			right: 0;
			top: 0;
			display: block;
		}
	}
	&__slider {
		margin-top: 25px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}

		.standard-slider__arrows {
			right: 0;
			bottom: calc(100% - 52px);
			@include min-screen($breakpoint-md) {
				right: calc((100% - 15 * var(--col-gutter)) / 14 * 1);
			}
			@include max-screen($breakpoint-sm - 1px) {
				display: none;
			}
		}
	}
}

.article-others-item {
	$block: &;
	width: 216px;
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	flex-shrink: 0;
	@include max-screen($breakpoint-md - 1px) {
		&:last-child {
			padding-right: var(--container-indent);
		}
	}
	@include smoothValue('width', 216px, 360px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-lg) {
		width: vw(360px, $breakpoint-lg);
		max-width: 490px;
	}

	&:last-child {
		margin-right: 0;
	}

	&._big-sm {
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			width: calc((100% - 60px) / 2);
			.video-block__poster {
				height: vw(202px, $breakpoint-sm);
			}
			#{$block} {
				&__image {
					height: vw(202px, $breakpoint-sm);
				}
			}
		}
	}

	&__title {
		@extend .transition-active;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		font-size: 16px;
		padding-top: 5px;
		padding-bottom: 5px;
		@include smoothValue('font-size', 16px, 20px, $breakpoint-sm, $breakpoint-lg);
		&:hover {
			color: $c-beige;
			text-decoration: underline;
		}
		&-wrap:nth-child(2) {
			margin-top: 15px;
			@include min-screen($breakpoint-md) {
				margin-top: 20px;
			}
		}
	}
	&__date {
		font-size: 12px;
		font-family: 'Uni Neue Book', sans-serif;
		color: $c-dark-gray;
		margin-top: 10px;
		margin-bottom: 20px;
		@include min-screen($breakpoint-sm) {
			font-size: 14px;
		}
	}

	&__image {
		width: 100%;
		height: 128px;
		@include smoothValue('height', 128px, 220px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			height: vw(220px, $breakpoint-lg);
			max-height: 300px;
		}
	  	background-color: $c-beige-bg;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__doctor {
		display: flex;
		align-items: center;
		padding-top: 15px;
		border-top: 1px solid $c-light-beige;
		@include min-screen($breakpoint-md) {
			padding-top: 20px;
		}
	  	&:nth-child(4) {
		  margin-top: auto;
		}
	}

	&__avatar {
		display: none;
		width: 60px;
		height: 60px;
		padding-right: 20px;
		flex-shrink: 0;
		@include min-screen($breakpoint-md) {
			display: block;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&__name {
		@extend .transition-active;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		line-height: 1;
		color: $c-burgundy;
		@include min-screen($breakpoint-md) {
			font-size: 20px;
		}
		&:hover {
			color: $c-beige;
			text-decoration: underline;
		}
	}

	&__position {
		text-transform: lowercase;
		color: $c-dark-gray;
		margin-top: 5px;
		font-size: 12px;
		br {
			display: none;
		}

		@include min-screen($breakpoint-md) {
			font-size: 10px;
			font-family: 'Museo Sans Cyrl', sans-serif;
			text-transform: uppercase;
			br {
				display: block;
			}
		}
	}
}
.article-others-slider {
	&::after {
		content: 'flickity';
		display: none;
	}
	.flickity-viewport {
		overflow: visible;
		@include min-screen($breakpoint-md) {
			overflow: hidden;
		}
	}
	&__item {
		width: 214px;
		margin-right: 30px;
		@include min-screen($breakpoint-sm) {
			width: calc((100% - 30px) / 2);
		}
		@include min-screen($breakpoint-md) {
			//width: calc((100% - 60px) / 3);
			@include widthInContainer(4);
			margin-right: calc((100% - 15 * var(--col-gutter)) / 14 * 1);
		}
		.video-block__poster {
			height: 128px;
			//max-width: 360px;
			margin-left: 0;
			@include smoothValue('height', 128px, 202px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('height', 202px, 220px, $breakpoint-xxs, $breakpoint-sm);
			@include min-screen($breakpoint-lg) {
				height: vw(220px, $breakpoint-lg);
				max-height: 360px;
			}
		}
	}
}
