/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
	position: absolute !important;
	background: hsla(0, 0%, 100%, 0.75);
	border: none;
	color: #333;
}

.flickity-button:hover {
	//background: white;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
}

.flickity-button:disabled {
	opacity: 0;
	cursor: auto;
	/* prevent disabled button from capturing pointer up event. #716 */
	pointer-events: none;
	transition: opacity 0.2s ease-out;
}

.flickity-button-icon {
	fill: transparent;
	background: url("data:image/svg+xml,%3Csvg width='16' height='32' viewBox='0 0 16 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.46348 30.9094L13.9037 17.0006C14.2432 16.621 14.2432 16.0469 13.9037 15.6673L1.46348 1.75852' stroke='%23FFFCFC' stroke-width='3'/%3E%3C/svg%3E%0A")
		center center no-repeat;
}

.next .flickity-button-icon {
	transform: translateX(2px);
}

.previous .flickity-button-icon {
	transform: rotate(180deg) translateX(2px);
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	top: calc(50% - 45px);
	width: 44px;
	height: 44px;
	border-radius: 50%;
	/* vertically center */
}

.flickity-prev-next-button.previous {
	left: 0;
	transform: translate(-100%, -50%) translateX(-2rem);
}
.flickity-prev-next-button.next {
	right: 0;
	transform: translate(100%, -50%) translateX(2rem);
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
	margin: 40px -17px 0;

	@include min-screen(576px) {
		margin-top: 64px;
	}
	@include min-screen(1280px) {
		margin-top: 48px;
	}
	@include min-screen(1440px) {
		margin-top: 72px;
	}
}

.flickity-rtl .flickity-page-dots {
	direction: rtl;
}

.flickity-page-dots .dot {
	position: relative;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin: 0 17px;
	background: #0c4afb;
	border-radius: 50%;
	cursor: pointer;
	transition: opacity 0.2s ease-out;
	&::after {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: #e57ec7;
		border-radius: 50%;
		opacity: 0;
		transform: scale(0);
		transition: opacity 0.2s ease-out, trasform 0.2s ease-in;
	}

	&:hover {
		opacity: 0.6;
	}
}

.flickity-page-dots .dot.is-selected {
	&::after {
		opacity: 1;
		transform: scale(1);
	}
}
