.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  background-color: $c-beige;
  padding: 12px 15px 15px 15px;
  z-index: 999;

  @include min-screen($breakpoint-sm) {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px;
  }
  @include min-screen($breakpoint-md) {
	padding: 30px 40px;
  }
  @include min-screen($breakpoint-lg) {
	padding: 22px 50px;
  }

  &__disclaimer {
	color: #fff;
	margin-bottom: 15px;
	max-width: 428px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 0;
	  font-size: 16px;
	  @include smoothValue('font-size', 16px, 14px, $breakpoint-sm, $breakpoint-md);
	  line-height: 1.6;
	}
	@include min-screen($breakpoint-md) {
	  font-size: 14px;
	  @include smoothValue('font-size', 14px, 18px, $breakpoint-md, $breakpoint-lg);
	  max-width: none;
	}
	@include min-screen($breakpoint-lg) {
	  font-size: 18px;
	}
  }

  &__button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40px;
	background-color: $c-burgundy;
	padding: 10px 0;
	font-size: 12px;

	@include min-screen($breakpoint-sm) {
	  width: max-content;
	  flex-grow: 0;
	  height: 50px;
	  padding: 13px 45px;
	  font-size: 14px;
	  line-height: 1.7;
	}
	@include screen ($breakpoint-md, $breakpoint-lg - 1px) {
	  height: 40px;
	  font-size: 12px;
	}
	@include min-screen($breakpoint-lg) {
	  height: 50px;
	  font-size: 14px;
	}
  }
}


#cookie-popup button#accept-cookies {
	max-width: 170px;
}