@mixin common-form-float-field {
	$parent-selector: &;
	display: block;
	position: relative;
	.form-field {
		&:placeholder-shown:not(:focus) ~ {
			#{$parent-selector} {
				&__float-label {
					@content;
				}
			}
		}

		&::placeholder {
			//opacity: 0;
		}
	}
}

%common-form-float-label {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transition: all 0.2s;
	pointer-events: none;
	user-select: none;
}

%specific-form-float-field {
	padding-top: 21px;
	padding-bottom: 9px;
	@include min-screen($breakpoint-sm) {
		padding-top: 28px;
		padding-bottom: 10px;
	}
}

%specific-form-float-label {
	padding: 7px 11px;
	font-family: 'Uni Neue', sans-serif;
	font-size: 10px;
	font-weight: 500;
	line-height: 1;
	color: $c-gray-placeholder;
	@include min-screen($breakpoint-sm) {
		padding: 9px 11px 7px 11px;
		font-size: 12px;
	}
}

%specific-form-float-label-initial {
	top: 11px;
	font-size: 14px;
	background: transparent;
	@include min-screen($breakpoint-sm) {
		top: 13px;
		font-size: 16px;
	}
}

.form-float-field {
	$parent-selector: &;
	@include common-form-float-field {
		@extend %specific-form-float-label-initial;
	}
	&__float-label {
		@extend %common-form-float-label, %specific-form-float-label;
	}
	.form-field {
		@extend %specific-form-float-field;
	}
	textarea ~ {
		#{$parent-selector} {
			&__float-label {
				background: $c-beige-form;
			}
		}
	}
}
