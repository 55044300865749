.side-drawer {
  &__wrapper {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 1000;
  }
  &__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  &__content {
	position: absolute;
	box-sizing: border-box;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	padding: 25px 15px 16px 15px;
	background-color: #fff;
	box-shadow: -10px 15px 50px rgba(0, 0, 0, .15);
	overflow-y: auto;

	@include min-screen($breakpoint-sm) {
	  width: 550px;
	  padding: 45px 40px;
	}
	@include min-screen($breakpoint-md) {
	  width: 570px;
	  padding: 55px 40px;
	}
  }
  &__close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 21px;
	height: 21px;
	cursor: pointer;
	@include min-screen($breakpoint-sm) {
	  top: 25px;
	  right: 25px;
	  width: 28px;
	  height: 28px;
	}
	svg {
	  stroke: $c-burgundy;
	  stroke-width: 2.5px;
	}
  }
}
