.relinking {
	@include small-section-mt();
	$block: &;

	&._col-4 {
		margin-top: 20px;
		@include min-screen($breakpoint-md) {
			margin-top: 30px;
		}
		#{$block} {
			&__wrap {
				padding-top: 20px;
				padding-bottom: 20px;
				@include min-screen($breakpoint-md) {
					padding-top: 30px;
					padding-bottom: 30px;
				}
			}
			&__list {
				display: block;
				margin-top: 15px;
				margin-bottom: -5px;
				@include min-screen($breakpoint-sm) {
					columns: 2;
					margin-top: 22.5px;
					margin-bottom: -7.5px;
				}
				@include min-screen($breakpoint-lg) {
					columns: 4;
					margin-top: 10px;
					margin-bottom: -10px;
				}
			}
			&__item {
				width: 100%;
				margin: 0;
				padding-top: 5px;
				padding-bottom: 5px;
				&::before {
					transform: translateY(5px);
					@include max-screen($breakpoint-sm - 1px) {
						left: 0;
					}
				}
				@include min-screen($breakpoint-sm) {
					padding-top: 7.5px;
					padding-bottom: 7.5px;
					&::before {
						transform: translateY(7.5px);
					}
				}
				@include min-screen($breakpoint-md) {
					padding-top: 10px;
					padding-bottom: 10px;
					&::before {
						transform: translateY(10px);
					}
				}
			}
		}
	}

	&__wrap {
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	li.relinking__item {
	  	$icon-height: 24px;
	  	@include min-screen($breakpoint-md) {
		  	$icon-height: 30px;
		}
		margin-top: 20px;
		@include smoothValue('margin-top', 20px, 25px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 25px, 35px, $breakpoint-sm, $breakpoint-lg);
	  	height: max-content;

	  	&::before {
		  	top: 0.8em;
		  	@include max-screen($breakpoint-sm) {
			  	top: 0.9em;
			}
		}
	  	a {
		  	line-height: $icon-height;
		  	vertical-align: text-bottom;
		}
		svg {
			display: inline-block;
			vertical-align: bottom;
			width: $icon-height;
			height: $icon-height;
		}
	}
	li {
		padding-left: 15px;
		@include min-screen($breakpoint-sm) {
			padding-left: 20px;
		}
		@include min-screen($breakpoint-lg) {
			padding-left: 25px;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: -20px;
		@include smoothValue('margin-top', -20px, -25px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', -25px, -35px, $breakpoint-sm, $breakpoint-lg);
	}
	&__item {
		width: 100%;
		font-size: 14px;
		box-sizing: border-box;
	  	padding-left: 5px;
		@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			width: 50%;
		}
		@include min-screen($breakpoint-md) {
			width: 1/3 * 100%;
			padding-right: 32px;
		}
		a:hover {
			text-decoration: underline;
		}
	}
}
