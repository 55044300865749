.standard-slider {
	position: relative;
	[data-slider-slides]::after {
		content: 'flickity';
		display: none;
	}
	&__arrows {
		display: flex;
		justify-content: center;
		margin-top: 28px;
		@include min-screen($breakpoint-sm) {
			position: absolute;
			z-index: 22;
			right: 3.3%;
			bottom: calc(100% + 34px);
		}
		@include min-screen($breakpoint-fhd) {
			right: 5.5%;
			bottom: calc(100% + 51px);
		}
	}
	&__arrow {
		user-select: none;
		transition: color $default-transition-duration $default-transition-function;

		&:hover {
			color: $c-beige;
			cursor: pointer;
		}
		&:active {
			opacity: 0.6;
		}

		svg {
			width: 24px;
			height: 13px;
		}
	}

	&__nav {
		& + & {
			margin-left: 70px;
		}
	}
}

@mixin sliderArrows() {
	&__arrows {
		display: flex;
		justify-content: center;
		@include min-screen($breakpoint-sm) {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			justify-content: space-between;
			margin-top: auto;
			margin-bottom: 20px;
			pointer-events: none;
		}
		@include min-screen($breakpoint-lg) {
			width: auto;
			left: 0;
			right: 0;
			margin-left: -65px;
			margin-right: -65px;
		}
	}
	&__nav {
		@extend %reset-Button;
		width: 40px;
		height: 40px;
		border: 1px solid $c-light-beige;
		border-radius: 50%;
		user-select: none;
		@include min-screen($breakpoint-sm) {
			margin-top: auto;
			margin-bottom: auto;
			pointer-events: all;
		}
		& + & {
			margin-left: 40px;
		}
	}
	&__arrow {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&._next svg {
			transform: translateX(1px);
		}
		&._prev svg {
			transform: translateX(-1px);
		}
	}
}
