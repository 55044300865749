.scroll-indicator {
  &._invisible {
	transform: translateY(-150px);
  }
  position: fixed;
  z-index: 1000;
  top: 50px;
  @include min-screen($breakpoint-sm) {
	top: 60px;
  }
  @include min-screen($breakpoint-md) {
	top: 70px;
  }
  transform: translateY(0);
  transition: all $default-transition-duration $default-transition-function;
  background-color: #ECE7E2;
  width: 100%;
  min-width: 576px;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
	display: none;
  }

  &__milestones {
	padding: 15px;
	display: flex;
	justify-content: space-between;

	@include min-screen($breakpoint-sm) {
	  padding: 12.5px 30px;
	}
	@include min-screen($breakpoint-md) {
	  padding: 12.5px 50px;
	}
  }

  &__milestone {
	display: block;
	font: 500 16px/1.4 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 16px, 18px, $breakpoint-xs, $breakpoint-sm);
	color: $c-dark-gray;

	&._active {
	  color: $c-burgundy;
	}
  }

  &__bar {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: $c-burgundy;
	height: 4px;
	width: 0;
	transition: width $default-transition-duration $default-transition-function;
  }
}
