.popup-card {
  &__title {
	max-width: 80%;
	@include screen($breakpoint-sm, $breakpoint-md - 1) {
	  max-width: 65%;
	}
	@include min-screen($breakpoint-md) {
	  max-width: 100%;
	}
	color: $c-burgundy;
	font: normal 23px/115% 'Kudryashev Headline', sans-serif;
	@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);

	&._small-mb {
	  margin-bottom: 10px;
	}
	&._desktop-small-mb {
	  @include smoothValue('margin-bottom', 30px, 20px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
	@include min-screen($breakpoint-xs) {
	  display: grid;
	  grid-template-columns: repeat(2, minmax(min-content, 1fr));
	}
  }
  &__flex-reverse {
	@include min-screen($breakpoint-xs) {
	  display: flex;
	  flex-direction: column-reverse;
	}
  }
  &__flex {
	@include max-screen($breakpoint-xs - 1px) {
	  margin-bottom: 30px;
	}
	@include min-screen($breakpoint-xs) {
	  display: flex;
	}
	@include screen($breakpoint-sm, $breakpoint-md) {
	  justify-content: space-between;
	}
  }
  &__image {
	width: 100%;
	position: relative;
	@include min-screen($breakpoint-xs) {
	  width: 350px;
	  margin-right: 30px;
	  grid-column: 1 / 2;
	}
	@include min-screen($breakpoint-sm) {
	  width: 412px;
	}
	@include min-screen($breakpoint-lg) {
	  margin-right: 110px;
	  width: 590px;
	}

	img {
	  width: 100%;
	}
	&:not(._square)::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(rgba($c-beige, .3), rgba($c-beige, .3)),
	  linear-gradient(rgba($c-dark-gray, .2), rgba($c-dark-gray, .2));
	  background-blend-mode: color;
	  mix-blend-mode: color;
	}
	&._square {
	  width: 250px;
	  margin-bottom: 10px;
	  @include min-screen($breakpoint-xs) {
		margin-right: 43px;
		margin-bottom: 20px;
	  }
	  @include min-screen($breakpoint-sm) {
		width: 375px;
	  }
	  @include min-screen($breakpoint-lg) {
		width: 510px;
		margin-right: 110px;
	  }

	  img {
		width: 100%;
	  }
	  &::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(rgba($c-beige, .1), rgba($c-beige, .2));
		background-size: contain;
		background-blend-mode: hard-light;
		mix-blend-mode: multiply;
	  }
	}
  }
  &__slider {
	position: relative;

	.slider-gallery {
	  margin-top: 0;
	}
	.slider-gallery__wrap {
	  max-width: 920px;
	}
	.flickity-viewport {
	  height: 100%;
	}
	.slider-gallery-main__image::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(rgba($c-beige, .3), rgba($c-beige, .3)),
	  		linear-gradient(rgba($c-dark-gray, .2), rgba($c-dark-gray, .2));
	  background-blend-mode: color;
	  mix-blend-mode: color;
	}
	.slider-gallery-main__arrows {
	  @include min-screen($breakpoint-lg) {
		padding: 0;
		width: auto;
		margin-left: calc(-5 * var(--container-indent));
		margin-right: calc(-5 * var(--container-indent));
	  }
	}
	.slider-gallery-main__nav {
	  @include max-screen($breakpoint-sm - 1px) {
		margin: 0;
	  }
	}
	.slider-gallery-main__static-info {
	  @include max-screen($breakpoint-sm - 1px) {
		display: none;
	  }
	}
   }
  &__delimeter {
	margin-top: 20px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 15px;
	@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);

	&._dark {
	  background-color: #D3D0CE;
	  margin-bottom: 20px;
	  @include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
	}
	&._m-large {
	  margin-bottom: 20px;
	  @include min-screen($breakpoint-sm) {
		margin-top: 30px;
		margin-bottom: 21px;
	  }
	}
  }
  &__text {
	max-width: 691px;
	font: 400 14px/1.6 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);

	p:not(:last-child) {
	  margin-bottom: 22px;
	}
	&._grid {
	  @include min-screen($breakpoint-xs) {
		grid-column: 1 / -1;
		grid-row: 2 / 3;
	  }
	}
  }
  &__caption {
	@extend .title-h6;
	margin: 20px 0 20px 20px;
	@include min-screen($breakpoint-sm) {
	  margin: 20px auto;
	  text-align: center;
	}
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-sm, $breakpoint-lg);
  }
  &__specs {
	margin-bottom: 15px;
	@include max-screen($breakpoint-xs - 1px) {
	  text-transform: uppercase;
	}
  }
  &__qualifications {
	font: 300 12px/15px 'Museo Sans Cyrl', sans-serif;
	@include min-screen($breakpoint-sm) {
	  font-size: 20px;
	  line-height: 24px;
	}
	margin-bottom: 10px;
  }
  &__position {
	color: #8c8c8c;
	font: 300 10px/1.7 'Museo Sans Cyrl', sans-serif;
	@include min-screen($breakpoint-sm) {
	  font: 400 14px/1.5 'Uni Neue', sans-serif;
	}
  }
  &__features {
	margin-bottom: 40px;
	margin-left: 20px;
	@include smoothValue('margin-bottom', 40px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-xs) {
	  display: flex;
	  margin-left: 0;
	}
  }
  &__column {
	@include min-screen($breakpoint-xs) {
	  display: flex;
	  flex-direction: column;
	  justify-content: space-between;
	}
	&:first-child {
	  @include min-screen($breakpoint-xs) {
		width: 42%;
	  }
	}
	&:nth-child(2), &:nth-child(3) {
	  @include min-screen($breakpoint-xs) {
		width: 29%;
	  }
  	}
	@include min-screen($breakpoint-md) {
	  width: 33.33333%;
	}
  }
  &__feature {
	display: flex;
	align-items: center;

	&:not(:last-child) {
	  margin-bottom: 25px;
	  @include min-screen($breakpoint-xs) {
		margin-bottom: 13px;
	  }
	}
	&:last-child {
	  @include max-screen($breakpoint-xs - 1px) {
		margin-bottom: 25px;
	  }
	}

	svg {
	  width: 27px;
	  height: 27px;
	  margin-right: 15px;
	  flex-shrink: 0;
	}
  }
  &__feature-text {
	font: 500 14px/1.6 'Uni Neue', sans-serif;
  }
  &__checkout {
	display: flex;
	flex-direction: column;
	align-items: center;
	@include min-screen($breakpoint-xs) {
	  flex-direction: row;
	  align-items: center;
	  justify-content: flex-end;
	}
	&._grid {
	  display: block;
	  @include min-screen($breakpoint-xs) {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	  }
	}
  }
  &__price-block {
	margin-bottom: 20px;
	text-align: center;
	@include min-screen($breakpoint-xs) {
	  margin-bottom: 0;
	  margin-right: 30px;
	  text-align: left;
	}
  }
  &__price-caption {
	font: 300 9px/1.6 'Museo Sans Cyrl', sans-serif;
	color: $c-beige;
	text-transform: uppercase;
  }
  &__price-figure {
	@extend .subtitle-20-100;
	font-weight: 300;
	&._grid {
	  @include max-screen($breakpoint-xs - 1px) {
		text-align: center;
	  }
	  margin-bottom: 20px;
	}
  }
  &__button {
	width: 100%;
	@include min-screen($breakpoint-xs) {
	  width: 153px;
	}
	&._short {
	  @include screen($breakpoint-xs, $breakpoint-md) {
		height: 40px;
	  }
	  @include min-screen($breakpoint-xs) {
		width: 124px;
		@include smoothValue('width', 124px, 153px, $breakpoint-sm, $breakpoint-lg);
	  }
	}
  }
  &__stats {
	margin-top: 14px;
	@include min-screen($breakpoint-xs) {
	  margin-top: 80px;
	}
	@include min-screen($breakpoint-lg) {
	  width: 300px;
	}
  }
  &__stat {
	font: 400 12px/16px 'Uni Neue Book', sans-serif;
	display: flex;
	flex-wrap: wrap;
	@include min-screen($breakpoint-sm) {
	  font-size: 16px;
	  line-height: 1.6;
	}
	&:not(:last-child) {
	  margin-bottom: 11px;
	  @include min-screen($breakpoint-sm) {
		margin-bottom: 21px;
	  }
	  @include min-screen($breakpoint-lg) {
		margin-bottom: 30px;
	  }
	}
	svg {
	  vertical-align: middle;
	  margin-right: 12px;
	  width: 16px;
	  height: 17px;
	  @include min-screen($breakpoint-sm) {
		margin-right: 15px;
		width: 24px;
		height: 24px;
	  }
	}
	span {
	  font-family: 'Uni Neue', sans-serif;
	  font-weight: 500;
	}
  }
}
