.article-with-nav {
  &__wrap {
	@include min-screen($breakpoint-md) {
	  @include desktop-container-padding-right;
	  display: flex;
	  align-items: flex-start;
	}
  }
  &__nav {
	box-sizing: border-box;
	@include min-screen($breakpoint-md) {
	  @include widthInContainer(4);
	  max-width: 320px;
	  margin-right: $indent-col-md;
	}
  }
  &__main {
	box-sizing: border-box;
	@include min-screen($breakpoint-md) {
	  @include widthInContainer(9);
	  max-width: 785px;
	  margin-left: $indent-col-md;
	}
  }
}

.article-nav {
  border: 1px solid $c-light-beige;
  padding: 25px 20px 30px 25px;
  background-image: url("../images/backgrounds/waves-small-right.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-blend-mode: multiply;
  margin-bottom: 30px;
  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-xxs, $breakpoint-sm);

  ul {
	list-style: none;
  }
  li:not(:last-child) {
	margin-bottom: 20px;
  }
  a,
  a:visited,
  a:active {
	display: inline-block;
	border-bottom: 1px solid currentColor;
	color: #333;
	font-size: 20px;
	font-family: 'Uni Neue Book', sans-serif;
	@extend .transition-active;
  }
  a:hover {
	border-color: transparent;
	color: $c-beige;
  }
}
