.sausage-menu {
	$block: &;
	&__list {
		display: flex;
		white-space: nowrap;
		overflow-x: auto;
		padding-bottom: 10px;
	}
	&__item {
		display: flex;
		align-items: center;
		&._active {
			#{$block} {
				&__link {
					background-color: $c-beige;
					color: $c-white;
				}
			}
		}
	}
	&__link {
		height: 32px;
		display: inline-flex;
		align-items: center;
		border-radius: 38px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 16px;
		font-weight: 500;
		@include smoothValue('height', 32px, 40px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('font-size', 16px, 18px, $breakpoint-sm, $breakpoint-lg);
	}
}
