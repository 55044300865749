.call-button {
	@extend %reset-Button;
	position: sticky;
	z-index: 100;
	//bottom: 16px;
	//right: 7px;
	top: 100vh;
	left: 100%;
	height: 1px;
	display: block;
	transform: translate(-50%, -120%);
	@include max-screen($breakpoint-md) {
		position: fixed;
	}
	@include smoothValue('bottom', 16px, 24px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('right', 7px, 20px, $breakpoint-sm, $breakpoint-lg);

	&:hover &__main {
		transform: scale(1.0666);
		border-color: #d9cab6;
	}

	&__wrap {
		position: absolute;
		bottom: calc(100% + 16px);
		right: calc(100% + 16px);
	}

	&__main {
		width: 72px;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff url('../images/backgrounds/call-btn-bg.png') center no-repeat;
		background-blend-mode: multiply;
		//background-size: cover;
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer;
		border: 1px solid #e0e0e0;
		box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.04);
		transform: scale(1);
		transition: border-color $default-transition-function $default-transition-duration,
			transform $default-transition-duration $default-transition-function;

		@include smoothValue('width', 72px, 90px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('height', 72px, 90px, $breakpoint-md, $breakpoint-lg);

		svg {
			width: 26px;
			height: 27px;
		}
	}

	&__operator {
		position: absolute;
		left: -10px;
		bottom: -3px;
		width: 34px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $c-burgundy;
		border-radius: 50%;
		border: 1px solid #ffffff;
		box-shadow: inset 1.57447px 1.57447px 3.14894px rgba(0, 0, 0, 0.18);

		@include smoothValue('width', 34px, 43px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('height', 34px, 43px, $breakpoint-md, $breakpoint-lg);

		svg {
			width: 15px;
			height: 15px;
		}

		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			width: 70%;
			height: 70%;
			border-radius: 50%;
			opacity: 0.2;
			box-shadow: 4px 14px 11px #621312;
		}
	}
}
