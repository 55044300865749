.program-card {
	border: 1px solid $c-light-beige;
	&__wrap {
		padding: 8px;
		@include min-screen($breakpoint-xs) {
			display: flex;
		}
	}
	&__info {
		padding: 10px;
		margin-top: 15px;
		@include min-screen($breakpoint-xs) {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			margin-top: 0;
			padding: 20px 32px;
		}
	}
	&__bottom {
		margin-top: 15px;
		@include min-screen($breakpoint-xs) {
			margin-top: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__image {
	  	position: relative;
		@include min-screen($breakpoint-xs) {
			@include smoothValue('width', 260px, 330px, $breakpoint-xs, $breakpoint-lg);
		  	flex-shrink: 0;
		}
	  	&::after {
		  content: '';
		  	position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		  	background: linear-gradient(rgba($c-beige, .3), rgba($c-beige, .3)),
				linear-gradient(rgba($c-dark-gray, .3), rgba($c-dark-gray, .3));
		  	background-blend-mode: color;
		  	mix-blend-mode: color;
		}
		img {
			width: 100%;
			height: 200px;
			object-fit: cover;
			@include smoothValue('height', 200px, 250px, $breakpoint-sm, $breakpoint-lg);
		}
	}
	&__title {
		@extend .title-h5;
		color: $c-burgundy;
	}
	&__text {
		@extend .base-text;
		font-family: 'Uni Neue Book', sans-serif;
		margin-top: 5px;
		@include min-screen($breakpoint-lg) {
			font-size: 20px;
		}
	}
	&__price {
		@extend .title-h5;
		font-weight: 300;
	}
	&__link {
		margin-top: 15px;
		@include min-screen($breakpoint-xs) {
			margin-top: 0;
		}
		.link-with-arrow {
			font-size: 14px;
			@include min-screen($breakpoint-md) {
				font-size: 16px;
			}
		}
	}
}
