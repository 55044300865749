.form {
  $parent-selector: &;
  margin-left: auto;
  margin-right: auto;
  @include min-screen($breakpoint-md) {
    max-width: 660px;
  }

  &__title {
    margin-bottom: 19px;
    font-family: 'Kudryashev Headline', sans-serif;
    font-size: 23px;
    line-height: 1.2;
    font-weight: 500;
    color: $c-burgundy;

    .modal & {
      padding-right: 40px;
    }
  }

  &__group {
    margin-bottom: 20px;

    &._policy {
      max-width: 290px;
      margin-bottom: 18px;

      &._v2 {
        margin-bottom: 28px;
      }
    }

    &._subscribe {
      max-width: 360px;
      margin-bottom: 15px;
    }

    &._small {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &._small-only-child {
      margin-bottom: 15px;
    }

    &._survey {
      @include min-screen($breakpoint-sm) {
        margin-bottom: 30px;
      }
    }
  }

  &__button {
    .button {
      width: 100%;
      padding-left: 45px;
      padding-right: 45px;

      ._page-review & {
        padding-left: 41px;
        padding-right: 41px;
      }
    }
  }

  &__grid {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__col {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__block {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid $c-light-beige;

    #{$parent-selector} {
      &__group {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__block-title {
    margin-bottom: 21px;
    font-family: 'Museo Sans Cyrl', sans-serif;
    font-weight: 100;
    font-size: 20px;
    line-height: 1.2;

    &._small {
      font-family: 'Uni Neue', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.6;

      #{$parent-selector} {
        &__title-slash,
        &__title-translate {
          font-family: 'Uni Neue Book', sans-serif;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }

  &__title-slash,
  &__title-translate {
    font-size: 18px;
  }

  &__title-slash,
  &__title-translate,
  &__control-translate,
  &__control-slash {
    color: $c-dark-gray;
  }

  &__title-translate {
    display: block;
  }

  &__title-required {
    color: $c-burgundy;
  }

  &__control-translate {
    font-size: 12px;

    &._rate {
      display: block;
    }

    &._text {
      display: block;
    }
  }

  &__rate {
    margin-left: -10px;
    margin-right: -10px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  &__rate-block {
    display: inline-flex;
    flex-direction: column;
    min-width: 100%;
  }

  &__rate-row {
    display: inline-flex;
    justify-content: space-between;

    &._description {
      margin-top: 11px;
    }
  }

  &__rate-item {
    flex-shrink: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Uni Neue Book', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    color: #1b1b1b;

    &._rate-max {
      text-align: right;
    }

    #{$parent-selector} {
      &__control-translate {
        font-size: 12px;
      }
    }
  }

  &__control-grid {
    margin-bottom: 14px;
  }

  &__control-col {
    min-width: 150px;
    margin-bottom: 15px;
  }

  &__text {
    font-family: 'Uni Neue Book', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;

    a {
      color: $c-burgundy;
    }
  }

  &._page-review {
    max-width: 750px;
    padding-top: 25px;
    padding-bottom: 50px;
  }

  @include min-screen($breakpoint-sm) {
    &__title {
      font-size: 38px;
      margin-bottom: 30px;

      .modal & {
        padding-right: 0;
      }
    }
    &__group {
      margin-bottom: 30px;

      &._policy {
        margin-bottom: 0;

        &._v2 {
          margin-bottom: 0;
        }
      }

      &._subscribe {
        margin-bottom: 33px;
      }
    }
    &__button {
      .button {
        width: auto;
        height: 60px;
        margin-left: auto;
      }
    }
    &__block {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    &__block-title {
      font-size: 26px;
      line-height: 1.4;

      &._small {
        font-size: 18px;
        line-height: 1.65;

        #{$parent-selector} {
          &__title-slash,
          &__title-translate {
            font-size: 16px;
          }
        }
      }
    }
    &__title-slash,
    &__title-translate {
      font-size: 20px;
    }
    &__control-translate {
      font-size: 16px;
    }
    &__control-col {
      min-width: 180px;
    }
    &__text {
      font-size: 18px;
    }
    &._page-review {
      padding-top: 30px;
      padding-bottom: 100px;
    }
  }
  @include min-screen($breakpoint-md) {
    &__rate {
      max-width: 590px;
    }
    &._page-review {
      max-width: 600px;
    }
  }

  &__more {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $c-light-beige;

    @include min-screen($breakpoint-sm) {
      margin-top: 30px;
      padding-top: 30px;
    }
    @include screen($breakpoint-sm, $breakpoint-md - 1px) {
      text-align: center;
    }
  }

  & sup {
    color: $c-burgundy;
  }

  &__info {
    font: 400 12px/16px 'Uni Neue', sans-serif;
    @include smoothValue('font-size', 12px, 16px, $breakpoint-xxs, $breakpoint-sm);
    @include smoothValue('line-height', 16px, 24px, $breakpoint-xxs, $breakpoint-sm);
    color: #000;

    &._legend-info {
      margin-bottom: 25px;
    }
  }

  &__fieldset {
    border: none;
    border-bottom: 1px solid $c-light-beige;
    padding-top: 25px;
    padding-bottom: 5px;
    @include smoothValue('padding-top', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
    @include min-screen($breakpoint-sm) {
      padding-bottom: 0;
    }

    &._topmost {
      padding-top: 30px;
      @include smoothValue('padding-top', 30px, 21px, $breakpoint-xs, $breakpoint-sm);
    }

    &._last-set {
      padding-bottom: 25px;
      @include smoothValue('padding-bottom', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
    }
  }

  &__legend {
    @extend .title-h6;
    @include smoothValue('font-size', 20px, 26px, $breakpoint-xxs, $breakpoint-sm);
    margin-bottom: 20px;

    &._with-info {
      margin-bottom: 0;
    }
  }

  &__hidden {
    display: none;

    &._active {
      display: block;
    }
  }
}

.form-group-double-input {
  .form__group-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    .form-float-field {
      width: 100%;
    }
  }
  .form__group:not(._policy) {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: unset;
    &:nth-child(2):not(._policy) {
      margin-left: 30px;
    }
  }
  .form-checkbox__label-policy {
    font-size: 12px !important;
  }
  .form-checkbox__label {
    font-size: 15px;
  }
}

.form-group-double-input {
  .display-none-form-group {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .form-group-double-input {

    .form__group:not(._policy) {
      &:nth-child(2):not(._policy) {
        margin-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .form-group-double-input {
    .form__group-wrapper {
      flex-direction: column;
      margin-bottom: 20px;
      .form__group:not(._policy) {
        margin-bottom: 20px !important;
      }
      .form__group:nth-child(2):not(._policy) {
        margin-left: 0 !important;
        margin-bottom: 0 !important;
      }
      .form-checkbox__label {
        font-size: 14px;
      }
    }
  }
}