.list-with-logos {

  &__lead-in {
	font: 300 17px/1.6 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 17px, 19px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-md);
	padding-bottom: 25px;
	@include smoothValue('padding-bottom', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
	border-bottom: 1px solid $c-light-beige;
  }
}

.partner-block {
  padding: 25px 0 20px 0;
  @include smoothValue('padding-top', 25px, 30px, $breakpoint-xs, $breakpoint-sm);
  @include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xs, $breakpoint-sm);
  //@include smoothValue('margin-left', 0px, 65px, $breakpoint-md, $breakpoint-lg);
  @include min-screen($breakpoint-sm) {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
  }
  border-bottom: 1px solid $c-light-beige;

  &__img {
	border: 1px solid $c-light-beige;
	width: 100%;
	flex-shrink: 0;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	background-color: #fff;

	@include min-screen($breakpoint-sm) {
	  width: 180px;
	  height: 180px;
	  @include smoothValue('width', 180px, 220px, $breakpoint-sm, $breakpoint-lg);
	  @include smoothValue('height', 180px, 220px, $breakpoint-sm, $breakpoint-lg);
	}

	&::after {
	  content: '';
	  position: absolute;
	  z-index: 10;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: $c-beige;
	  mix-blend-mode: color;
	  opacity: .2;
	  transition: opacity $default-transition-duration $default-transition-function;
	}
	& img {
	  border: none;
	  width: 150px;
	  @include smoothValue('width', 150px, 180px, $breakpoint-sm, $breakpoint-lg);
	  height: auto;
	  filter: grayscale(100%);
	  opacity: .3;
	  transition: all $default-transition-duration $default-transition-function;
	}

	&:hover img {
	  opacity: 1;
	  filter: none;
	}
	&:hover::after {
	  visibility: hidden;
	  opacity: 0;
	}
  }

  &__text {
	margin-top: 20px;
	@include min-screen($breakpoint-sm) {
	  margin-top: 0;
	  margin-right: 35px;
	  @include smoothValue('margin-right', 35px, 55px, $breakpoint-sm, $breakpoint-lg);
	}
  }

  &__name {
	@extend .title-h6;
	margin-bottom: 10px;
  }
  &__desc {
	line-break: strict;
	font: 400 14px/1.6 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);

	& p:not(:last-child) {
	  margin-bottom: 10px;
	}
  }
}
