.menu {
	$block: &;
	display: flex;
	margin-left: -10px;
	margin-right: -10px;

	@include max-screen($breakpoint-md) {
		margin-left: 0px;
		margin-right: 0px;
	}
	@include min-screen($breakpoint-sm) {
		margin-left: -12px;
		margin-right: -12px;
	}
	@include min-screen($breakpoint-lg) {
		margin-left: -20px;
		margin-right: -20px;
	}
	@include min-screen($breakpoint-fhd) {
		margin-left: -27px;
		margin-right: -27px;
	}

  @include max-screen($breakpoint-md) {
    ._doctors {
      max-width:90px;
    }

    ._about {
      min-width:35px;
    }
  }
	&__text {
		&._mobile {
			font-size: 12px;
			@include min-screen($breakpoint-sm) {
				display: none;
			}
		}
		&._desktop {
			position: relative;
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: bold;
			@include max-screen($breakpoint-sm - 1px) {
				display: none;
			}
			@include smoothValue('font-size', 13px, 15px, $breakpoint-md, $breakpoint-lg);
			@include smoothValue('font-size', 15px, 18px, $breakpoint-lg, $breakpoint-fhd);
		}
	}
	&__item {
		position: relative;
		display: block;
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: underline;
		text-transform: uppercase;

		@include max-screen($breakpoint-md) {
			padding-left: 6px;
			padding-right: 6px;
		}
		a {
			display: inline-block;
			text-decoration: underline;
		  	@extend .transition-active;

		  	&:hover {
			  	text-decoration: none;
			}
		}

		&::after {
			position: absolute;
			content: '';
			top: calc(100% + var(--header-bottom-padding) - 3px);
			left: 0;
			width: 100%;
			height: 4px;
			background-color: $c-beige;
			transform: translateY(3px);
			opacity: 0;
			@extend .transition-move;
			@include min-screen($breakpoint-sm) {
				top: calc(100% + var(--header-bottom-padding) - 3px);
			}
			._fixed & {
			  	@include min-screen($breakpoint-xs) {
				  	top: calc(100% + var(--header-bottom-padding) - 15px);
				}
				@include min-screen($breakpoint-sm) {
					top: calc(100% + var(--header-bottom-padding) - 10px);
				}
				@include min-screen($breakpoint-md) {
					top: calc(100% + var(--header-bottom-padding) - 6px);
				}
			}
		}

		&._active {
			a {
				text-decoration: none;
				color: $c-beige;
			}
		}

		&._current-section &::after {
			opacity: 0;
			transform: translateY(0);
		}
		.header:not(._open-menu) &._current-section {
			color: $c-burgundy;
			&::after {
				opacity: 1;
				transform: translateY(0);
			}
			@include max-screen($breakpoint-sm - 1px) {
				color: $c-burgundy;
			}
		}

		&._active::after {
			opacity: 1;
			transform: translateY(0);

			@include max-screen($breakpoint-xs - 1px) {
				top: calc(100% + 10px);
			}

			._open-menu & {
				top: auto;
				bottom: -10px;
			}
		}

		@include min-screen($breakpoint-sm) {
			padding-left: 12px;
			padding-right: 12px;
		}
		@include min-screen($breakpoint-lg) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include min-screen($breakpoint-fhd) {
			padding-left: 27px;
			padding-right: 27px;
		}

		&._without-popup {
			#{$block} {
				&__text::after {
					display: none;
				}
			}
		}
	}
	&__popup {
		@include popupContainer();
		@extend .transition-move;
		top: calc(100% + 16px);
		background-color: $c-white;
		padding-top: 1rem;
		padding-bottom: 1rem;

		&::before {
			position: absolute;
			content: '';
			left: 0;
			bottom: 100%;
			width: 100%;
			height: 16px;
		}

		.menu__item + .menu__item {
			margin-top: 8px;
		}

		@include max-screen($breakpoint-sm - 1px) {
			display: none;
		}
	}
}
._fixed .menu{
	@include min-screen($breakpoint-lg) {
		margin-left: -150px;
		margin-right: -20px;
	}
}