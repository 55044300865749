@mixin common-form-field {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border: none;
	border-radius: 0;
	line-height: normal;
	background-image: none;
	box-shadow: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&::placeholder {
		font-family: inherit;
		font-size: inherit;
		line-height: normal;
		opacity: 1;
	}
	&:focus {
		outline: none;
	}
	@at-root {
		textarea#{&} {
			resize: none;
		}
		select#{&} {
			&::-ms-expand {
				display: none;
			}
		}
	}
}

%specific-form-field {
	height: 50px;
	padding: 15px 11px;
	font-family: 'Uni Neue', sans-serif;
	font-size: 14px;
	font-weight: 500;
	color: $c-dark;
	background-color: $c-beige-form;
	@include min-screen($breakpoint-sm) {
		height: 60px;
		padding: 19px 11px;
		font-size: 16px;
	}
}

.form-field {
	@include common-form-field;
	@extend %specific-form-field;
	&::placeholder {
		color: $c-gray-placeholder;
	}
	@at-root {
		textarea#{&} {
			height: 160px;
		}
		select#{&} {
			padding-right: 42px;
			background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%238D3332' stroke-width='1.5'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: right 16px center;
			background-size: 10px 7px;
			@include min-screen($breakpoint-sm) {
				padding-right: 52px;
				background-position: right 21px center;
			}
		}
	}
	&._date {
		padding-right: 50px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.5 19.5C21.5 20.05 21.05 20.5 20.5 20.5H3.5C2.95 20.5 2.5 20.05 2.5 19.5V4.5C2.5 3.95 2.95 3.5 3.5 3.5H20.5C21.05 3.5 21.5 3.95 21.5 4.5V19.5Z' stroke='%238D3332' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.5 2V5' stroke='%238D3332' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5 2V5' stroke='%238D3332' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.5 14.5L15.5 17.5' stroke='%238D3332' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.5 17.5L15.5 14.5' stroke='%238D3332' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.5 10.5V9.5H7.5V10.5H6.5Z' stroke='%238D3332'/%3E%3Cpath d='M11.5 10.5V9.5H12.5V10.5H11.5Z' stroke='%238D3332'/%3E%3Cpath d='M6.5 16.5V15.5H7.5V16.5H6.5Z' stroke='%238D3332'/%3E%3Cpath d='M11.5 16.5V15.5H12.5V16.5H11.5Z' stroke='%238D3332'/%3E%3Cpath d='M16.5 10.5V9.5H17.5V10.5H16.5Z' stroke='%238D3332'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: right 13px center;
		background-size: 24px 24px;
		@include min-screen($breakpoint-sm) {
			padding-right: 60px;
			background-position: right 18px center;
		}
	}
	&._with-autocomplete {
		@extend .transition-active;
		border: 1px solid $c-beige-form;
		&:focus {
			background-color: $c-white;
		}
	}
}
