.page-sphere {
	$block: &;
	position: absolute;
	height: 800px;
	width: 100%;
	overflow-x: hidden;
	overflow-y: visible;
	pointer-events: none;
	&._big {
		#{$block} {
			&__image {
				top: 4%;
				opacity: 0.8;
				@include min-screen($breakpoint-xs) {
					right: -35%;
					top: 0;
				}
			  @include min-screen($breakpoint-md) {
				right: -22%;
				top: -6%;
			  }
				img {
					width: 390px;
					@include smoothValue('width', 390px, 650px, $breakpoint-xs, $breakpoint-sm);
					@include smoothValue('width', 650px, 820px, $breakpoint-sm, $breakpoint-lg);
				}
			}
		}
	}
	&__image {
		position: absolute;
		z-index: -2;
		right: 0;
		top: -160px;
		margin-right: -175px;
		opacity: 0.4;
		width: auto;
		height: auto;
		@include min-screen($breakpoint-xs) {
			right: -15%;
			top: -120px;
			margin-right: 0;
		}
		@include min-screen($breakpoint-sm) {
			top: 0;
			right: -20%;
		}
		@include min-screen($breakpoint-lg) {
			right: -15%;
		}
		@include min-screen($breakpoint-fhd) {
			right: -5%;
		}
		img {
			width: 350px;
			@include smoothValue('width', 350px, 500px, $breakpoint-sm, $breakpoint-lg);
		}
	}
}
