.building-banner {
  margin-top: 19.5px;
  @include smoothValue('margin-top', 19.5px, 20.5px, $breakpoint-xs, $breakpoint-sm);
  @include smoothValue('margin-top', 20.5px, 25px, $breakpoint-sm, $breakpoint-lg);
  margin-bottom: 30px;
  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-xs, $breakpoint-sm);
  @include smoothValue('margin-bottom', 40px, 50px, $breakpoint-sm, $breakpoint-lg);
  @include min-screen($breakpoint-md) {
	margin-left: var(--container-indent);
	margin-right: var(--container-indent);
  }

  &__top {
	position: relative;
	z-index: 100;
	padding: 25px 15px 46px 15px;
	@include smoothValue('padding-left', 15px, 28px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-right', 15px, 28px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 28px, 65px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 28px, 65px, $breakpoint-sm, $breakpoint-lg);

	@include smoothValue('padding-top', 25px, 50px, $breakpoint-xs,$breakpoint-sm);
	@include smoothValue('padding-top', 50px, 65px, $breakpoint-sm,$breakpoint-md);

	@include smoothValue('padding-bottom', 46px, 38px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 38px, 61px, $breakpoint-sm, $breakpoint-lg);
	background-color: $c-light-beige;
	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: 0;
	  width: 100%;
	  height: 100%;
	  opacity: .4;
	  transform: scaleX(-1);
	  background: url("../images/backgrounds/waves.png") top left no-repeat;
	  background-size: contain;
	  mix-blend-mode: multiply;
	  z-index: 200;
	}
  }

  &__bg {
	position: absolute;
	z-index: 150;
	bottom: 0;
	right: -8%;
	@include smoothValue('right', -8%, 60%, $breakpoint-xxs, $breakpoint-sm);
	width: 90%;
	@include smoothValue('width', 90%, 100%, $breakpoint-xxs, $breakpoint-sm);
	height: 90%;
	@include smoothValue('height', 90%, 100%, $breakpoint-xxs, $breakpoint-sm);
	background: url("../images/backgrounds/building-bg_mobile.png");
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: contain;
	mix-blend-mode: multiply;
	@include min-screen($breakpoint-md) {
	  background-image: url("../images/backgrounds/building-bg.png");
	  right: 0;
	}
  }

  &__title {
	position: relative;
	z-index: 200;
	@extend .title-h1;
	line-height: 120%;
	//@include smoothValue('line-height', 48px, 57px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 15px;
	@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xs, $breakpoint-sm);
  }
  &__text {
	position: relative;
	z-index: 200;
	max-width: 600px;
	@include min-screen($breakpoint-lg) {
	  max-width: 785px;
	}

	font: 300 17px/1.5 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 17px, 19px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-xs, $breakpoint-sm);
  }
  &__tabs {
	border-top: 1px solid $c-dark-beige;
	background-color: $c-light-beige;
	padding: 19px 14px;
	@include min-screen($breakpoint-sm) {
	  padding-top: 20px;
	}
	@include smoothValue('padding-left', 14px, 29px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-right', 14px, 29px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 29px, 65px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 29px, 65px, $breakpoint-sm, $breakpoint-lg);
	overflow-x: auto;
	position: relative;

	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: 0;
	  height: 100%;
	  width: 55px;
	  background: linear-gradient(to left, $c-light-beige, rgba(255, 255, 255, 0));
	  @include min-screen($breakpoint-sm) {
		display: none;
	  }
	}
  }
  &__list {
	overflow-x: hidden;
	display: flex;
	flex-wrap: nowrap;
  }
  &__tab {
	position: relative;
	display: block;
	flex-shrink: 0;
	font-weight: 500;
	font-size: 12px;
	color: $c-dark;
	cursor: pointer;
	transition: color $default-transition-duration $default-transition-function;
	text-transform: uppercase;
	@include smoothValue('font-size', 12px, 15px, $breakpoint-xxs, $breakpoint-sm);

	&:not(:last-child) {
	  margin-right: 15px;
	  @include smoothValue('margin-right', 15px, 35px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-right', 35px, 50px, $breakpoint-sm, $breakpoint-md);
	}

	&::after {
	  position: absolute;
	  content: '';
	  left: 0;
	  bottom: 0;
	  width: 100%;
	  height: 4px;
	  background-color: #c4b197;
	  opacity: 0;
	}

	&:hover {
	  color: $c-burgundy;
	  &::after {
		opacity: 1;
	  }
	}
  }
}

.about-emc {
  margin-bottom: 30px;
  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-xs, $breakpoint-sm);
  @include smoothValue('margin-bottom', 40px, 60px, $breakpoint-sm, $breakpoint-md);

  &__title {
	color: $c-burgundy;
	font: 300 22px/1.3 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 22px, 36px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-md) {
	  margin-left: 30px;
	  font-weight: 250;
	}
	@include smoothValue('margin-left', 30px, 65px, $breakpoint-md, $breakpoint-lg);
	margin-top: 31px;
	@include smoothValue('margin-top', 31px, 40px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('margin-top', 40px, 60px, $breakpoint-sm, $breakpoint-md);
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 51px, $breakpoint-sm, $breakpoint-lg);
  }
  &__text {
	max-width: 785px;
	font: 400 14px/1.6 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-xs, $breakpoint-sm);

	@include min-screen($breakpoint-md) {
	  margin-left: 30px;
	}
	@include smoothValue('margin-left', 30px, 65px, $breakpoint-md, $breakpoint-lg);
	&:not(:last-child) {
	  margin-bottom: 15px;
	  @include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-md);
	}
  }
  &__icon {
	display: inline-block;
	margin-left: 5px;
	width: 10px;
	height: 11px;
  }
  &__gallery {
	margin-bottom: 41px;
	@include smoothValue('margin-bottom', 41px, 56px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 56px, 82px, $breakpoint-sm, $breakpoint-md);

	& .slider-gallery {
	  margin-top: 0;
	  &__wrap {
		max-width: 785px;
	  }
	}
	& .slider-gallery-main__nav:hover {
	  border-color: transparent;
	}
	& .slider-gallery-main__image::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: 0;
	  width: 100%;
	  height: 100%;
	  background-color: $c-beige;
	  mix-blend-mode: multiply;
	  opacity: .2;
	}
	& .slider-gallery__text {
	  text-align: center;
	}
  }
  &__info-section {
	@extend .container;
	@extend .section-mb;
	@include max-screen($breakpoint-sm) {
	  margin-bottom: 70px;
	}
  }
  &__info-link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px 15px 18px;
	@include smoothValue('padding-top', 15px, 31px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-top', 31px, 45px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('padding-bottom', 15px, 31px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 31px, 45px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('padding-left', 18px, 35px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-left', 35px, 65px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('padding-right', 20px, 40px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-right', 40px, 70px, $breakpoint-sm, $breakpoint-md);

	&:not(:last-child) {
	  margin-bottom: 30px;
	  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-xs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 40px, 60px, $breakpoint-sm, $breakpoint-md);
	}
	background-color: #f8f6f4;
	color: $c-burgundy;

	& span {
	  font: 300 22px/1.3 'Museo Sans Cyrl', sans-serif;
	  @include smoothValue('font-size', 22px, 36px, $breakpoint-sm, $breakpoint-lg);
	  @include min-screen($breakpoint-sm) {
		font-weight: 250;
	  }
	}
	& svg {
	  width: 39px;
	  height: 10px;
	  transform: translateX(0);
	  @extend .transition-move;
	  @include min-screen($breakpoint-xs) {
		margin-top: 15px;
	  }
	}
  }
  &__promo {
	margin-top: 25px;
	@include min-screen($breakpoint-sm) {
	  margin-top: 30px;
	}
  }
}
