.modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: none;
	width: 100vw;
	height: 100%;
	overflow: hidden;
	background: rgba(160, 156, 149, 0.6);
	backdrop-filter: blur(10px);
	pointer-events: all;
	&__content {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		overflow-y: auto;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@include min-screen($breakpoint-sm) {
		&__content {
			padding: 40px 30px;
		}
	}
}

.modal {
	$parent-selctor: &;
	position: relative;
	display: none;
	width: 100%;
	max-width: 100%;
	margin: auto;
	padding: 25px 15px;
	box-sizing: border-box;
	background: $c-white;
	&__close {
		position: absolute;
		right: 19px;
		top: 19px;
		width: 17px;
		height: 17px;
		cursor: pointer;
		svg {
			stroke: $c-burgundy;
			stroke-width: 2.5px;
		}
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			transform: translate(-50%, -50%);
		}
	}
	&__title {
		margin-bottom: 17px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 300;
		font-size: 20px;
		line-height: 1.2;
		color: $c-burgundy;
		& ~ {
			#{$parent-selctor} {
				&__button {
					margin-top: 34px;
				}
			}
		}
	}
	&__subtitle {
		margin-bottom: 26px;
		font-family: 'Uni Neue', sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.6;
	}
	&__button {
		.button {
			min-width: 124px;
			margin: 0 auto;
		}
	}
	&._message {
		width: 290px;
		padding: 60px 20px 55px 20px;
		text-align: center;
	}
	&._card-services {
	  	position: relative;
		//margin-left: 15px;
		//margin-right: 15px;
	  	width: 290px;
	  	padding: 45px 30px 20px 30px;
	  	background-image: url('../images/backgrounds/waves-left.png'), linear-gradient(#fff, #fff);
	  	background-size: initial;
	  	background-repeat: no-repeat;
	  	background-position: top right;
		background-blend-mode: multiply;
	  @include min-screen($breakpoint-sm) {
		width: 660px;
		padding: 60px 70px 50px 60px;
	  }
	}
  	&._accordion-birth-faq, &._compare-products, &._card-with-slider, &._card-detail, &._card-detail-doctor {
	  @include min-screen($breakpoint-lg) {
		width: 1150px;
		padding-left: 115px;
		padding-right: 115px;
	  }
	}
	@include max-screen($breakpoint-sm - 1) {
		&__subtitle {
			br {
				display: none;
			}
		}
		&__button {
			.button {
				font-size: 12px;
				height: 40px;
			}
		}
	}
	@include min-screen($breakpoint-sm) {
		width: 830px;
		padding: 41px 65px 50px 45px;
		&__close {
			right: 24px;
			top: 24px;
			width: 22px;
			height: 22px;
			svg {
				stroke-width: 2px;
			}
		}
		&__title {
			margin-bottom: 20px;
			font-size: 26px;
			line-height: 1.4;
			& ~ {
				#{$parent-selctor} {
					&__button {
						margin-top: 50px;
					}
				}
			}
		}
		&__subtitle {
			margin-bottom: 36px;
			font-family: 'Uni Neue Book', sans-serif;
			font-weight: normal;
			font-size: 18px;
		}
		&__button {
			.button {
				min-width: 153px;
			}
		}
		&._message {
			width: 660px;
			padding: 90px 85px 80px 85px;
		}
	}
	@include min-screen($breakpoint-md) {
		padding-left: 65px;
	}
}
