.breadcrumbs {
	margin-top: 15px;
	@include smoothValue('margin-top', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 20px, 25px, $breakpoint-sm, $breakpoint-lg);
	&__wrap {
		display: flex;
		align-items: center;
	}
	&__home {
		@include min-screen($breakpoint-xs) {
			display: none;
		}
		svg {
			width: 24px;
			height: 24px;
		}
	}
	&__list {
		display: flex;
		padding: 0;
		margin: 0 0 0 -10px;
		@include max-screen($breakpoint-xs - 1px) {
			margin-left: 0;
		}
		//@include min-screen($breakpoint-sm) {
		//	margin-right: -15px;
		//}
	}
	&__item {
		display: flex;
		align-items: baseline;
		margin-left: 10px;
		font-size: 12px;
		white-space: nowrap;
		color: $c-dark-gray;
		@include max-screen($breakpoint-xs - 1px) {
			&:first-child,
			&:not(:nth-last-child(2)) {
				display: none;
			}
		}
		@include smoothValue('font-size', 12px, 14px, $breakpoint-sm, $breakpoint-lg);

		@include min-screen($breakpoint-sm) {
			margin-left: 15px;
		}
		& + & {
			&::before {
				content: '';
				display: inline-block;
				width: 4px;
				height: 8px;
				margin-right: 10px;
				background: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L5 4.5L1 8.5' stroke='%23333333'/%3E%3C/svg%3E%0A")
					no-repeat 0 0;
				background-size: contain;
				@include min-screen($breakpoint-sm) {
					margin-right: 15px;
				}
			}
		}

		a {
			display: inline-block;
			color: $c-burgundy;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			@include min-screen($breakpoint-lg) {
				max-width: initial;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
