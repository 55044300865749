.article-author {
	display: flex;
	align-items: center;
	margin-top: 20px;
	@include smoothValue('margin-top', 20px, 25px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 25px, 35px, $breakpoint-sm, $breakpoint-lg);
	&__photo {
		width: 60px;
		height: 60px;
		flex-shrink: 0;
		@include smoothValue('width', 60px, 100px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 60px, 100px, $breakpoint-xxs, $breakpoint-sm);
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__info {
		padding-left: 10px;
	}
	&__name {
		font-family: 'Museo Sans Cyrl', sans-serif;
		color: $c-burgundy;
		font-weight: 100;
		font-size: 18px;
		@include smoothValue('font-size', 18px, 26px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__position {
		margin-top: 5px;
		text-transform: uppercase;
		color: $c-dark-gray;
		font-size: 9px;
		max-width: 300px;
		@include min-screen($breakpoint-sm) {
			margin-top: 10px;
		}
		@include smoothValue('font-size', 9px, 12px, $breakpoint-xxs, $breakpoint-sm);
	}
}
