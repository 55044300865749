.offers {
	margin-top: 36px;
	&__wrap {
		@include scrollbar() {
			display: none;
			width: 0;
			background: transparent;
		}
		position: relative;
		padding-top: 0;

		&::before {
			position: absolute;
			content: '';
			z-index: -1;
			left: calc(-1 * var(--container-indent));
			top: 0;
			width: calc(100% + 2 * var(--container-indent));
			height: 100%;
			@include min-screen($breakpoint-xs) {
				background: $c-light-beige url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
			}
		}

		@include min-screen($breakpoint-xs) {
			padding-bottom: 50px;
			padding-top: 64px;
		}
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding-left;

			&::before {
				left: 0;
			}
		}
		@include smoothValue('padding-right', 15px, 30px, $breakpoint-md, $breakpoint-lg);
		@include min-screen($breakpoint-lg) {
			padding-bottom: 80px;
		  	@include smoothValue('padding-right', 30px, 75px, $breakpoint-lg, $breakpoint-fhd);
		}
		@include smoothValue('padding-top', 64px, 50px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('padding-top', 50px, 75px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('padding-top', 75px, 85px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__title {
		@extend .title-h3;
		margin-bottom: 15px;
		color: $c-burgundy;
		@include min-screen($breakpoint-xs) {
			margin-bottom: 42px;
		}
		@include min-screen($breakpoint-md) {
			margin-bottom: 32px;
		}
		@include smoothValue('margin-bottom', 40px, 56px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__outer {
		overflow: hidden;
	}
	&__list {
		@include min-screen($breakpoint-xs) {
			display: flex;
			overflow-x: auto;
			overflow-y: visible;
			margin-left: calc(-1 * var(--container-indent));
			margin-right: calc(-1 * var(--container-indent));
			//padding-left: var(--container-indent);
		}
		@include mq($breakpoint-xs, $breakpoint-md - 1px) {
			._ff & {
				padding-bottom: 16px;
				margin-bottom: -16px;
			}
		}
		@include min-screen($breakpoint-sm) {
			--gutter: 10px;
			margin-left: calc(-1 * var(--gutter) / 2);
			margin-right: calc(-1 * var(--gutter) / 2);
		}
		@include min-screen($breakpoint-md) {
			--gutter: 15px;
			overflow: visible;
			padding-left: 0;
			//margin-left: 0;
			//margin-right: 0;
		}
		@include min-screen($breakpoint-lg) {
			justify-content: space-between;
			margin-left: 0;
			margin-right: 0;
		}
		@include smoothValue('--gutter', 30px, 75px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__item {
		text-align: center;
		&:last-child {
			@include min-screen($breakpoint-xs) {
				.offers__item-wrap {
					margin-right: var(--container-indent);
				}
			}
			@include min-screen($breakpoint-md) {
				.offers__item-wrap {
					margin-right: 0;
				}
			}
		}
		&-wrap {
			display: block;
			background-color: $c-light-beige;
			padding: 20px 12px 22px;
			height: 100%;
			@extend .transition-active;

			@include min-screen($breakpoint-xs) {
				height: 100%;
				box-sizing: border-box;
				background-color: #ece7e2;
			}
			@include min-screen($breakpoint-sm) {
				padding: 35px 15px 18px 14px;
			}
			@include min-screen($breakpoint-md) {
			  padding: 30px 13px 18px 11px;
			}
			@include min-screen($breakpoint-lg) {
				padding: 30px 42px;
				width: 100%;
			}
		  @include min-screen($breakpoint-fhd) {
			padding: 47px 55px;
		  }

			._desktop &:hover {
				background-color: $c-white;
				.offers__name {
					color: $c-beige;
					text-decoration: underline;
				}
			}
		}
		@include max-screen($breakpoint-xs - 1px) {
			& + & {
				margin-top: 20px;
			}
		}

		@include min-screen($breakpoint-xs) {
			width: 320px;
			margin-top: 0;
			flex-shrink: 0;
			padding-left: calc(var(--col-gutter) / 2);
			padding-right: calc(var(--col-gutter) / 2);
		}
		@include min-screen($breakpoint-sm) {
			padding-left: calc(var(--gutter) / 2);
			padding-right: calc(var(--gutter) / 2);
		}
		@include min-screen($breakpoint-md) {
			width: 1/3 * 100%;
			box-sizing: border-box;
		}
		@include min-screen($breakpoint-lg) {
			box-sizing: content-box;
			padding-left: 0;
			padding-right: 0;
		}
		@include smoothValue('width', 395px, 500px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__name {
		font-size: 18px;
		line-height: 1.4;
		font-weight: 300;
		font-family: 'Museo Sans Cyrl', sans-serif;
		color: $c-burgundy;
		margin-top: 20px;
		@extend .transition-active;

	  	@include min-screen($breakpoint-sm) {
		  margin-top: 23px;
		}
	  @include min-screen($breakpoint-md) {
		margin-top: 28px;
	  }
		@include min-screen($breakpoint-lg) {
			font-size: 20px;
		  	line-height: 1.25;
			margin-top: 23px;
		}
	  	@include smoothValue('font-size', 20px, 26px, $breakpoint-lg, $breakpoint-fhd);
	  	@include min-screen($breakpoint-fhd) {
		  margin-top: 20px;
		}
	}
	&__icon {
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		@include smoothValue('width', 80px, 120px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__value {
		font-family: 'Kudryashev Headline', sans-serif;
		font-weight: 500;
		font-size: 95px;
		line-height: 1;
		color: $c-beige;
		margin-top: 26px;
		sup {
			font-size: 0.5em;
		}

		@include smoothValue('margin-top', 39px, 46px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('margin-top', 14px, 16px, $breakpoint-md, $breakpoint-lg);
	}
	&__desc {
		color: $c-burgundy;
		font-size: 12px;
		@include smoothValue('font-size', 12px, 14px, $breakpoint-lg, $breakpoint-fhd);
	}
	&__text {
		margin-top: 24px;
		font-size: 14px;
		line-height: 1.6;
		font-family: 'Uni Neue Book', sans-serif;
		@include smoothValue('font-size', 14px, 16px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 16px, 18px, $breakpoint-lg, $breakpoint-fhd);
	  	@include screen($breakpoint-md, $breakpoint-fhd - 1px) {
		  margin-top: 30px;
		}
	}
	&__link {
		margin-top: 45px;
		@include smoothValue('margin-top', 25px, 60px, $breakpoint-md, $breakpoint-lg);
	  	@include smoothValue('margin-top', 60px, 55px, $breakpoint-lg, $breakpoint-fhd);
		&._show-desktop {
			display: none;
			@include min-screen($breakpoint-sm) {
				display: block;
				text-align: center;
			}
		}
	}

  	&_two {
	  	.offers__wrap {
		  	@include smoothValue('padding-left', 45px, 65px, $breakpoint-md, $breakpoint-lg);
		  	@include smoothValue('padding-right', 45px, 150px, $breakpoint-md, $breakpoint-lg);
			@include smoothValue('padding-left', 65px, 95px, $breakpoint-lg, $breakpoint-fhd);
			@include smoothValue('padding-right', 150px, 210px, $breakpoint-lg, $breakpoint-fhd);
		}

	  	.offers__list {
			@include min-screen($breakpoint-sm) {
			  	padding-left: 10px;
			}
		  	@include min-screen($breakpoint-md) {
			  	padding-left: 0;
			  	justify-content: space-between;
			}
		  	@include smoothValue('padding-left', 0px, 85px, $breakpoint-md, $breakpoint-lg);
		  	@include smoothValue('padding-left', 85px, 230px, $breakpoint-lg, $breakpoint-fhd);
		}

		@include min-screen($breakpoint-xs) {
			.offers__item:last-child .offers__item-wrap {
			  	margin-right: 0;
			}
		}

	  	.offers__item {
		  	@include min-screen($breakpoint-md) {
			  	width: 375px;
			  	padding: 0;
			}
		  	@include smoothValue('width', 375px, 425px, $breakpoint-md, $breakpoint-lg);
		  	@include smoothValue('width', 425px, 480px, $breakpoint-lg, $breakpoint-fhd);
		}
	}

  	&_single {
	  	.offers__item {
		  	@include min-screen($breakpoint-xs) {
			  	padding: 0;
			  	margin: 0 auto;
			}
		  	@include min-screen($breakpoint-sm) {
			  	height: 285px;
			  	width: 100%;
			  	margin: 0;
			}
		  	@include smoothValue('height', 285px, 240px, $breakpoint-md, $breakpoint-lg);
		}
	  	.offers__item-wrap {
			@include min-screen($breakpoint-sm) {
			  	display: flex;
			  	flex-direction: column;
			  	flex-wrap: wrap;
			  	justify-content: center;
			  	padding: 52px 10px 44px 23px;
				@include smoothValue('padding-left', 23px, 80px, $breakpoint-sm, $breakpoint-md);
				@include smoothValue('padding-right', 10px, 70px, $breakpoint-sm, $breakpoint-md);
				@include smoothValue('padding-left', 80px, 70px, $breakpoint-md, $breakpoint-lg);
				@include smoothValue('padding-left', 80px, 156px, $breakpoint-lg, $breakpoint-fhd);
				@include smoothValue('padding-right', 70px, 110px, $breakpoint-lg, $breakpoint-fhd);
			}
		}
	  	.offers__intro {
		  	@include min-screen($breakpoint-sm) {
			  	display: flex;
			  	flex-direction: column;
			  	align-items: center;
			}
		  	@include min-screen($breakpoint-md) {
			  	flex-direction: row-reverse;
			  	justify-content: space-between;
			  	flex-grow: 2;
			  	margin-right: 35px;
			}
		  	@include min-screen($breakpoint-lg) {
			  	flex-grow: 1;
			  	margin-right: 0;
			}
		}
	  	.offers__icon {
		  	@include min-screen($breakpoint-sm) {
			  width: 110px;
			  margin: 0;
			}
			@include min-screen($breakpoint-md) {
			  width: 160px;
			}
		  	@include min-screen($breakpoint-lg) {
			  	margin-right: 40px;
			}
			@include min-screen($breakpoint-fhd) {
			  margin-right: auto;
			}
		}
	  	.offers__name {
		  	@include min-screen($breakpoint-sm) {
			  	max-width: 265px;
			  	font-size: 20px;
			}
		  	@include min-screen($breakpoint-md) {
				max-width: 236px;
			  	margin: 0;
				text-align: left;
			}
		  	@include min-screen($breakpoint-lg) {
			  	font-size: 26px;
			  	max-width: 426px;
			}
			@include min-screen($breakpoint-fhd) {
			  margin-right: 70px;
			}
		}
	  	.offers__value {
		  	@include min-screen($breakpoint-sm) {
			  	margin-top: 0;
			}
		  	@include min-screen($breakpoint-lg) {
			  	margin-right: 40px;
			}
		  	@include smoothValue('margin-right', 40px, 50px, $breakpoint-lg, $breakpoint-fhd);
		  	@include min-screen($breakpoint-fhd) {
			  	margin-left: auto;
			}
		}
	  	.offers__desc {
			@include min-screen($breakpoint-lg) {
			  	margin-right: 40px;
			}
		  	@include smoothValue('margin-right', 40px, 50px, $breakpoint-lg, $breakpoint-fhd);
		  	@include min-screen($breakpoint-fhd) {
			  	width: 170px;
			  	margin-left: auto;
			}
		}
	  	.offers__text {
		  	@include min-screen($breakpoint-lg) {
			  	text-align: left;
			  	margin-top: 0;
			}
		}
	}
}
