:root {
	--container-indent: 15px;
	--col-gutter: #{$indent-col-xs};
	@include min-screen($breakpoint-sm) {
		--container-indent: 30px;
	}
	@include min-screen($breakpoint-md) {
		--container-indent: 40px;
		--col-gutter: #{$indent-col-md};
	}
	@include min-screen($breakpoint-lg) {
		--container-indent: 50px;
		--col-gutter: #{$indent-col-lg};
	}
}

@mixin desktop-container-padding-left {
	padding-left: calc((100% - 15 * var(--col-gutter)) / 16);

	@include min-screen($breakpoint-sm) {
		&::before {
			transition: transform 0.4s ease-in-out;
			transition-delay: 0.15s;
			transform: translateX(30px);
		}
		&._animated::before {
			transform: translateX(0px);
		}
	}
}
@mixin desktop-container-padding-right {
	padding-right: calc((100% - 15 * var(--col-gutter)) / 16);
}

@mixin desktop-container-padding {
	@include desktop-container-padding-left;
	@include desktop-container-padding-right;
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	//overflow: hidden;
	opacity: 1;
	@extend .transition-move;

	@include max-screen($breakpoint-md) {
		overflow: hidden;
	}

	.main-content {
		flex-grow: 1;
		position: relative;
	}

	._loading & {
		opacity: 0;
	}
}

.container {
	max-width: 1920px;
	margin: auto;
	box-sizing: content-box;
	padding-left: var(--container-indent);
	padding-right: var(--container-indent);
}

._ie {
	.container {
		padding-left: 50px;
		padding-right: 50px;
	}
}

.strait-container {
	@extend .container;
	max-width: 745px;
}

.container-with-aside {
	&._article {
		margin-top: 35px;
		@include smoothValue('margin-top', 35px, 40px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 40px, 50px, $breakpoint-sm, $breakpoint-lg);

		.slider-gallery {
			.container {
				padding: 0;
			}
			&__wrap {
				max-width: initial;
			}
		}
		.slider-gallery-main__arrows {
			@include min-screen($breakpoint-md) {
				display: none;
			}
		}
	}

	&__title-wrap {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding-left;
		}
		.title-link {
			@include max-screen($breakpoint-xs - 1px) {
				width: 100%;
			}
		}
	}
	&__wrap {
		position: relative;
		display: flex;
		flex-wrap: wrap-reverse;
		justify-content: space-between;

		&:not(._without-side-padding) {
			@include min-screen($breakpoint-lg) {
				@include desktop-container-padding;
			}
		}
		&._border-top {
			@include min-screen($breakpoint-sm) {
				border-top: 1px solid $c-light-beige;
				padding-top: 20px;
			}
		}
	}
	&__main {
		width: 100%;
		@include min-screen($breakpoint-md) {
			@include widthInContainer(9);
		}
	}
	&__aside {
		display: block;
		@include min-screen($breakpoint-md) {
			display: block;
			align-items: flex-end;
			@include widthInContainer(4);
		}
		&._always-show {
			display: block;
			@include max-screen($breakpoint-md - 1px) {
				margin-top: 30px;
				width: 100%;
			}
			@include max-screen($breakpoint-xs - 1px) {
				margin-top: 20px;
			}
		}
		&._reverse {
			@include max-screen($breakpoint-md - 1px) {
				margin-top: 0;
				order: -1;
			}
		}
		&._mt-0 {
			margin-top: 0;
		}
	}
	&__sticky {
		position: sticky;
	  	z-index: 20;
		top: 100px;
		width: 100%;
		padding-bottom: 32px;
	}
	&__more {
		margin-top: 48px;
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			text-align: center;
		}
	}
}
