.article-rating {
	padding-top: 20px;
	padding-bottom: 20px;
	@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 30px, 40px, $breakpoint-md, $breakpoint-sm);
	@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 30px, 40px, $breakpoint-md, $breakpoint-sm);
	&__title {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		text-align: center;
		font-size: 20px;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	}
	&__text {
		font-size: 14px;
		font-family: 'Uni Neue Book', sans-serif;
		text-align: center;
		.g-bold {
			font-family: 'Uni Neue', sans-serif;
		}
	}
	&__stars {
		display: flex;
		justify-content: center;
		margin-top: 15px;
		margin-bottom: 15px;
		svg {
		}
	}
	&__star {
		color: transparent;
		cursor: pointer;
		path {
			@extend .transition-active, .transition-move;
		}
		&._filled svg {
			.article-rating__stars:not(._voting) & {
				fill: $c-beige;
				color: $c-beige;
			}
			._voting & {
				color: $c-burgundy;
			}
		}
		&._half {
			color: $c-beige;
			.half {
				display: block;
			}
			._voting & .half {
				opacity: 0;
			}
		}
		._voting & {
			.stroke {
				stroke: $c-burgundy;
			}
		}
		&._hover svg {
			fill: $c-burgundy;
		}
		.half {
			display: none;
		}
		input {
			display: none !important;
			visibility: hidden !important;
		}
	}
	&__result {
		display: flex;
		justify-content: center;
		align-items: baseline;
		font-family: 'Uni Neue Book', sans-serif;
		text-align: center;
		font-size: 14px;
		margin-top: 5px;
		.g-bold {
			font-family: 'Uni Neue', sans-serif;
		}
	}
	&__cancel {
		@extend %reset-Button;
		position: relative;
		margin-left: 10px;
		color: $c-burgundy;
		border-bottom: 1px dashed $c-burgundy;
		&::before {
			position: absolute;
			content: '';
			left: -5px;
			width: 1px;
			height: 100%;
			background-color: $c-light-beige;
		}
	}
}
