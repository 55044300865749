.program-unifying-list {
	margin-top: 30px;
	& + & {
		margin-top: 15px;
		@include smoothValue('margin-top', 15px, 40px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 40px, 60px, $breakpoint-sm, $breakpoint-lg);
	}
	&__title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 25px;
		@include min-screen($breakpoint-sm) {
			margin-bottom: 30px;
		}
	}
	&__card {
		& + & {
			margin-top: 15px;
			@include min-screen($breakpoint-sm) {
				margin-top: 20px;
			}
			@include min-screen($breakpoint-md) {
				margin-bottom: 30px;
			}
		}
	}
}

.program-unifying-detail-info {
	position: relative;
	padding: 20px;
	@include waves;
	@include min-screen($breakpoint-sm) {
		padding: 25px;
	}
	&::before {
		background-color: $c-light-beige;
	}
	&.g-hidden-md {
		margin-top: 30px;
	}
	.link-with-arrow {
		display: inline;
		font-size: 14px;
		line-height: 1.45;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-lg);
		svg {
			display: inline;
			vertical-align: middle;
		}
	}

	&._small-form {
		.title {
			font-size: 18px;
		}

		.form {
			.form__group {
				&._tel {
					margin-top: 20px;
				}
				&._policy {
					max-width: inherit;
					margin-bottom: 20px;

					.form-checkbox__label {
						font-size: 14px;
					}
				}
			}
			.form__button {
				width: fit-content;
				margin: auto;
			}
		}
	}
}

