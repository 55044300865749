.title-link {
	$block: &;
	position: relative;
	margin-top: 20px;
	overflow: visible;
	@include smoothValue('margin-top', 20px, 25px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 25px, 35px, $breakpoint-sm, $breakpoint-lg);

  	&:last-child {
	  @include large-section-mb;
	}

	.g-delimeter {
		margin-top: 20px;
		@include min-screen($breakpoint-md) {
			margin-top: 24px;
		}
	}
	&._article-title {
		margin-top: 0;
		padding-bottom: 5px;
		margin-bottom: -5px;
		svg {
			left: auto;
			right: 100%;
			top: 0.3em;
			@include max-screen($breakpoint-lg - 1px) {
				display: none;
			}
		}
	}
	&._news-title {
		margin-top: 35px;
		@include smoothValue('margin-top', 35px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 38px, 50px, $breakpoint-sm, $breakpoint-lg);
	}
	&._with-mb {
		margin-bottom: 20px;
		@include min-screen($breakpoint-md) {
			margin-bottom: 40px;
		}
	}
	&._with-pb {
		padding-bottom: 5px;
		margin-bottom: -5px;
	}
	&._sm-no-arrow {
		@include max-screen($breakpoint-md) {
			svg {
				display: none;
			}
		}
	}
	&__wrap {
		position: relative;
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__container {
		position: relative;
		@include min-screen($breakpoint-sm) {
			display: flex;
		}
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
		}
	}
	&__price {
	}
	&__element {
		display: inline-flex;
		align-items: baseline;
		color: $c-burgundy;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 23px;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
		&:hover {
			svg {
				transform: translateX(-5px);
				@include min-screen($breakpoint-md) {
					transform: translateX(-10px);
				}
			}
		}
		@include max-screen($breakpoint-xs - 1px) {
			pointer-events: none;
		}
		svg {
			margin-right: 5px;
			padding-bottom: 0.2em;
			top: 0.3em;
			@extend .transition-move;
			@include smoothValue('margin-right', 5px, 15px, $breakpoint-xxs, $breakpoint-sm);
			@include min-screen($breakpoint-lg) {
				position: absolute;
				left: 0;
			    top: 22px;
			}
			@include max-screen($breakpoint-xs - 1px) {
				display: none;
			}
		}
		h1 {
			font-size: inherit;
			line-height: 1;
		  	font-weight: normal;
		}
	}

	&__date {
		font-family: 'Uni Neue Book', sans-serif;
		color: $c-dark-gray;
		font-size: 12px;
		margin-top: 10px;
		@include smoothValue('font-size', 12px, 14px, $breakpoint-sm, $breakpoint-lg);
		@include max-screen($breakpoint-sm - 1px) {
			margin-top: 5px;
		}
	}

	&__text {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-size: 17px;
		font-weight: 100;
		margin-top: 20px;
		color: $c-dark;
		max-width: 785px;
		@include smoothValue('font-size', 17px, 19px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}
		@include min-screen($breakpoint-md) {
			font-weight: 300;
		}
		@include min-screen($breakpoint-lg) {
			margin-top: 40px;
		}
	}
	&__button {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
		}
		@include min-screen($breakpoint-md) {
			position: absolute;
			right: 0;
			top: 0;
			margin-top: 0;
		}
		.button {
			@include max-screen($breakpoint-xs - 1px) {
				width: 100%;
			}
			@include min-screen($breakpoint-xs) {
				width: 280px;
			}
		}
	}
}

.title-link-price {
	margin-top: 20px;
	@include min-screen($breakpoint-sm) {
		margin-top: 0;
		display: flex;
		flex: 0 0 auto;
		margin-left: auto;
	}
	&__price {
		flex: 0 0 auto;
		@include min-screen($breakpoint-sm) {
			padding-right: 20px;
			padding-left: 20px;
		}
		@include smoothValue('padding-left', 20px, 120px, $breakpoint-sm, $breakpoint-lg);
	}
	&__desc {
		color: $c-beige;
		font-size: 12px;
		text-transform: uppercase;
	}
	&__value {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 32px;
		line-height: 1;
		color: $c-burgundy;
		@include smoothValue('font-size', 32px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
	}
	&__buttons {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 0;
		}
	}
	&__button {
		width: 100%;
		@include min-screen($breakpoint-xs) {
			width: 210px;
			font-size: 14px;
		}
		@include smoothValue('width', 210px, 260px, $breakpoint-sm, $breakpoint-lg);
		& + & {
			margin-top: 10px;
		}
	}
}
