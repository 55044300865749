.form-radio {
	$parent-selector: &;
	@include common-form-checkbox-radio;
	&__input {
		&:checked {
			& ~ #{$parent-selector} {
				&__box {
					background: $c-beige;
				}
			}
		}
	}
	&__box {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: $c-beige-form;
		transition: background 0.2s ease-out;
	}
	&__icon {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: $c-white;
		transition: opacity 0.2s ease-out;
	}
	&__label {
		padding-left: 10px;
		padding-top: 7px;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.2;
		color: #1b1b1b;
	}
	&._column {
		flex-direction: column-reverse;
		#{$parent-selector} {
			&__label {
				padding-left: 0;
				padding-top: 0;
				margin-bottom: 3px;
				font-family: 'Museo Sans Cyrl', sans-serif;
				font-weight: 100;
				font-size: 20px;
				line-height: 1.25;
				text-align: center;
			}
		}
	}
	@include min-screen($breakpoint-sm) {
		&__label {
			padding-top: 0;
			font-size: 18px;
			line-height: 1.6;
		}
	}
}
