.ticket {
	$block: &;
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	@include min-screen($breakpoint-sm) {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	@include min-screen($breakpoint-lg) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	&._aside {
		margin-top: 0;
		#{$block} {
			&__main,
			&__info {
				@include min-screen($breakpoint-md) {
					padding-top: 30px;
					padding-bottom: 24px;
				}
			}
			&__title {
				@include min-screen($breakpoint-md) {
					font-size: 23px;
					line-height: 1.15;
				}
			}
			&__value {
				@include min-screen($breakpoint-md) {
					font-size: 72px;
				}
			}
			&__text._book-fs {
				font-weight: 300;
			}
			&__icon {
				@include min-screen($breakpoint-md) {
					width: 100px;
					height: 100px;
				}
			}
		}
	}
	&__wrap {
		@include waves;
		display: flex;
		flex-wrap: wrap;

		&::before {
			background-color: $c-light-beige;
		}
	}
	&__main {
		width: 100%;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
		@include min-screen($breakpoint-sm) {
			width: 60%;
		}
		@include min-screen($breakpoint-md) {
			padding: 34px;
		}
		#{$block}._aside & {
			@include min-screen($breakpoint-md) {
				width: 100%;
			}
		}
	}
	&__info {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 20px;
		box-sizing: border-box;
		@include min-screen($breakpoint-md) {
			padding: 34px;
		}
		@include min-screen($breakpoint-sm) {
			width: 40%;
		}
		#{$block}._aside & {
			@include min-screen($breakpoint-md) {
				width: 100%;
			}
		}
	}
	&__title {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 20px;
		margin-top: 10px;
		@include smoothValue('font-size', 20px, 28px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 28px, 30px, $breakpoint-sm, $breakpoint-lg);

		span {
			display: block;
			color: $c-burgundy;
		}
	}
	&__icon {
		width: 100px;
		height: 100px;
		margin-left: auto;
		margin-right: auto;
		@include min-screen($breakpoint-sm) {
			width: 134px;
			height: 134px;
		}
		svg {
			width: 100%;
		}
	}
	&__value {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 72px;
		line-height: 1;
		color: $c-beige;
		text-align: center;
		@include smoothValue('font-size', 72px, 94px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 94px, 110px, $breakpoint-sm, $breakpoint-lg);

		sup {
			font-size: 0.5em;
		}
	}
	&__desc {
		@extend .small-text;
		color: $c-burgundy;
		text-align: center;
	}
	&__btn {
		width: 100%;
		height: 40px;
		margin-top: 10px;
		font-size: 12px;
		@include max-screen($breakpoint-sm - 1px) {
			order: 1;
		}
		@include min-screen($breakpoint-sm) {
			width: 144px;
			margin-left: auto;
			margin-right: auto;
		}
		@include min-screen($breakpoint-lg) {
		}
	}
	&__text {
		@extend .small-text;
		&._main {
			max-width: 220px;
			margin-top: 8px;
			@include min-screen($breakpoint-lg) {
				max-width: 320px;
			}
		}
		&._book-fs {
			margin-top: 8px;
			max-width: 270px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}
	&__line {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		border-top: 1px dashed #d7d7d7;
		@include min-screen($breakpoint-sm) {
			width: 0;
			height: 100%;
			border-left: 1px dashed #d7d7d7;
			border-top: none;
		}
		#{$block}._aside & {
			@include min-screen($breakpoint-md) {
				width: 100%;
				height: 0;
				border-left: none;
				border-top: 1px dashed #d7d7d7;
			}
		}
		&::before {
			transform: translateY(-50%);
			position: absolute;
			z-index: 2;
			left: 0;
			content: '';
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-right: 10px solid transparent;
			border-left: 8px solid $c-white;
			@include min-screen($breakpoint-sm) {
				top: -1px;
				border-left-width: 10px;
				border-left-color: transparent;
				border-top-width: 8px;
				border-top-color: $c-white;
				transform: translate(-50%, 0);
			}
			#{$block}._aside & {
				@include min-screen($breakpoint-md) {
					transform: translateY(-50%);
					position: absolute;
					z-index: 2;
					left: 0;
					content: '';
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-right: 10px solid transparent;
					border-left: 8px solid $c-white;
				}
			}
		}
		&::after {
			position: absolute;
			content: '';
			z-index: 2;
			right: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid transparent;
			border-right: 8px solid $c-white;
			transform: translateY(-50%);
			@include min-screen($breakpoint-sm) {
				bottom: -2px;
				border-right-width: 10px;
				border-right-color: transparent;
				border-bottom-width: 8px;
				border-bottom-color: $c-white;
				transform: translate(50%, 0);
			}
			#{$block}._aside & {
				@include min-screen($breakpoint-md) {
					position: absolute;
					content: '';
					z-index: 2;
					right: 0;
					bottom: auto;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-left: 10px solid transparent;
					border-right: 8px solid $c-white;
					transform: translateY(-50%);
				}
			}
		}
	}
}
