.button {
	@extend %reset-Button;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $c-burgundy;
	color: $c-white;
	padding-left: 1rem;
	padding-right: 1rem;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	@extend .transition-active;
	&._beige {
		background-color: $c-beige;
		color: $c-white;
	}
}
