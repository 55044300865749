.link-with-arrow {
	display: inline-flex;
	align-items: center;
	font-size: 18px;
	line-height: 1;
	font-family: 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 18px, 20px, $breakpoint-lg, $breakpoint-fhd);

	span {
		text-decoration: underline;
	}

	svg {
		width: 23px;
		height: 17px;
		margin-left: 12px;
		color: $c-burgundy;
		transform: translateX(0);
		@extend .transition-move;
	}

	&:hover {
		span {
			color: currentColor;
			text-decoration: none;
		}
		svg {
			transform: translateX(10px);
		}
	}
	&._small {
		font-size: 14px;
		@include min-screen($breakpoint-md) {
			font-size: 18px;
		}
	}
}
