.article-text {
  	position: relative;
	margin-top: 20px;
	overflow: visible;
	font-size: 14px;
	@include min-screen($breakpoint-sm) {
		font-size: 16px;
	}
	@include min-screen($breakpoint-md) {
		margin-top: 50px;
	}
	.g-delimeter._with-margin {
		margin-top: 25px;
		margin-bottom: 25px;
		@include min-screen($breakpoint-md) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	&._mt-0 {
		margin-top: 0;
	}
	&__section {
		margin-top: 20px;
	}
	&__title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 10px;
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}

		* + & {
			margin-top: 20px;
			@include min-screen($breakpoint-sm) {
				margin-top: 30px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 40px;
			}
		}
		.g-delimeter + & {
			@include min-screen($breakpoint-sm) {
				margin-top: 25px;
			}
			@include min-screen($breakpoint-md) {
				margin-top: 30px;
			}
		}
	}
	&__subtitle {
		font-size: 20px;
		line-height: 1.4;
		color: $c-burgundy;
		margin-bottom: 10px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}
		* + & {
			margin-top: 20px;
			@include min-screen($breakpoint-sm) {
				margin-top: 30px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 40px;
			}
		}
	}
	&__big {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-size: 17px;
		font-weight: 300;
		@include smoothValue('font-size', 17px, 19px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-lg);
		@include max-screen($breakpoint-xs) {
			font-weight: 100;
		}
		p + p {
			margin-top: 1em;
		}
		&._with-mb {
			margin-bottom: 10px;
			@include min-screen($breakpoint-sm) {
				margin-bottom: 15px;
			}
			@include min-screen($breakpoint-md) {
				margin-bottom: 20px;
			}
		}
	}
	&__text {
		margin-bottom: 10px;
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}

		&._book {
			font-family: 'Uni Neue Book', sans-serif;
			font-size: 16px !important;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		p + p {
			margin-top: 1.6em;
		}

	  	img {
		  //width: 100%;
		}
	}
	&__list {
	  	font-family: 'Uni Neue Book', sans-serif;
		margin-bottom: 10px;
	  	@include max-screen($breakpoint-sm - 1px) {
			li {
				padding-left: 16px;
				&::before {
					left: 0;
				}
			}
		}
		@include min-screen($breakpoint-md) {
			margin-bottom: 20px;
		}
		&._col-2 {
			@include min-screen($breakpoint-sm) {
				columns: 2;
			}
		}
	}

  &__link-to-top {
	display: none;
	position: absolute;
	left: -65px;
	bottom: -100px;
	cursor: pointer;
	transform: none;
	transition: all $default-transition-duration $default-transition-function;

	& svg {
	  width: 8px;
	  height: 95px;
	}

	@include min-screen($breakpoint-lg) {
	  display: block;
	}

	&:hover {
	  transform: translateY(-15px);
	}
  }
}
@include max-screen($breakpoint-sm) {
	table.one-two-column > tbody > tr {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		> td:nth-child(odd) {
			width: 30% !important;
		}
		> td:nth-child(even) {
			width: 70% !important;
		}
	}
}