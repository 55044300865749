.search-result {
	&__list {
		padding-top: 8px;
	}
	&__item {
		padding-bottom: 25px;
		margin-bottom: 25px;
		border-bottom: 1px solid $c-light-beige;
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}
	}
	&__title {
		margin-bottom: 10px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		font-size: 20px;
		line-height: 1.2;
	}
	&__link {
		@extend .animated;
		color: $c-burgundy;
		._desktop &:hover {
			color: $c-brown;
		}
	}
	&__video-col {
		width: 100%;
		padding-top: 5px;
		margin-bottom: 15px;
	}
	&__text-col {
		width: 100%;
	}
	&__description {
		margin-bottom: 11px;
		font-family: 'Uni Neue Book', sans-serif;
		font-weight: normal;
		font-size: 14px;
		line-height: 1.6;
		b,
		strong {
			font-family: 'Uni Neue', sans-serif;
			font-weight: 500;
		}
	}
	&__category {
		font-family: 'Uni Neue', sans-serif;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.3;
		text-transform: uppercase;
		color: $c-dark-gray;
	}
	&__recommendations {
		margin-bottom: 50px;
	}
	&__recommendations-title {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: normal;
		font-size: 18px;
		line-height: 1.22;
		margin-bottom: 23px;
	}
	&__recommendations-item {
		margin-bottom: 22px;
		font-family: 'Uni Neue Book', sans-serif;
		font-weight: normal;
		font-size: 18px;
		line-height: 1.6;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: '•';
			margin-right: 5px;
		}
	}
	@include min-screen($breakpoint-sm) {
		&__list {
			padding-top: 0;
		}
		&__item {
			padding-bottom: 40px;
			margin-bottom: 30px;
		}
		&__title {
			margin-bottom: 20px;
			font-size: 26px;
			line-height: 1.4;
		}
		&__info {
			flex-wrap: nowrap;
			margin-left: -10px;
			margin-right: -10px;
		}
		&__video-col,
		&__text-col {
			width: auto;
			padding-left: 10px;
			padding-right: 10px;
		}
		&__video-col {
			flex-shrink: 0;
			padding-top: 0;
			margin-bottom: 0;
		}
		&__text-col {
			flex-grow: 1;
		}
		&__description {
			margin-bottom: 17px;
			font-size: 16px;
		}
		&__recommendations {
			margin-bottom: 70px;
		}
		&__recommendations-title {
			font-size: 20px;
		}
		&__recommendations-item {
			margin-bottom: 21px;
		}
	}
}
