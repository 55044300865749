.birth-foot {
  margin-top: 30px;
  border-top: 1px solid $c-light-beige;
  padding-top: 20px;
  margin-bottom: 100px;
  overflow-y: visible;
  @include min-screen($breakpoint-xs) {
	margin-bottom: 30px;
  }
  @include min-screen($breakpoint-sm) {
	margin-bottom: 100px;
  }
  @include min-screen($breakpoint-lg) {
	margin-bottom: 87px;
  }

  &__wrap {
	@include min-screen($breakpoint-sm) {
	  display: flex;
	  justify-content: space-between;
	}
	@include min-screen($breakpoint-md) {
	  @include desktop-container-padding;
	}
  }
  &__services {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	@include min-screen($breakpoint-sm) {
	  width: 50%;
	  margin-bottom: 0;
	}
  }
  &__icon {
	width: 72px;
	height: 72px;
	flex-shrink: 0;
	margin-right: 15px;
  }
  &__text {
	@extend .small-text;
	font-weight: 500;

	span {
	  display: block;
	  width: max-content;
	  margin-top: 6px;
	  @include min-screen($breakpoint-md) {
		margin-top: 0;
	  }
	  color: $c-burgundy;
	  font-weight: 400;
	  border-bottom: 1px dashed currentColor;
	  cursor: pointer;
	  @extend .transition-active;

	  &:hover {
		border-color: transparent;
	  }
	}
  }
  &__prev-next {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	@include min-screen($breakpoint-lg) {
	  flex-direction: row;
	}
  }
  &__prev-step {
	@extend .link-with-arrow;
	cursor: pointer;
	margin-top: 20px;
	@include min-screen($breakpoint-lg) {
	  margin-top: 0;
	  margin-right: 30px;
	}

	svg {
	  display: inline-block;
	  width: 18px;
	  height: 13px;
	  margin-left: 0;
	  margin-right: 10px;
	}

	&:hover {
	  svg {
		transform: translateX(-10px);
	  }
	}
  }
  &__button {
	width: 100%;
	@include min-screen($breakpoint-xs) {
	  width: auto;
	  padding-left: 40px;
	  padding-right: 27px;
	}

	svg {
	  display: inline-block;
	  width: 18px;
	  height: 13px;
	  color: #ffffff;
	  margin-left: 10px;
	}

	&._no-icon {
	  @include min-screen($breakpoint-xs) {
		padding-left: 45px;
		padding-right: 45px;
	  }
	  svg {
		display: none;
	  }
	}
  }
}
