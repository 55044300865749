//для IE 11

._ie{
	.doctors-slider__slide {
		margin-right: calc( 95px + 50 * ((100vw - 1440px) / (480)));
		margin-bottom: 25px;
	}
}

.factors {
	$block: &;
	position: relative;
	margin-top: 70px;
	overflow: visible;
	@include smoothValue('margin-top', 70px, 100px, $breakpoint-xxs, $breakpoint-xs);
	@include smoothValue('margin-top', 76px, 60px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('margin-top', 80px, 120px, $breakpoint-lg, $breakpoint-fhd);

	.grid {
		position: relative;
		@include min-screen(1800px) {
			margin-top: 3%;
		}
	}

	&__wrap {
		@include min-screen($breakpoint-sm) {
			position: absolute;
			z-index: 2;
			top: 0;
			max-width: 45%;
			@include desktop-container-padding-left;
		}
		@include min-screen($breakpoint-md) {
			max-width: 35%;
		}
		@include min-screen($breakpoint-lg) {
			max-width: 45%;
		}
		@include min-screen($breakpoint-fhd) {
			max-width: 40%;
		}
	}

	&__title {
		@extend .title-h4;
		margin-bottom: 25px;
	}

	&__list {
		counter-reset: factors-counter;
		//@include min-screen($breakpoint-sm) {
		//	padding-left: calc((100% - 15 * var(--col-gutter))/16);
		//	padding-right: calc((100% - 15 * var(--col-gutter))/16);
		//}
		@include min-screen(450px) {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: -40px;
		}
		@include min-screen($breakpoint-sm) {
			margin-bottom: 0;
			//display: grid;
			//grid-template-columns: 1fr;
			//grid-auto-flow: dense;
		}
	}

	&__item {
		position: relative;

		&:nth-child(2n - 1) {
			.factors__item-wrap::after {
				left: 18px;
			}
		}

		&:nth-child(2n) {
			.factors__item-wrap::after {
				right: 15px;
			}
		}

		&:nth-child(2n) {
			@include min-screen($breakpoint-sm) {
				height: 0;
			}
			@include smoothValue('margin-bottom', 173px, 185px, $breakpoint-sm, $breakpoint-md);
			@include smoothValue('margin-bottom', 165px, 206px, $breakpoint-lg, $breakpoint-fhd);
		}

		& + & {
			@include max-screen($breakpoint-xs - 1px) {
				margin-top: 40px;
			}
			//@include smoothValue('margin-top', -12%, -3%, $breakpoint-sm, $breakpoint-fhd);
			//@include smoothValue('margin-bottom', 20px, 40px, $breakpoint-sm, $breakpoint-fhd);
		}

		&:last-child {
			@include min-screen($breakpoint-sm) {
				height: auto;
				margin-bottom: 0;

				.factors__item-wrap {
					transform: translateY(-20%);
				}
			}

			@include min-screen($breakpoint-md) {
				margin-top: -40px;

				.factors__item-wrap {
					transform: translateY(-45%);
				}
			}
			@include min-screen($breakpoint-lg) {
				margin-top: -136px;

				.factors__item-wrap {
					transform: translateY(8%);
				}
			}
			@include min-screen($breakpoint-fhd) {
				margin-top: -220px;

				.factors__item-wrap {
					transform: translateY(-5%);
				}
			}
		}

		@include min-screen($breakpoint-sm) {
			&:nth-child(1) {
				#{$block} {
					&__text {
						justify-content: flex-start;
					}

					&__item-wrap::after {
						left: 0;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
			&:nth-child(2) {
				#{$block} {
					&__item-wrap {
						margin-left: calc(-1 * var(--col-gutter));
						transform: translateY(-6%);
					}
					&__item-wrap::after {
						left: 50%;
						top: 100%;
						transform: translate(-50%, -50%);
					}
				}
			}
			&:nth-child(3) {
				#{$block} {
					&__item-wrap {
						margin-right: calc(-1 * var(--col-gutter));
					}
					&__item-wrap::after {
						left: 30%;
						top: 100%;
						transform: translate(-50%, -50%);
					}
				}
			}
			&:nth-child(4) {
				#{$block} {
					&__text {
					}

					&__text-wrap {
						text-align: left;
					}

					&__item-wrap {
						transform: translateY(-14%);
					}

					&__item-wrap::after {
						right: 0;
						top: 50%;
						transform: translate(50%, -50%);
					}
				}
			}
			&:nth-child(5) {
				#{$block} {
					&__item-wrap::after {
						left: 40%;
						top: 100%;
						transform: translate(-50%, -50%);
					}
				}
			}
			&:nth-child(6) {
				#{$block} {
					&__text {
						text-align: right;
					}
					&__item-wrap {
						margin-left: calc(-1 * var(--col-gutter));
					}

					&__item-wrap::after {
						left: 15%;
						top: 100%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
		@include min-screen($breakpoint-md) {
			&:nth-child(2) {
				#{$block} {
					&__item-wrap {
						transform: translateY(-22%);
					}
				}
			}
			&:nth-child(4) {
				#{$block} {
					&__item-wrap {
						transform: translateY(-27%);
					}
					&__text {
						justify-content: flex-start;
					}
					&__item-wrap::after {
						right: 7%;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
		}
		@include min-screen($breakpoint-lg) {
			&:nth-child(2) {
				#{$block} {
					&__item-wrap::after {
						right: 28%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(3) {
				#{$block} {
					&__item-wrap::after {
						right: 42%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(4) {
				#{$block} {
					&__item-wrap::after {
						right: 23%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(5) {
				#{$block} {
					&__item-wrap::after {
						right: auto;
						left: 32%;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(6) {
				#{$block} {
					&__item-wrap::after {
						right: auto;
						left: 31%;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
		}
		@include min-screen(1800px) {
			&:nth-child(1) {
				#{$block} {
					&__item-wrap::after {
						top: 67%;
					}
				}
			}
			&:nth-child(2) {
				#{$block} {
					&__item-wrap {
						transform: translateY(-36%);
					}
					&__item-wrap::after {
						right: 32%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(3) {
				#{$block} {
					&__item-wrap::after {
						right: 52%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
					&__text-wrap {
						padding-right: 10%;
					}
				}
			}
			&:nth-child(4) {
				#{$block} {
					&__item-wrap {
						transform: translateY(-40%);
					}
					&__text {
						justify-content: center;
					}
					&__item-wrap::after {
						right: 8%;
						left: auto;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(5) {
				#{$block} {
					&__text {
						justify-content: center;
					}
					&__text-wrap {
						margin-right: -10%;
					}
					&__item-wrap::after {
						right: auto;
						left: 23.5%;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
			&:nth-child(6) {
				#{$block} {
					&__item-wrap::after {
						right: auto;
						left: 35%;
						top: 100%;
						transform: translate(0, -50%);
					}
				}
			}
		}

		@include min-screen(450px) {
			width: 50%;
			//padding-left: var(--col-gutter);
			//padding-right: var(--col-gutter);
			box-sizing: border-box;
			margin-bottom: 40px;
		}
		@include min-screen($breakpoint-sm) {
			width: 100%;
			margin-bottom: 0;
		}
	}

	&__item-wrap {
		position: relative;

		&::after {
			position: absolute;
			counter-increment: factors-counter;
			content: counter(factors-counter);
			top: 18px;
			width: 54px;
			height: 54px;
			display: flex;
			align-items: baseline;
			justify-content: center;
			background-color: $c-burgundy;
			border-radius: 50%;
			font-family: 'Kudryashev Headline', sans-serif;
			font-weight: 500;
			font-size: 29px;
			line-height: 2;
			color: $c-white;
			transform: scale(1);
			transition: all $default-transition-duration $default-transition-function;

			._win._chrome &,
			._win._opera &,
			._win._yandex & {
				line-height: 1.7;
			}

			@include min-screen($breakpoint-lg) {
				width: 68px;
				height: 68px;
				line-height: 74px;
				._win._chrome & {
					line-height: 2.2;
				}
			}
		}

		&:hover {
			#{$block} {
				&__image {
					clip-path: polygon(3% 97%, 97% 97%, 97% 3%, 3% 3%);

					img {
						transform: scale(1.12);
					}
				}
			}
		}
	}

	&__image {
		clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
		transition: all 0.35s ease-in-out;
		img {
			width: 100%;
			transition: all 0.35s ease-in-out;

			@include max-screen($breakpoint-sm - 1px) {
				height: 250px;
				object-fit: cover;
			}
		}
	}

	&__text {
		margin-top: 15px;

		font-weight: 500;
		font-size: 16px;
		line-height: 1.3;
		text-transform: uppercase;
		@include max-screen($breakpoint-xs - 1px) {
			font-family: 'Museo Sans Cyrl', serif;
		}
		@include min-screen($breakpoint-sm) {
			position: absolute;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			font-size: 14px;
			margin-top: 24px;
		}
		@include min-screen($breakpoint-fhd) {
			line-height: 1.4;
			margin-top: 22px;
		}
		@include smoothValue('font-size', 14px, 16px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('font-size', 16px, 23px, $breakpoint-lg, $breakpoint-fhd);
	}

	&__text-wrap {
		@include min-screen($breakpoint-sm) {
			display: inline-block;
		}
	}

	&__after-text {
		font-size: 14px;
		line-height: 1.3;
		@include smoothValue('font-size', 16px, 20px, $breakpoint-lg, $breakpoint-fhd);
		@include max-screen($breakpoint-sm - 1px) {
			margin-top: 50px;
		}
		@include min-screen($breakpoint-sm) {
			position: absolute;
			top: calc(100% - 40px);
		}
		@include min-screen($breakpoint-lg) {
			line-height: 1.6;
		}
	}
}
