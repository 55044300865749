.articles-list {
  --gird-gutter: 30px;

  &__aside {
	padding-top: 20px;
	@include min-screen($breakpoint-md) {
	  padding-right: $indent-col-md;
	}
  }
  &__main {
	@include min-screen($breakpoint-md) {
	  padding-left: $indent-col-md;
	}
  }
  &__header {
	border-bottom: 1px solid $c-light-beige;
	@include min-screen($breakpoint-sm) {
	  display: flex;
	  justify-content: space-between;
	}
	padding: 20px 0;
	@include min-screen($breakpoint-sm) {
	  padding-left: 20px;
	}
	@include min-screen($breakpoint-md) {
	  padding-left: 25px;
	}
  }
  &__search-result {
	@extend .title-h6;
	color: $c-burgundy;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 0;
	}
  }
  &__search {
	width: 100%;
	@include min-screen($breakpoint-sm) {
	  width: 50%;
	  max-width: 405px;
	}
  }
  &__row {
	@include min-screen($breakpoint-xs) {
	  display: flex;
	  justify-content: space-between;
	}
	padding: 15px 0 20px 0;
	@include min-screen($breakpoint-sm) {
	  padding: 20px 0;
	}
	@include min-screen($breakpoint-md) {
	  padding: 25px;
	}
	&:not(:last-child) {
	  border-bottom: 1px solid $c-light-beige;
	}
  }
  &__left {
	@include min-screen($breakpoint-xs) {
	  width: 60%;
	}
  }
  &__title {
	display: block;
  	@extend .title-h5;
	margin-bottom: 10px;
	cursor: pointer;
	&:hover {
	  color: $c-burgundy;
	  text-decoration: underline;
	}
  }
  &__date	{
  	@extend .small-text;
	color: $c-dark-gray;
	margin-bottom: 20px;
  }
  &__right {
	@include min-screen($breakpoint-xs) {
	  width: 40%;
	}
	@include smoothValue('width', 40%, 30%, $breakpoint-sm, $breakpoint-md);
	display: flex;
  }
  &__img {
	height: 80px;
	margin-right: 10px;
  }
  &__name {
	@extend .title-h6;
	color: $c-burgundy;
	margin-bottom: 10px;
  }
  &__credentials {
	@extend .tiny-text;
	@include min-screen($breakpoint-md) {
	  font-size: 10px;
	}
	&:not(:last-child) {
	  margin-bottom: 3px;
	}
  }
}
