.license {
	margin-top: 26px;
	margin-bottom: 30px;
	@include smoothValue('margin-top', 26px, 55px, $breakpoint-md, $breakpoint-sm);
	@include smoothValue('margin-bottom', 30px, 40px, $breakpoint-md, $breakpoint-sm);
	&__wrap {
		padding-top: 20px;
		border-top: 1px solid $c-light-beige;
		@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('padding-top', 30px, 40px, $breakpoint-md, $breakpoint-sm);
	}
	&__title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 20px;
		@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include min-screen($breakpoint-md) {
			font-size: 26px;
		}
	}

	&__list {
		@include min-screen($breakpoint-sm) {
			display: flex;
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	&__item {
		display: block;
		& + & {
			margin-top: 10px;
			@include min-screen($breakpoint-sm) {
				margin-top: 0;
			}
		}
		@include min-screen($breakpoint-sm) {
			width: 50%;
			box-sizing: border-box;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&__item-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		background: rgba(235, 228, 217, 0.3) url('../images/backgrounds/waves-small.png') 0 0 no-repeat;
		background-size: contain;
		border: 1px solid rgb(235, 228, 217);
		padding: 26px 12px 36px;
		text-align: center;
	}
	&__image {
		width: 100%;

		img {
			mix-blend-mode: multiply;
		}
	}
	&__text {
		margin-top: 40px;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
	}
}
