.content-table, .classic-table {
  @extend .tiny-section-mb;
  width: 100%;
  max-width: 1020px;
  border-collapse: collapse;

  tr, td {
	border: none;
  }

  tr {
	border-bottom: 1px solid $c-light-beige;
  }

  td {
	font: 400 14px/1.5 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);
	color: $c-dark;
	padding: 13px 10px 14px 10px;
	@include smoothValue('padding-top', 13px, 16px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-top', 16px, 25px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 14px, 16px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 16px, 25px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 10px, 15px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-right', 15px, 20px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-left', 10px, 15px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-left', 15px, 20px, $breakpoint-sm, $breakpoint-lg);

	&:not(:first-child) {
	  text-align: right;
	  white-space: nowrap;
		@include max-screen($breakpoint-xs - 1px) {
			white-space: inherit;
		}

	  tbody & {
		font-size: 12px;
		@include smoothValue('font-size', 12px, 14px, $breakpoint-xs, $breakpoint-sm);
	  }
	}

	ul {
	  @extend .styled-ul;

	  li {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;

		&::before {
		  background-color: #606060;
		}
	  }
	}
  }

  &__heading td {
	width: 100%;
	background-color: $c-light-beige;
	color: #1b1b1b;
	font-weight: 700;
	font-size: 12px;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-xs, $breakpoint-sm);
  }

  &__heading_major td {
	background-color: #ece7e2;
	color: $c-burgundy;
  }

  thead td {
	font: 400 9px/1.5 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 9px, 12px, $breakpoint-xs, $breakpoint-sm);
	text-transform: uppercase;
	color: $c-dark-gray;
	padding-top: 0;
	padding-bottom: 6px;
	@include smoothValue('padding-top', 0px, 15px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 6px, 11px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 11px, 15px, $breakpoint-sm, $breakpoint-lg);

	&:first-child {
	  text-align: left;
	}
  }

  td.content-table__pale {
	color: $c-dark-gray;
  }

  tfoot td {
	padding-top: 19px;
	@include smoothValue('padding-top', 19px, 30px, $breakpoint-xs, $breakpoint-sm);

	@include min-screen($breakpoint-sm) {
	  font-size: 14px;
	}
  }

  &__flex-row {
	@include max-screen($breakpoint-xs) {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;

	  td:first-child {
		width: 100%;
	  }

	  td:not(:first-child) {
		text-align: left;
	  }
	}
  }
  &__psprice {
    @include max-screen($breakpoint-xs) {

      td:first-child {
	    font-size: 0.9em;
      }

      td:not(:first-child) {
        font-size: 0.9em;
	    white-space: nowrap;
      }

	  td:last-child {
	    span {
		  display: none;
		}
	  }
	}
    @include min-screen($breakpoint-xs) {
	  td:last-child {
	    span {
	      display: inline;
	    }
	  }
	}
  }
}

.classic-table {
	.main {
		font: 400 9px/1.5 'Uni Neue', sans-serif;
		@include smoothValue('font-size', 9px, 12px, $breakpoint-xs, $breakpoint-sm);
		text-transform: uppercase;
		//color: $c-dark-gray;
		padding-top: 0;
		padding-bottom: 6px;
		@include smoothValue('padding-top', 0px, 15px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('padding-bottom', 6px, 11px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('padding-bottom', 11px, 15px, $breakpoint-sm, $breakpoint-lg);
		text-align: left;
		background-color: #ece7e2;
		td {
			font-size: 12px;
			font-weight: 600;
			color: #8c8c8c;
			@include smoothValue('padding-top', 16px, 15px, $breakpoint-sm, $breakpoint-lg);
			@include smoothValue('padding-bottom', 16px, 30px, $breakpoint-sm, $breakpoint-lg);
		}
	}
}
