.sticker {
	width: 120px;
	height: 120px;
	background: no-repeat center url(/local/templates/emc/img/badge.png);
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 15px;
	color: #ffff;
	line-height: 21px;
	position: absolute;
	top: 50%;
	right: 9%
}
@media only screen and (max-width: 768px){
	.sticker {
		top: 60%;
		right: 5%
	}
}
.birth-config {
  &__wrap {
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding();
	}
  }
  &__page-intro {
	margin-bottom: 22.5px;
	@include smoothValue('margin-bottom', 22.5px, 15px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 15px, 22.5px, $breakpoint-sm, $breakpoint-lg);
  }
  &__heading {
	@extend .text-17-300;
	font-weight: 250;
	@include min-screen($breakpoint-md) {
	  font-weight: 300;
	}
	color: $c-burgundy;
	margin-bottom: 10px;
	@include smoothValue('margin-bottom', 10px, 5px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 5px, 10px, $breakpoint-sm, $breakpoint-md);

	&._wrap {
	  max-width: 746px;
	}
  }
  &__lead-in {
	max-width: 795px;
	@extend .simple-text;
	&._small {
	  @include max-screen($breakpoint-xxs) {
		max-width: 270px;
	  }
	}
  }
  &__filter {
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);

	.sausage-menu__link {
	  font-size: 14px;
	  @include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);
	}
  }
  &__products {
	display: flex;
	flex-direction: column-reverse;
	@include min-screen($breakpoint-sm) {
	  flex-direction: column;
	}
  }
  &__compare {
	@extend .link-with-arrow;
	cursor: pointer;
	align-self: center;
	margin-top: 20px;
	@include min-screen($breakpoint-sm) {
	  position: relative;
	  z-index: 500;
	  align-self: flex-end;
	  margin-top: 5px;
	  @include smoothValue('margin-top', 5px, -46px, $breakpoint-md, $breakpoint-lg);
	  margin-bottom: 30px;
	  @include smoothValue('margin-bottom', 30px, 40px, $breakpoint-sm, $breakpoint-lg);
	}

	svg {
	  display: inline-block;
	  width: 18px;
	  height: 13px;
	  margin-left: 0;
	  margin-right: 11px;
	}
	span {
	  cursor: pointer;
	}
	&:hover {
	  svg {
		transform: translateX(-10px);
	  }
	}
  }
  &__tiles {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(290px, 339px));
	@include min-screen($breakpoint-lg) {
	  grid-template-columns: repeat(auto-fill, minmax(290px, 380px));
	}
	grid-gap: 20px;
	@include smoothValue('grid-gap', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('grid-column-gap', 30px, 35px, $breakpoint-md, $breakpoint-lg);

	&._narrow {
	  grid-template-columns: repeat(auto-fill, minmax(290px, 339px));
	  grid-gap: 30px;
	  @include smoothValue('grid-column-gap', 30px, 95px, $breakpoint-md, $breakpoint-lg);
	  @include min-screen($breakpoint-fhd) {
		grid-template-columns: repeat(3, minmax(290px, 339px));
	  }
	}
	&:not(&._narrow) {
	  @include min-screen($breakpoint-lg) {
		grid-template-columns: repeat(auto-fill, minmax(290px, 380px));
	  }
	}
  }
  &__category {
	@extend .subtitle-20-100;
	font-weight: 300;
	margin-bottom: -15px;
	@include smoothValue('margin-bottom', -15px, -10px, $breakpoint-xxs, $breakpoint-sm);

	&._expert {
	  @include max-screen(737px) {
		grid-row: 3 / 4;
	  }
	}
	&._dummy {
	  @include max-screen(737px) {
		display: none;
	  }
	  @include screen(737px, 1217px) {
		grid-row: 3 / 4;
	  }
	}
	&._span-full {
	  grid-column: 1 / -1;
	  @include screen(737px, 1217px) {
		grid-column: 2 / -1;
		grid-row: 3 / 4;
	  }
	}
  }
}

.birth-services-list {
  margin-left: 0;
  margin-right: 0;
  & .accordion-faq-list__line {
	padding: 14px 15px;
	@include smoothValue('padding-top', 14px, 17px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 14px, 17px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 17px, 25px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 17px, 25px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-left', 15px, 25px, $breakpoint-sm, $breakpoint-lg);
	background-color: #ECE7E2;

	&._active {
	  padding-bottom: 0;
	}
	&:last-child {
	  border-bottom: none;
	}
  }
  & .accordion-faq-list__icon {
	@include min-screen($breakpoint-lg) {
	  left: 20px;
	}
  }
  & .accordion-faq-list__title {
	color: $c-burgundy;
	font: 500 12px/16px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 12px, 16px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('line-height', 16px, 22px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-md) {
	  flex-grow: 1;
	}
  }
  & .accordion-faq-list__text {
	color: $c-dark;
	background-color: #fff;
	margin-left: -15px;
	margin-right: -15px;
	@include smoothValue('margin-left', -15px, -25px, $breakpoint-sm, $breakpoint-lg);
  }

  &__title {
	@extend .title-h3;
	color: $c-burgundy;
	max-width: 90%;
	@include min-screen($breakpoint-xs) {
	  max-width: 70%;
	}
	@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
	margin-bottom: 20px;
	@include smoothValue('margin-bottom', 20px, 25px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-md) {
	  padding-bottom: 25px;
	  margin-bottom: 15px;
	  border-bottom: 1px solid $c-light-beige;
	  max-width: 100%;
	}
  }
  &__legend {
	width: 100%;
	box-sizing: border-box;
	padding: 0 15px;
	@include smoothValue('padding-right', 15px, 20px, $breakpoint-sm, $breakpoint-lg);
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	@include smoothValue('margin-bottom', 6px, 11px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 11px, 15px, $breakpoint-sm, $breakpoint-md);
	@extend .tiny-text;
	@include smoothValue('font-size', 9px, 12px, $breakpoint-xxs, $breakpoint-sm);
	color: $c-dark-gray;
	text-transform: uppercase;
  }
  &__menu-item {
	width: 100%;
	@include min-screen($breakpoint-lg) {
	  width: 920px;
	  box-sizing: border-box;
	}
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 10px;
	@include smoothValue('padding', 10px, 15px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 15px, 20px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 15px, 20px, $breakpoint-sm, $breakpoint-lg);
	&:not(:last-child) {
	  border-bottom: 1px solid $c-light-beige;
	}
	font-family: 400 14px/1.5 'Uni Neue Book', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);

	&._extra {
	  display: block;
	  @include min-screen($breakpoint-md) {
		padding-left: 0;
	  }
	}
	&._xxs-wrap {
	  @include max-screen($breakpoint-xs) {
		display: block;
	  }
	}
  }
  &__point {
	flex: 100% 0 0;
	display: flex;
	justify-content: space-between;
	&:not(:last-child) {
	  margin-bottom: 16px;
	  @include smoothValue('margin-bottom', 16px, 11px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 11px, 15px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__service {
	max-width: 80%;
	@include min-screen($breakpoint-sm) {
	  max-width: 75%;
	}
	&._span-full {
	  width: 100%;
	  flex: 100% 0 0;
	  margin-bottom: 13px;
	  @include smoothValue('margin-bottom', 13px, 19px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 19px, 15px, $breakpoint-sm, $breakpoint-lg);
	}
	&._bullet {
	  &::before {
		content: '';
		display: inline-block;
		margin-left: 2px;
		@include smoothValue('margin-left', 2px, 7px, $breakpoint-xxs, $breakpoint-sm);
		margin-right: 13px;
		width: 5px;
		height: 5px;
		vertical-align: middle;
		border-radius: 50%;
		background-color: #606060;
	  }
	}
  }
  &__volume {
	font-weight: bold;
	text-align: right;
	max-width: 27%;

	&._xxs-wrap {
	  @include max-screen($breakpoint-xs) {
		max-width: 80%;
		text-align: left;
		margin-top: 5px;
	  }
	}
  }
}
