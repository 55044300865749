.slider-gallery {
	//@include small-section-mt();
	margin-top: 10px;
	.container {
		@include min-screen($breakpoint-md) {
			position: relative;
		}
	}

	&__wrap {
		max-width: 745px;
		margin-left: auto;
		margin-right: auto;
	}

	&__slave {
		margin-top: 10px;
	}

	&__text {
		margin-top: 10px;
		@include min-screen($breakpoint-sm) {
			margin-top: 15px;
		}
	}
}

.slider-gallery-main {
	position: relative;
	@include min-screen($breakpoint-md) {
		position: static;
	}
	&__image {
		width: 100%;
		overflow: hidden;
		img {
			width: 100%;
		  	height: 217px;
			@include smoothValue('height', 217px, 409px, $breakpoint-xxs, $breakpoint-xs);
			@include smoothValue('height', 409px, 531px, $breakpoint-xs, $breakpoint-sm);
			@include smoothValue('height', 531px, 459px, $breakpoint-sm, $breakpoint-md);
			@include smoothValue('height', 459px, 589px, $breakpoint-md, $breakpoint-lg);
			@include smoothValue('height', 589px, 791px, $breakpoint-lg, $breakpoint-fhd);
		  	object-fit: contain;
		  	object-position: center;
		}
	}
	&__static-info {
		position: absolute;
		z-index: 2;
		bottom: 0;
		right: 0;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 16px;
		padding-right: 15px;
		min-width: 90px;
		background-color: $c-burgundy;
		font-family: 'Kudryashev Headline', sans-serif;
		color: $c-white;
		font-size: 18px;
		line-height: 1;
		box-sizing: border-box;
	}
	@include sliderArrows();
	&__arrows {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding-left: 6px;
		padding-right: 6px;
		box-sizing: border-box;
		margin: 0;
		@include min-screen($breakpoint-xs) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include min-screen($breakpoint-md) {
			padding-left: var(--container-indent);
			padding-right: var(--container-indent);
			top: calc(558px / 2);
			bottom: auto;
			height: auto;
			transform: translateY(-50%);
		}
	}
	&__arrow {
		@extend .transition-active;
		background-color: rgba(255, 255, 255, 0.9);
		color: $c-dark;
		&:hover {
			background-color: $c-beige;
			color: $c-white;
		}
	}
	&__nav {
		width: 26px;
		height: 26px;
		overflow: hidden;
		border: none;
		@include smoothValue('width', 26px, 60px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('height', 26px, 60px, $breakpoint-xs, $breakpoint-sm);
		@include min-screen($breakpoint-md) {
			border: 1px solid $c-beige;
		}
		&._prev {
			margin-left: calc((100% - 745px) / 4);
		}
		&._next {
			margin-right: calc((100% - 745px) / 4);
		}
		svg {
			width: 5px;
			height: 10px;
			@include smoothValue('width', 5px, 11px, $breakpoint-xs, $breakpoint-sm);
			@include smoothValue('height', 10px, 22px, $breakpoint-xs, $breakpoint-sm);
		}
	}
}

.slider-gallery-slave {
  	position: relative;
	&__item {
		position: relative;
		width: 80px;
		margin-right: 10px;
		padding-bottom: 10px;

		&::before {
			position: absolute;
			content: '';
			bottom: 3px;
			width: 100%;
			height: 3px;
			background-color: $c-burgundy;
			opacity: 0;
			transform: translateY(0);
			@extend .transition-move;
		}

		@include min-screen($breakpoint-lg) {
			margin-right: 15px;
		}

		img {
			width: 100%;
			opacity: 1;
			@extend .transition-move;
		}

		&.is-selected {
			&::before {
				transform: translateY(3px);
				opacity: 1;
			}
			img {
				opacity: 0.4;
			}
		}
	}
	@include sliderArrows();
	&__arrows {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		margin: 0;
	  	display: none;
		@include min-screen($breakpoint-sm - 1px) {
		  	display: flex;
			padding-left: calc(var(--container-indent) * 2);
			padding-right: calc(var(--container-indent) * 2);
		}
	}
	&__nav {
		width: 32px;
		height: 32px;
		overflow: hidden;
		border: 1px solid #EBE4D9;
		&._prev {
			margin-left: calc((100% - 745px) / 4);
		}
		&._next {
			margin-right: calc((100% - 745px) / 4);
		}
		svg {
			width: 6px;
			height: 12px;
		}
  }
}

.slider-gallery-text {
	position: relative;
	font-family: 'Uni Neue Book', sans-serif;
	font-style: italic;
	font-size: 12px;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-xxs, $breakpoint-sm);
	&__item {
		position: absolute;
		top: 0;
		width: 100%;
	}
}
