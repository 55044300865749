.competencies-all {
  &__wrap {
	@include min-screen($breakpoint-lg) {
	  @include desktop-container-padding();
	}
  }
  &__lead-in {
	@extend .text-17-300;
	margin-top: 20px;
	@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 40px, $breakpoint-sm, $breakpoint-md);
	margin-bottom: 15px;
  }
}
