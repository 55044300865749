.resume-advantages {
	&._section {
		margin-top: 20px;
		margin-bottom: 20px;
		@include min-screen($breakpoint-md) {
			display: none;
		}
		@extend .mobile-container-margin;
		@include smoothValue('margin-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__wrap {
		padding-top: 10px;
		padding-bottom: 20px;
		@include smoothValue('padding-top', 10px, 20px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		._section & {
			border-top: 1px solid $c-light-beige;
			border-bottom: 1px solid $c-light-beige;
			@include min-screen($breakpoint-sm) {
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		@include min-screen($breakpoint-md) {
			padding-bottom: 20px;
		}
	}
}

.resume-advantages-tiles {
	display: flex;
	flex-wrap: wrap;
	._section {
		margin-left: -15px;
		margin-right: -15px;
	}
	&__item {
		position: relative;
		display: flex;
		width: 100%;
		height: 215px;
		margin-top: 10px;
		@include smoothValue('height', 215px, 235px, $breakpoint-xxs, $breakpoint-sm);

		&._gray &-wrap {
			background: url('../images/backgrounds/advantages-tile-gray@2x.png') center no-repeat;
			background-size: cover;
		}
		&._beige &-wrap {
			background: url('../images/backgrounds/advantages-tile-beige@2x.png') center no-repeat;
			background-size: cover;
		}

		._section & {
			@include min-screen($breakpoint-xs) {
				& + & {
					margin-top: 0;
				}
			}
			@include min-screen($breakpoint-xs) {
				width: 50%;
				box-sizing: border-box;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		@include min-screen($breakpoint-md) {
			& + & {
				margin-top: 20px;
			}
		}
	}
	&__item-wrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		text-align: center;
	}
	&__value {
		color: $c-burgundy;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 48px;
		line-height: 1.2;
		width: 100%;
	}
	&__icon {
		position: absolute;
		left: 20px;
		top: 20px;
		width: 50px;
		height: 50px;
	}
	&__text {
		font-size: 16px;
		line-height: 1.6;
		font-weight: 500;
		margin-top: 15px;
	}
}
