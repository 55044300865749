.appointment {
	$block: &;
	position: relative;
	@include max-screen($breakpoint-md - 1px) {
		display: none;
	}
	&._big {
		display: block;
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
			#{$block} {
				&__wrap {
					display: flex;
					align-items: flex-start;
				}
				&__main {
					flex: 1 1 100%;
				}

				&__buttons {
					width: vw(290px, $breakpoint-sm);
					flex-shrink: 0;
				}

				&__buttons {
					margin-top: 0;
				}
			}
		}
		@include min-screen($breakpoint-md) {
			display: none;
		}
	}
	&._question {
		min-height: 275px;
		display: flex;
		@include max-screen($breakpoint-sm - 1px) {
			margin-top: 20px;
		}
		#{$block} {
			&__wrap {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
			&__button {
				width: 100%;
				margin-top: 30px;
				@include min-screen($breakpoint-md) {
					max-width: 198px;
				}
			}
			&__link {
				display: flex;
				align-items: flex-end;
				flex-grow: 1;
			}
		}
		&._wide {
			margin-top: 0;
			@include screen($breakpoint-sm, $breakpoint-md - 1px) {
				min-height: initial;
				#{$block} {
					&__wrap {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						align-items: flex-start;
					}
					&__main,
					&__button {
						width: 50%;
					}
					&__button {
						margin-top: 0;
					}
					&__link {
						padding-left: 72px;
						margin-top: 20px;
					}
				}
			}
		}
	}
	&._v2 {
		margin-bottom: 20px;
		@include min-screen($breakpoint-sm) {
			margin-bottom: 30px;
		}
		#{$block} {
			&__title {
				@include max-screen($breakpoint-xs - 1px) {
					padding-right: 16px;
				}
			}
			&__buttons {
				margin-top: 15px;
				@include min-screen($breakpoint-sm) {
					margin-top: 10px;
				}
				@include min-screen($breakpoint-md) {
					margin-top: 25px;
				}
			}
		}
	}

	&__wrap {
		@include waves;
		position: relative;
		padding: 15px;
		@include min-screen($breakpoint-sm) {
			padding: 25px;
		}
		&::before {
			background-color: $c-light-beige;
			mix-blend-mode: initial;
		}
	}
	&__main {
		display: flex;
		align-items: center;
	}
	&__icon {
		position: relative;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $c-white;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;
		border: 1px solid #ebe4d9;
		@include waves-small;

		svg {
			width: 30px;
			height: 30px;
		}
	}
	&__title {
		color: $c-burgundy;
		font-size: 14px;
		padding-left: 10px;
		font-family: 'Uni Neue Book', sans-serif;
		@include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 16px, 18px, $breakpoint-sm, $breakpoint-lg);
	}
	&__buttons {
		margin-top: 40px;
		@include min-screen($breakpoint-md) {
			margin-top: 60px;
		}
		&._with-text {
			@include screen($breakpoint-sm, $breakpoint-md - 1px) {
				display: flex;
				#{$block} {
					&__text {
						width: 50%;
						padding-right: 16px;
					}
					&__btn {
						width: 50%;
					}
				}
			}
		}
	}
	&__text {
		margin-bottom: 20px;
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 12px;
		@include min-screen($breakpoint-sm) {
			font-size: 14px;
		}
		@include min-screen($breakpoint-md) {
			margin-bottom: 40px;
		}
		p + p {
			margin-top: 15px;
		}
		a {
			font-family: 'Uni Neue', sans-serif;
			font-weight: bold;
		}
	}
	&__btn {
		& + & {
			margin-top: 13px;
		}
		button {
			width: 100%;
		}
	}
}
