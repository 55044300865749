.main-title {
	font-family: 'Kudryashev Headline', sans-serif;
	font-size: 37.7132px;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: -0.02em;
	@include smoothValue('font-size', 38px, 88px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 76px, 102px, $breakpoint-md, $breakpoint-lg);

	span {
		font-family: 'Playfair Display', serif;
		font-style: italic;
		color: $c-burgundy;
		font-feature-settings: 'pnum' on, 'lnum' on;
		font-weight: 500;
		font-size: 1.25em;
	}
}

.title-h1 {
	color: $c-burgundy;
	font-family: 'Kudryashev Headline', sans-serif;
	font-size: 23px;
	@include smoothValue('font-size', 23px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 30px, 48px, $breakpoint-sm, $breakpoint-lg);
}

.title-h2 {
	font-family: 'Kudryashev Headline', sans-serif;
	font-size: 31px;
	line-height: 1.2;
	font-weight: 500;
	@include smoothValue('font-size', 31px, 54px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 54px, 70px, $breakpoint-md, $breakpoint-lg);

	@include max-screen($breakpoint-lg - 1px) {
		letter-spacing: -0.02em;
	}

	span.accent {
		font-family: 'Playfair Display', serif;
		font-style: italic;
		color: $c-burgundy;
	}
}

.title-h3 {
	font-family: 'Kudryashev Headline', sans-serif;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 500;
	@include smoothValue('font-size', 32px, 38px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 38px, 48px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('font-size', 48px, 54px, $breakpoint-lg, $breakpoint-fhd);

	@include min-screen($breakpoint-sm) {
		line-height: 1.2;
	}
  	&._burgundy {
	  color: $c-burgundy;
	}
	span.accent {
		font-family: 'Playfair Display', serif;
		font-style: italic;
		color: $c-burgundy;
	}
}

.title-h4 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-style: normal;
	font-weight: 100;
	font-size: 20px;
	line-height: 24px;

	@include min-screen($breakpoint-xs) {
		line-height: 1.3;
	}
	@include smoothValue('font-size', 20px, 36px, $breakpoint-md, $breakpoint-lg);

	&._burgundy {
		color: $c-burgundy;
	}
}

.title-h5 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-weight: 100;
	font-size: 20px;
	line-height: 1.4;
	@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	&._burgundy {
		color: $c-burgundy;
	}
}

.title-h6 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-weight: 300;
	font-size: 18px;
	line-height: 1.4;
	@include smoothValue('font-size', 18px, 20px, $breakpoint-sm, $breakpoint-lg);
}

.subtitle-20-100 {
	font-size: 20px;
	line-height: 1.2;
	font-weight: 100;
	font-family: 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 20px, 26px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('font-size', 26px, 36px, $breakpoint-lg, $breakpoint-fhd);
}

.subtitle-18-300 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 22px;

	@include smoothValue('font-size', 18px, 20px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('line-height', 22px, 24px, $breakpoint-lg, $breakpoint-fhd);
}

.subtitle-18-100 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-style: normal;
	font-weight: 100;
	font-size: 18px;
	line-height: 1.25;

	@include min-screen($breakpoint-lg) {
		font-size: 20px;
	}
}

.text-type-book {
	font-family: 'Uni Neue Book', sans-serif;
	font-size: 10px;
	line-height: 130%;
	color: $c-dark-gray;

	@include min-screen($breakpoint-xs) {
		font-size: 12px;
		line-height: 1.5;
	}
	@include min-screen($breakpoint-lg) {
		font-size: 14px;
	}
	@include smoothValue('font-size', 14px, 16px, $breakpoint-lg, $breakpoint-fhd);
}

.base-text {
	font-size: 14px;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 16px, 18px, $breakpoint-sm, $breakpoint-lg);
}

.small-text {
	font-size: 12px;
	@include smoothValue('font-size', 12px, 14px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-lg);
}

.tiny-text {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 9px;
	line-height: 170%;
	text-transform: uppercase;
}

.text-17-300 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-size: 17px;
	font-weight: 300;
	@include smoothValue('font-size', 17px, 19px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('font-size', 19px, 23px, $breakpoint-sm, $breakpoint-lg);
}
