.registration-process {
  @include min-screen($breakpoint-lg) {
	@include desktop-container-padding;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__heading {
	width: 100%;
	flex-shrink: 0;
	@extend .title-h4;
	@include smoothValue('font-size', 20px, 22px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('font-size', 22px, 36px, $breakpoint-sm, $breakpoint-lg);
	margin-bottom: 40px;
	@include smoothValue('margin-bottom', 40px, 45px, $breakpoint-xs, $breakpoint-sm);
	@include smoothValue('margin-bottom', 45px, 60px, $breakpoint-sm, $breakpoint-lg);
  }
  &__list {
	margin-left: 12.5px;
	padding-left: 25px;
	@include min-screen($breakpoint-xs) {
	  width: 43%;
	  padding-left: 32.5px;
	}
	@include min-screen($breakpoint-md) {
	  padding-left: 37.5px;
	  width: 40%
	}
	border-left: 1px dashed #ece7e2;
	list-style: none;
  }
  &__step {
	font: 400 14px/1.6 'Uni Neue', sans-serif;
	color: #1b1b1b;
	@include smoothValue('font-size', 14px, 18px, $breakpoint-sm, $breakpoint-md);
	position: relative;

	&_1 {
	  margin-bottom: 40px;
	  @include smoothValue('margin-bottom', 40px, 50px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 50px, 60px, $breakpoint-sm, $breakpoint-md);
	}
	&_2, &_3 {
	  margin-bottom: 40px;
	  @include smoothValue('margin-bottom', 40px, 73px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 73px, 60px, $breakpoint-sm, $breakpoint-md);
	}
	&_4 {
	  margin-bottom: 40px;
	  @include smoothValue('margin-bottom', 40px, 55px, $breakpoint-xxs, $breakpoint-sm);
	}
	&::before {
	  content: '';
	  position: absolute;
	  top: -10px;
	  left: -37.5px;
	  @include min-screen($breakpoint-xs) {
		left: -45px;
	  }
	  @include min-screen($breakpoint-md) {
		left: -50px;
	  }
	  width: 25px;
	  height: 45px;
	  background: url('../images/mobile-app/bullet.png'), linear-gradient(#fff, #fff);
	  background-position-y: 10px;
	  background-repeat: no-repeat;
	  background-size: contain;
	  z-index: 20;
	}
	&_5::before {
	  height: 70px;
	}
  }
  &__icons-wrap {
	margin-top: 15px;
	@include smoothValue('margin-top', 15px, 20px, $breakpoint-xs, $breakpoint-md);
	display: flex;
  }
  &__icon {
	height: 40px;
	width: 120px;
	border: 1px solid rgba(27, 27, 27, .1);
	display: flex;
	justify-content: center;
	align-items: center;
	&:not(:last-child) {
	  margin-right: 15px;
	  @include min-screen($breakpoint-md) {
		margin-right: 10px;
	  }
	}
	& svg {
	  height: 25.6px;
	  width: 101px;
	}
  }
  &__image{
	display: none;
	@include min-screen($breakpoint-xs) {
	  display: block;
	}
	width: 250px;
	@include smoothValue('width', 250px, 285px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('width', 285px, 325px, $breakpoint-sm, $breakpoint-lg);
	padding-top: 20px;
	@include smoothValue('padding-top', 20px, 50px, $breakpoint-sm, $breakpoint-md);
	padding-right: 12px;
	@include smoothValue('padding-right', 12px, 135px, $breakpoint-sm, $breakpoint-md);

	& img {
	  width: 100%;
	}
  }
}
