.page-header {
	&__inner {
		padding-top: 35px;
		padding-bottom: 29px;
		border-bottom: 1px solid $c-light-beige;
	}
	&__title {
		margin-bottom: 20px;
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 23px;
		line-height: 1.15;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__title-main {
		display: block;
		margin-bottom: 12px;
		color: $c-burgundy;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__title-slash {
		display: none;
	}
	&__title-translate {
		display: block;
	}
	&__title-slash,
	&__title-translate {
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		font-size: 20px;
		line-height: 1.2;
		color: $c-dark-gray;
	}
	&__search-field {
		max-width: 745px;
	}
	@include max-screen($breakpoint-sm - 1) {
		&__inner {
			._page-search & {
				padding-bottom: 19px;
			}
		}
	}
	@include min-screen($breakpoint-sm) {
		&__inner {
			padding-top: 40px;
			padding-bottom: 30px;
		}
		&__title {
			margin-bottom: 23px;
			font-size: 38px;
			line-height: 1.2;
		}
		&__title-main {
			margin-bottom: 5px;
		}
		&__title-slash,
		&__title-translate {
			font-size: 26px;
			line-height: 1.4;
		}
	}
	@include min-screen($breakpoint-md) {
		&__inner {
			padding-top: 47px;
			padding-bottom: 25px;
			._page-search & {
				padding-bottom: 40px;
			}
		}
		&__title {
			margin-bottom: 32px;
			max-width: 935px;
			font-size: 48px;
			line-height: 1.15;
		}
		&__title-main {
			display: inline;
			margin-bottom: 0;
		}
		&__title-slash,
		&__title-translate {
			display: inline;
			font-size: 36px;
			line-height: inherit;
		}
	}
}
