.tags {
	&__wrap {
		display: flex;
		padding-top: 20px;
		padding-bottom: 20px;
		@include smoothValue('padding-top', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('padding-top', 30px, 40px, $breakpoint-md, $breakpoint-sm);
		@include smoothValue('padding-bottom', 20px, 30px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('padding-bottom', 30px, 40px, $breakpoint-md, $breakpoint-sm);
	}
	&__title {
		display: flex;
		align-items: center;
		font-size: 12px;
		height: 20px;
		color: $c-dark-gray;
		text-transform: uppercase;
	}
	&__list {
		margin-top: -5px;
		margin-left: 5px;
	}
	&__item {
		display: inline-flex;
		align-items: center;
		height: 20px;
		padding-left: 5px;
		padding-right: 5px;
		margin-top: 5px;
		margin-left: 5px;
		background-color: #ece7e2;
		font-size: 12px;
		line-height: 1;
		white-space: nowrap;
		color: $c-burgundy;
		text-transform: uppercase;
		@extend .transition-active;
		&:hover {
			background-color: $c-beige;
			color: $c-white;
		}
	}
}
