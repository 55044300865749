//для IE 11

._ie{
	.footer__logo svg, .footer__logo img {
		max-height: 65px;
	}
	.footer-markets__item ._google-play {
		max-height: 45px;
	}
	.footer-copyright__agima {
		max-height: 12px;
	}
	.footer__link .question-ceo-item > svg {
		max-width: 25px;
	}
}

.footer {
	position: relative;
	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #8c8c8c;
		opacity: 0.2;
	}
	@include min-screen($breakpoint-xs) {
		padding-top: 16px;
	}

	// fix bug with opacity chrome 64+
	._chrome._desktop & {
		will-change: auto;
	}

	&__wrap {
		@include min-screen($breakpoint-sm) {
			@include desktop-container-padding;
		}
		@include min-screen($breakpoint-md) {
			padding-right: 0;
		}
	}
	&__top {
		position: relative;
		padding-top: 25px;
		@include min-screen($breakpoint-sm) {
			padding-bottom: 12px;
		}
		@include min-screen($breakpoint-md) {
			padding-bottom: 0;
		}
		@include min-screen($breakpoint-lg) {
			padding-bottom: 18px;
		}
	}
	&__bottom {
		background-color: $c-beige-bg;
		padding-top: 20px;
		padding-bottom: 32px;
		margin-top: 30px;
		@include min-screen($breakpoint-sm) {
			padding-top: 42px;
		}
		@include min-screen($breakpoint-md) {
			display: flex;
			height: 150px;
			align-items: center;
			box-sizing: border-box;
			.container {
				flex-grow: 1;
			}
		}
		@include min-screen($breakpoint-lg) {
			.grid,
			.container,
			.footer__wrap {
				height: 100%;
			}
			height: 170px;
		}

		.grid {
			justify-content: space-between;
			@include min-screen($breakpoint-md) {
				justify-content: flex-start;
			}
		}
	}

	&__logo {
		margin-bottom: 18px;
		a {
			display: inline-block;
		}
		svg,
		img {
			width: 151px;
			height: auto;

			@include min-screen($breakpoint-sm) {
				width: 170px;
			}
			@include smoothValue('width', 170px, 209px, $breakpoint-md, $breakpoint-lg);
		}

		@include min-screen($breakpoint-sm) {
			margin-bottom: 32px;
		}
	}

	&__col {
		& + & {
			position: relative;
			padding-top: 20px;
			padding-bottom: 13px;
			margin-top: 25px;

			@include min-screen($breakpoint-sm) {
				padding-top: 0;
				&::before {
					display: none;
				}
			}
			@include min-screen($breakpoint-sm) {
				margin-top: 0;
			}

			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: calc(-1 * var(--container-indent));
				width: calc(100% + var(--container-indent) * 2);
				height: 1px;
				background-color: $c-dark-gray;
				opacity: 0.2;
			}
		}
	}

	&__link {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		line-height: 1.3;
	  	position: relative;
	  	z-index: 2;

		a {
			@extend .transition-active;
			&:hover {
				text-decoration: underline;
				color: $c-beige;
			}
		}

		@include min-screen($breakpoint-sm) {
			line-height: 1.5;
		}
		@include min-screen($breakpoint-lg) {
			line-height: 1.6;
		}

		& + & {
			margin-top: 14px;
			@include min-screen($breakpoint-sm) {
				margin-top: 20px;
			}
			@include min-screen($breakpoint-lg) {
				margin-top: 10px;
			}
		}

		@include smoothValue('font-size', 14px, 18px, $breakpoint-md, $breakpoint-lg);
	}

	&__markets {
		margin-top: 35px;

		@include min-screen($breakpoint-sm) {
			margin-top: 0;
		}
	}

	&__cards {
	  	opacity: 0;
	  	visibility: hidden;
		margin-top: 50px;
		@include min-screen($breakpoint-md) {
			margin-top: 0;
		}
	}

	&__copyright {
		position: relative;
	  	z-index: 1;
		margin-top: 16px;
		font-size: 12px;
		line-height: 1;
		font-family: 'Uni Neue Book', sans-serif;
		@include min-screen($breakpoint-sm) {
			margin-top: 44px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 56px;
		}
		@include min-screen($breakpoint-lg) {
			margin-top: -16px;
		}
	}

	&__tagline {
		margin-top: 10px;
		font-weight: bold;
		color: $c-beige-bg;
		font-size: 12px;
		@include smoothValue('font-size', 12px, 16px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('font-size', 16px, 18px, $breakpoint-md, $breakpoint-lg);
		@include smoothValue('margin-top', 10px, 15px, $breakpoint-sm, $breakpoint-md);
		@include smoothValue('margin-top', 15px, 20px, $breakpoint-lg, $breakpoint-fhd);
	}
}

.footer-social {
	display: flex;
	align-items: center;
	justify-content: space-between;
	opacity: 0.6;

	@include min-screen($breakpoint-md) {
		justify-content: flex-start;
	}

	&__item {
		opacity: 1;
		display: block;
		@extend .transition-move;
		&:hover {
			opacity: 0.7;
		}
		svg {
			display: block;
			width: 34px;
			height: 34px;
		}

		& + & {
			@include min-screen($breakpoint-md) {
				margin-left: 24px;
			}
			@include min-screen($breakpoint-lg) {
				margin-left: 41px;
			}
		}
	}
}

.footer-markets {
	display: flex;
	justify-content: space-between;
	align-items: center;
	mix-blend-mode: lighten;
	@include min-screen($breakpoint-md) {
		justify-content: flex-start;
	}

	&__item {
		opacity: 1;
		@extend .transition-move;
		&:hover {
			opacity: 0.7;
		}
		._app-store {
			width: 135px;
			height: auto;
		}
		._google-play {
			width: 135px;
			height: auto;
		}

		& + & {
			@include min-screen($breakpoint-lg) {
				margin-left: 28px;
			}
		}
	}
}

.footer-cards {
	display: flex;
	justify-content: space-between;
	align-items: center;
	opacity: 0.6;
	@include min-screen($breakpoint-md) {
		justify-content: flex-start;
	}

	&__item {
		&._mir {
			width: 44px;
			@include min-screen($breakpoint-sm) {
				width: 51px;
			}
			@include min-screen($breakpoint-md) {
				width: 43px;
			}
			@include min-screen($breakpoint-lg) {
				width: 59px;
			}
		}

		&._visa {
			width: 41px;
			@include min-screen($breakpoint-sm) {
				width: 48px;
			}
			@include min-screen($breakpoint-md) {
				width: 41px;
			}
			@include min-screen($breakpoint-lg) {
				width: 55px;
			}
		}

		&._mastercard {
			width: 99px;
			@include min-screen($breakpoint-sm) {
				width: 114px;
			}
			@include min-screen($breakpoint-md) {
				width: 99px;
			}
			@include min-screen($breakpoint-lg) {
				width: 132px;
			}
		}
		& + & {
			@include min-screen($breakpoint-md) {
				margin-left: 42px;
			}
			@include min-screen($breakpoint-lg) {
				margin-left: 66px;
			}
		}
	}
}

.footer-copyright {
	color: $c-dark-gray;
	//@include min-screen($breakpoint-lg) {
	//	position: absolute;
	//	width: 100%;
	//	bottom: 0;
	//}

	&__item {
		display: flex;
		align-items: baseline;

		& + & {
			margin-top: 28px;

			@include min-screen($breakpoint-sm) {
				margin-top: 0;
			}
		}

		&._creator {
			font-size: 14px;
		}

		a {
			opacity: 1;
			@extend .transition-move;
			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__agima {
		width: 55px;
	}
}
