.product-card {
  width: 100%;
  border: 1px solid transparent;
  @extend .transition-active;

  &:hover {
	& .product-card__body {
	  background-color: #fff;
	  border-color: $c-light-beige;
	}
  }
  &._select {
	box-sizing: border-box;
	border-color: $c-beige;

	.product-card__body {
	  background-color: $c-gray;
	  border: none;
	}
	.product-card__button {
	  display: none;
	}
	.product-card__chosen {
	  display: flex;
	}
  }

  &__img {
	width: 100%;
	height: 180px;
	@include smoothValue('height', 180px, 200px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('height', 200px, 240px, $breakpoint-sm, $breakpoint-lg);
	position: relative;

	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  object-position: center;
	}
	&:not(._square)::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(rgba($c-beige, .3), rgba($c-beige, .3)),
	  linear-gradient(rgba($c-dark-gray, .2), rgba($c-dark-gray, .2));
	  background-blend-mode: color;
	  mix-blend-mode: color;
	}
	&._square {
	  width: 250px;
	  height: 250px;
	  img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	}
	&._small {
	  width: 230px;
	  height: 230px;
	  margin: 18px auto 0 auto;
	}
	&._with-overlay {
	  position: relative;

	  &::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $c-beige;
		mix-blend-mode: multiply;
		opacity: 0.1;
	  }
	}
  }
  &__body {
	box-sizing: border-box;
	border: 1px solid transparent;
	background-color: $c-light-gray;
	padding: 15px;
	@include smoothValue('padding', 15px, 20px, $breakpoint-xxs, $breakpoint-sm);
	padding-top: 15px;
	@include smoothValue('padding-top', 15px, 20px, $breakpoint-sm, $breakpoint-md);
	height: 221px;
	@include smoothValue('height', 221px, 205px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('height', 205px, 258px, $breakpoint-sm, $breakpoint-lg);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&._small {
	  height: 200px;
	  @include smoothValue('height', 200px, 268px, $breakpoint-sm, $breakpoint-lg);
	}
	&._long {
	  height: 490px;
	  padding: 20px;
	  position: relative;
	}
  }
  &__stats {
	position: absolute;
	top: 30px;
	right: 10px;
	max-width: 55px;
  }
  &__stat {
	text-transform: uppercase;
	color: #606060;
	@extend .tiny-text;
	font-size: 10px;
	text-align: center;
	@include max-screen($breakpoint-xs - 1px) {
	  display: none;
	}
	&:not(:last-child) {
	  margin-bottom: 5px;
	}

	svg {
	  width: 16px;
	  height: 16px;
	  margin: 0 auto 5px auto;
	}
  }
  &__text {
	&._mb-auto {
	  margin-top: 5px;
	  margin-bottom: auto;
	}
  }
  &__title, &__price {
	@extend .title-h5;
	font-weight: 300;
	line-height: 24px;
	@include smoothValue('line-height', 24px, 36px, $breakpoint-sm, $breakpoint-lg);
  }
  &__title {
	color: $c-burgundy;
	margin-bottom: 10px;
	@include smoothValue('margin-bottom', 10px, 12px, $breakpoint-sm, $breakpoint-md);
	cursor: pointer;
	&:hover {
	  text-decoration: underline;
	}
	&._no-hover {
	  cursor: auto;
	  &:hover {
		text-decoration: initial;
	  }
	}
	&._mb-small {
	  margin-bottom: 5px;
	}
  }
  &__desc {
	font: 300 14px/22px 'Museo Sans Cyrl', sans-serif;
	&._tiny {
	  text-transform: uppercase;
	  font-size: 10px;
	  line-height: 17px;
	  @include smoothValue('font-size', 10px, 12px, $breakpoint-sm, $breakpoint-md);
	}
	&._small {
	  font-size: 12px;
	  line-height: 170%;
	}
	&._12-16 {
	  font-size: 12px;
	  line-height: 16px;
	}
	&._caps {
	  text-transform: uppercase;
	}
  }
  &__cta {}
  &__price {
	margin: 0 auto 15px auto;
	text-align: center;
	@include min-screen($breakpoint-sm) {
	  margin-left: 0;
	  text-align: left;
	}
	&:not(._mb-constant) {
	  @include smoothValue('margin-bottom', 15px, 25px, $breakpoint-sm, $breakpoint-lg);
	}
	&._mb-small {
	  @include smoothValue('margin-bottom', 15px, 10px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 10px, 25px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__included {
	font: 300 18px/25px 'Museo Sans Cyrl', sans-serif;
	@include smoothValue('font-size', 18px, 20px, $breakpoint-sm, $breakpoint-md);
	margin-bottom: 15px;
	&:not(._mb-constant) {
	  @include smoothValue('margin-bottom', 15px, 10px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('margin-bottom', 10px, 25px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__button {
	width: 100%;
	height: 40px;
	font-size: 12px;
	@include min-screen($breakpoint-xs) {
	  width: auto;
	  padding-left: 35px;
	  padding-right: 35px;
	}
  }
  &__chosen {
	width: 100%;
	height: 40px;
	display: none;
	justify-content: space-between;
	align-items: center;
	font: 700 14px/19px 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 14px, 16px, $breakpoint-sm, $breakpoint-md);
	@include smoothValue('line-height', 19px, 22px, $breakpoint-sm, $breakpoint-md);
  }
  &__check {
	color: $c-beige;
	text-transform: uppercase;

	svg {
	  display: inline-block;
	  width: 17px;
	  height: 17px;
	  margin-right: 5px;
	  vertical-align: text-bottom;
	}
  }
  &__cancel {
	text-decoration: underline;
	font-weight: 400;
	cursor: pointer;
	@extend .transition-active;

	&:hover {
	  text-decoration: none;
	}
	svg {
	  color: $c-burgundy;
	  display: inline-block;
	  width: 10px;
	  height: 10px;
	  margin-right: 10px;
	}
  }
}
