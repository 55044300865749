.body-scheme {
  @include min-screen($breakpoint-sm) {
	display: flex;
  }
  @include min-screen($breakpoint-md) {
	@include desktop-container-padding;
  }

  &._area {
	.body-scheme__scheme {
	  @include min-screen($breakpoint-sm) {
		margin-right: 13px;
		@include smoothValue('margin-right', 13px, 21px, $breakpoint-sm, $breakpoint-lg);
	  }
	}
	.body-scheme__body::after {
	  display: block;
	}
	.body-scheme__switch-base {
	  cursor: default;
	}
	.body-scheme__scheme-back {
	  @include min-screen($breakpoint-sm) {
		display: flex;
	  }
	}
	.body-scheme__area-name {
	  color: $c-burgundy;
	}
	.body-scheme__title {
	  display: flex;
	  align-items: center;
	  height: 40px;
	  margin-bottom: 10px;
	  @include smoothValue('margin-bottom', 10px, 16px, $breakpoint-xxs, $breakpoint-sm);
	}
  }

  &__scheme {
	position: relative;
	background-color: #FBF7F3;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 20px;
	padding: 34px 4px 0 0;
	@include smoothValue('padding-top', 34px, 78px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-top', 78px, 49px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 4px, 9px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-left', 0px, 40px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 37px, 64px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('padding-bottom', 64px, 38px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-sm) {
	  width: 305px;
	  flex-shrink: 0;
	  margin-bottom: 0;
	  margin-right: 26px;
	  @include smoothValue('width', 305px, 595px, $breakpoint-sm, $breakpoint-lg);
	  @include smoothValue('margin-right', 26px, 21px, $breakpoint-sm, $breakpoint-lg);
	}

	&::after {
	  content: '';
	  position: absolute;
	  z-index: 100;
	  top: 0;
	  left: 0;
	  width: 118px;
	  @include min-screen($breakpoint-sm) {
		width: 273px;
	  }
	  height: 100%;
	  background: url('../images/backgrounds/waves-small.png') no-repeat;
	  background-size: contain;
	}
  }
  &__switch-base {
	position: absolute;
	z-index: 200;
	top: 0;
	left: 0;
	padding: 17px 11px 4px 16px;
	@include smoothValue('padding-top', 17px, 36px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-left', 16px, 49px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-right', 11px, 49px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('padding-bottom', 4px, 8px, $breakpoint-sm, $breakpoint-lg);
	border-bottom: 1px solid #d7d7d7;
	cursor: pointer;

	span {
	  display: block;
	  font: 500 12px/1.6 'Uni Neue', sans-serif;
	  @include smoothValue('font-size', 12px, 14px, $breakpoint-xxs, $breakpoint-sm);
	  color: $c-burgundy;
	}
	img {
	  display: block;
	  object-position: center;
	  object-fit: contain;
	  width: 21px;
	  height: 51px;
	  @include smoothValue('width', 21px, 33px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('width', 33px, 52px, $breakpoint-sm, $breakpoint-lg);
	  @include smoothValue('height', 51px, 78px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('height', 78px, 123px, $breakpoint-sm, $breakpoint-lg);
	  margin: 0 auto 4px auto;
	  @include smoothValue('margin-bottom', 4px, 9px, $breakpoint-sm, $breakpoint-lg);
	}
  }
  &__body {
	position: relative;
	z-index: 200;
	width: 186px;
	height: 409px;
	display: block;
	margin: auto;
	@include smoothValue('width', 186px, 220px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('width', 220px, 274px, $breakpoint-sm, $breakpoint-lg);
	@include smoothValue('height', 409px, 483px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('height', 483px, 601px, $breakpoint-sm, $breakpoint-lg);

	// to disable hover effects when we have chosen area
	&::after {
	  display: none;
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: transparent;
	}
  }
  &__base {
	width: 100%;
	height: 100%;
  }
  &__highlight {
	position: absolute;
	cursor: pointer;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
	top: 0;
	left: 0;
  }
  &__scheme-back {
	position: absolute;
	z-index: 200;
	bottom: 0;
	left: 0;
	padding: 11px 0 14px 17px;
	border-top: 1px solid #d7d7d7;
	display: none;
	align-items: center;
	font-size: 12px;
	line-height: 18px;
	text-transform: uppercase;
	color: $c-burgundy;
	cursor: pointer;

	svg {
	  position: relative;
	  transition: all $default-transition-duration $default-transition-function;
	  transform: none;
	  width: 18px;
	  height: 6px;
	  margin-right: 11px;
	}
	&:hover {
	  svg {
		transform: translateX(-10px);
	  }
	}
  }
  &__area-name {
	position: absolute;
	opacity: 0;
	bottom: 14px;
	right: 15px;
	width: auto;
	height: auto;
	@include smoothValue('right', 15px, 21px, $breakpoint-xxs, $breakpoint-sm);
	font-size: 10px;
	@include smoothValue('font-size', 10px, 12px, $breakpoint-xxs, $breakpoint-sm);
	line-height: 1.5;
	text-transform: uppercase;
	@extend .transition-active;
	@include max-screen($breakpoint-xs) {
	  text-align: right;
	  max-width: 80px;
	}
  }
  &__text {
	width: 100%;
	max-width: 595px;
	@include min-screen($breakpoint-sm) {
	  width: auto;
	  flex-grow: 1;
	}
  }
  &__title {
	font: 500 15px/1.7 'Uni Neue', sans-serif;
	text-transform: uppercase;
	@include smoothValue('font-size', 15px, 19px, $breakpoint-xxs, $breakpoint-sm);
	@include min-screen($breakpoint-sm) {
	  line-height: 1.4;
	}
	color: $c-burgundy;
	margin-bottom: 12px;
	@include min-screen($breakpoint-md) {
	  padding-left: 20px;
	  margin-bottom: 20px;
	  margin-top: 10px;
	}
  }
  &__title-back {
	display: inline-flex;
	align-items: center;
	height: 100%;
	padding-right: 18px;
	border-right: 1px solid #d7d7d7;
	margin-right: 18px;
	font-size: 14px;
	cursor: pointer;

	svg {
	  position: relative;
	  transition: all $default-transition-duration $default-transition-function;
	  transform: none;
	  width: 18px;
	  height: 6px;
	  @include min-screen($breakpoint-xs) {
		margin-right: 11px;
	  }
	}
	&:hover {
	  svg {
		transform: translateX(-10px);
	  }
	}
  }
  &__list {
	list-style: none;
  }
  &__list-item {
	font: 500 12px/1.4 'Uni Neue', sans-serif;
	@include smoothValue('font-size', 12px, 15px, $breakpoint-xxs, $breakpoint-sm);
	border-top: 1px solid $c-light-beige;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 9px 0;
	@include max-screen($breakpoint-sm - 1px) {
	  max-width: 90%;
	}
	@include min-screen($breakpoint-md) {
	  padding: 9px 20px;
	}
	&:last-child {
	  border-bottom: 1px solid transparent;
	}
	color: #1b1b1b;
	cursor: pointer;
	@extend .transition-active;

	svg {
	  display: none;
	  width: 18px;
	  height: 6px;
	}

	&:hover, &.hover {
	  color: #A58A63;
	  border-color: #C4B197;

	  & + .body-scheme__list-item {
		border-top: 1px solid #C4B197;
	  }
	  svg {
		display: block;
	  }
	}
  }
}

#name-head {
  @include min-screen($breakpoint-md) {
	top: 104px;
	right: 123px;
	@include smoothValue('right', 123px, 183px, $breakpoint-md, $breakpoint-lg);
  }
}

#name-spine {
  @include min-screen($breakpoint-md) {
	top: 225px;
	right: 23px;
	@include smoothValue('right', 23px, 83px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  right: 43px;
	  @include smoothValue('right', 43px, 103px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-shoulder-left {
  @include min-screen($breakpoint-md) {
	top: 195px;
	left: 22px;
	@include smoothValue('top', 195px, 205px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('left', 22px, 100px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 175px;
	  left: 32px;
	  @include smoothValue('top', 175px, 205px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('left', 32px, 130px, $breakpoint-md, $breakpoint-lg);
	  @include min-screen($breakpoint-lg) {
		top: 130px;
	  }
	}
  }
}

#name-shoulder-right {
  @include min-screen($breakpoint-md) {
	top: 193px;
	right: 13px;
	@include smoothValue('right', 13px, 63px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 183px;
	  right: 28px;
	  @include smoothValue('right', 28px, 83px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-arm-left {
  @include min-screen($breakpoint-md) {
	top: 234px;
	left: 17px;
	@include smoothValue('left', 17px, 107px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 212px;
	  left: 37px;
	  @include smoothValue('left', 37px, 127px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-arm-right {
  @include min-screen($breakpoint-md) {
	top: 228px;
	right: 15px;
	@include smoothValue('right', 15px, 65px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 195px;
	  right: 25px;
	  @include smoothValue('right', 25px, 95px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-ribs-left {
  @include min-screen($breakpoint-md) {
	top: 240px;
	left: 37px;
	@include smoothValue('left', 37px, 155px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 220px;
	  left: 87px;
	  @include smoothValue('left', 87px, 167px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-ribs-right {
  @include min-screen($breakpoint-md) {
	top: 238px;
	right: 55px;
	@include smoothValue('right', 55px, 115px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 205px;
	  right: 75px;
	  @include smoothValue('right', 75px, 145px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-elbow-left {
  @include min-screen($breakpoint-md) {
	top: 259px;
	left: 7px;
	@include smoothValue('left', 7px, 92px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 255px;
	  left: 27px;
	  @include smoothValue('left', 27px, 112px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-elbow-right {
  @include min-screen($breakpoint-md) {
	top: 260px;
	right: 5px;
	@include smoothValue('right', 5px, 55px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 240px;
	  right: 20px;
	  @include smoothValue('right', 20px, 80px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-forearm-left {
  @include min-screen($breakpoint-md) {
	top: 280px;
	left: 10px;
	@include smoothValue('left', 10px, 80px, $breakpoint-md, $breakpoint-lg);
  }
  @include screen($breakpoint-md, $breakpoint-lg - 1px) {
	text-align: right;
	max-width: 80px;
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  text-align: left;
	  max-width: none;
	  left: 14px;
	  top: 260px;
	  @include smoothValue('left', 14px, 100px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-forearm-right {
  @include min-screen($breakpoint-md) {
	top: 280px;
	right: 5px;
	@include smoothValue('right', 5px, 55px, $breakpoint-md, $breakpoint-lg);
  }
  @include screen($breakpoint-md, $breakpoint-lg - 1px) {
	max-width: 80px;
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  max-width: none;
	  right: 8px;
	  @include smoothValue('right', 8px, 70px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-wrist-left {
  @include min-screen($breakpoint-md) {
	top: 334px;
	left: 8px;
	@include smoothValue('left', 8px, 95px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  left: 30px;
	  @include smoothValue('left', 30px, 175px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('top', 334px, 355px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-wrist-right {
  @include min-screen($breakpoint-md) {
	top: 341px;
	right: 42px;
	@include smoothValue('right', 42px, 92px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 321px;
	  right: 72px;
	  @include smoothValue('right', 72px, 137px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('top', 321px, 345px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-pelvis {
  @include min-screen($breakpoint-md) {
	top: 355px;
	right: 76px;
	@include smoothValue('top', 355px, 375px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('right', 76px, 126px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 300px;
	  right: 46px;
	  @include smoothValue('top', 300px, 325px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('right', 46px, 106px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-hip-left {
  @include min-screen($breakpoint-md) {
	top: 352px;
	left: 10px;
	@include smoothValue('left', 22px, 92px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('top', 352px, 372px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 377px;
	  left: 10px;
	  @include smoothValue('left', 10px, 105px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('top', 382px, 402px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-hip-right {
  @include min-screen($breakpoint-md) {
	top: 355px;
	right: 55px;
	@include smoothValue('top', 355px, 375px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('right', 55px, 105px, $breakpoint-md, $breakpoint-lg);
  }
  @include min-screen($breakpoint-lg) {
	right: 55px;
  }
  @include screen($breakpoint-md, $breakpoint-lg - 1px) {
	max-width: 80px;
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  top: 365px;
	  right: 65px;
	  @include smoothValue('top', 365px, 385px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('right', 65px, 105px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-thigh-left {
  @include min-screen($breakpoint-md) {
	bottom: 226px;
	left: 49px;
	@include smoothValue('left', 49px, 129px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include smoothValue('left', 49px, 142px, $breakpoint-md, $breakpoint-lg);
  }
}

#name-thigh-right {
  @include min-screen($breakpoint-md) {
	bottom: 226px;
	right: 23px;
	@include smoothValue('right', 23px, 83px, $breakpoint-md, $breakpoint-lg);
  }
  ._female & {
	@include min-screen($breakpoint-md) {
	  right: 36px;
	  @include smoothValue('right', 36px, 133px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-knee-left {
  @include min-screen($breakpoint-md) {
	bottom: 166px;
	left: 42px;
	@include smoothValue('left', 42px, 122px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 186px;
	  left: 57px;
	  @include smoothValue('left', 57px, 145px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-knee-right {
  @include min-screen($breakpoint-md) {
	bottom: 157px;
	right: 22px;
	@include smoothValue('right', 22px, 82px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 187px;
	  right: 42px;
	  @include smoothValue('right', 42px, 108px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-shin-left {
  @include min-screen($breakpoint-md) {
	bottom: 120px;
	left: 68px;
	@include smoothValue('left', 68px, 148px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 110px;
	  left: 88px;
	  @include smoothValue('left', 88px, 178px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-shin-right {
  @include min-screen($breakpoint-md) {
	bottom: 109px;
	right: 60px;
	@include smoothValue('right', 60px, 120px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  right: 80px;
	  @include smoothValue('right', 80px, 140px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-ankle-left {
  @include min-screen($breakpoint-md) {
	bottom: 92px;
	left: 18px;
	@include smoothValue('left', 18px, 98px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 78px;
	  left: 30px;
	  @include smoothValue('left', 30px, 128px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-ankle-right {
  @include min-screen($breakpoint-md) {
	bottom: 78px;
	right: 6px;
	@include smoothValue('right', 6px, 66px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 78px;
	  right: 20px;
	  @include smoothValue('right', 20px, 86px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('bottom', 78px, 68px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-foot-left {
  @include min-screen($breakpoint-md) {
	bottom: 76px;
	left: 128px;
	@include smoothValue('bottom', 76px, 70px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('left', 128px, 208px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 56px;
	  left: 138px;
	  @include smoothValue('bottom', 56px, 46px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('left', 138px, 234px, $breakpoint-md, $breakpoint-lg);
	}
  }
}

#name-foot-right {
  @include min-screen($breakpoint-md) {
	bottom: 68px;
	right: 110px;
	@include smoothValue('bottom', 68px, 62px, $breakpoint-md, $breakpoint-lg);
	@include smoothValue('right', 110px, 180px, $breakpoint-md, $breakpoint-lg);
  }

  ._female & {
	@include min-screen($breakpoint-md) {
	  bottom: 55px;
	  right: 120px;
	  @include smoothValue('bottom', 55px, 47px, $breakpoint-md, $breakpoint-lg);
	  @include smoothValue('right', 120px, 195px, $breakpoint-md, $breakpoint-lg);
	}
  }
}
