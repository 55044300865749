// для IE 11

._ie {
	.main-fs-pointer__arrow {
		top: -30px;
	}
}

.main-fs {
	display: flex;
	height: 620px;
	padding-top: 186px;
	box-sizing: border-box;

	@include min-screen($breakpoint-xs) {
		padding-top: 154px;
	}
	@include min-screen($breakpoint-sm) {
		height: calc(var(--vh, 1vh) * 100);
		padding-top: 165px;
	}
	@include min-screen($breakpoint-md) {
		padding-top: 132px;
	}
	@include min-screen($breakpoint-lg) {
		padding-top: 144px;
	}
	.container {
		position: relative;
		display: flex;
		height: 100%;
		width: 100%;
	}
	&__wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	  	z-index: 20;
	}

	&__info {
		@include min-screen($breakpoint-sm) {
			margin-top: 20px;
		}
		@include min-screen($breakpoint-md) {
			margin-bottom: 140px;
			transform: translateX(40px);
		}
		@include min-screen($breakpoint-fhd) {
			transform: translateX(-3.5%);
		}
	}

	&__title {
		@extend .main-title;
		transition-delay: 0.25s;
		@include min-screen($breakpoint-xs) {
			text-align: center;
		}
	}
	&__subtitle {
		@extend .subtitle-18-300;
		transition-delay: 0.35s;
		@include max-screen($breakpoint-xs - 1px) {
			span {
				display: block;
			}
		}
		@include min-screen($breakpoint-xs) {
			text-align: center;
		}
		@include min-screen($breakpoint-sm) {
			margin-top: 14px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 10px;
		}
		@include smoothValue('font-size', 18px, 26px, $breakpoint-md, $breakpoint-fhd);
		@include min-screen($breakpoint-lg) {
			font-size: 26px;
		}
	}
}

@keyframes arrow-shake {
	0% {
		transform: translateY(-6px);
	}
	10% {
		transform: translateY(-6px);
	}
	90% {
		transform: translateY(6px);
	}
	100% {
		transform: translateY(6px);
	}
}

.main-fs-pointer {
	max-width: 516px;
	display: flex;
	align-items: flex-end;
	padding-bottom: 32px;
	transition-delay: 0.35s;
	@include min-screen($breakpoint-sm) {
		padding-left: 20px;
	}
	@include min-screen($breakpoint-md) {
		max-width: 443px;
		padding-left: 32px;
	}
	@include min-screen($breakpoint-lg) {
		max-width: 535px;
		padding-left: 48px;
	}

	@include smoothValue('padding-left', 48px, 80px, $breakpoint-lg, $breakpoint-fhd);
	@include smoothValue('max-width', 535px, 670px, $breakpoint-lg, $breakpoint-fhd);
	&__arrow {
		height: 74px;
		flex-shrink: 0;
		animation: arrow-shake 1s infinite cubic-bezier(0.69, 0.01, 0.51, 1.04) alternate;

		@include min-screen($breakpoint-sm) {
			position: absolute;
			left: 0;

			height: 94px;
			width: 8px;
		}
		@include min-screen($breakpoint-md) {
			height: 65px;
		}
		@include min-screen($breakpoint-lg) {
			height: 94px;
		}
	}
	&__text {
		@extend .text-type-book;
		padding-left: 15px;
		@include max-screen($breakpoint-xs - 1px) {
		  	max-width: 267px;
		}
		@include min-screen($breakpoint-md) {
			@include desktop-container-padding-left();
		}
	}
}

.main-fs-quality-approval {
	display: flex;
	align-items: center;
	transition-delay: 0s;
	@include max-screen($breakpoint-sm - 1px) {
		padding-top: 5px;
	}
	&__logo {
		position: relative;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 7px;
		margin-right: 7px;
		padding-top: 6px;
		padding-bottom: 6px;

		@include min-screen($breakpoint-sm) {
			padding-right: 15px;
			margin-right: 15px;
		}
		img {
			width: 32px;
			height: 32px;

			@include min-screen($breakpoint-xs) {
				width: 40px;
				height: 40px;
			}
			@include min-screen($breakpoint-lg) {
				width: 46px;
				height: 46px;
			}
		}
		&::after {
			position: absolute;
			content: '';
			right: 0;
			width: 1px;
			height: 100%;
			opacity: 0.3;
			background-color: $c-beige;
		}
	}
	&__title {
		font-size: 12px;
		line-height: 1;
		@include min-screen($breakpoint-xs) {
			font-size: 14px;
		}
	}
	&__subtitle {
		font-size: 10px;
		line-height: 1;
		margin-top: 6px;
		@include min-screen($breakpoint-xs) {
			font-size: 12px;
		}
	}
}

.main-fs-sphere {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: translate(40%, -6%);
	border-radius: 50%;
	transition: transform $default-transition-duration $default-transition-function,
		opacity $default-transition-duration $default-transition-function;
	transition-delay: 0.35s;

	@include max-screen($breakpoint-sm - 1px) {
		._win & {
			height: 100vmin;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
		}
	}
	@include min-screen($breakpoint-xs) {
		transform: translate(35%, -10%);
	}
	@include min-screen($breakpoint-sm) {
		transform: translate(35%, -5%);
		max-height: 100vmin;
	}
	@include min-screen($breakpoint-md) {
		transform: translate(25%, -5%);
	}
	@include min-screen($breakpoint-lg) {
		height: 120%;
		transform: translate(20%, -20%);
	}
	@include min-screen($breakpoint-fhd) {
		height: 125%;
		transform: translate(13%, -20%);
	}

  	&._video {
	  	display: flex;
	  	align-items: center;
	  	@include min-screen($breakpoint-md) {
		  	align-items: flex-start;
		}
		img {
		  	flex: auto 0 0;
		  	height: 330px;
		  	max-height: 100%;
		  	@include min-screen($breakpoint-xs) {
			  height: 360px;
			}
			@include min-screen($breakpoint-sm) {
			  	height: 380px;
			  	margin-left: 10%;
			}
		  	@include min-screen($breakpoint-md) {
			  	height: 90%;
			  	margin-left: 15%;
			  	margin-top: -3%;
			}
		  	@include min-screen(1280px) {
			  	height: 100%;
			}
		}
	}

	&._fallback {
		background: center center no-repeat;
		background-size: contain;
		._webp & {
			background-image: url('../images/sphere-main-fs/sphere-main-fs.webp');
		}
		._no-webp & {
			background-image: url('../images/sphere-main-fs/sphere-main-fs.png');
		}
	}
}
