.page-404 {
	padding-top: 180px;
	//@include smoothValue('padding-top', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
	//@include smoothValue('padding-top', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
	@include min-screen($breakpoint-xs) {
		padding-top: 152px;
	}
	@include min-screen($breakpoint-md) {
		padding-top: 117px;
	}
  @include min-screen($breakpoint-lg) {
	padding-top: 230px;
  }
	&__wrap {
		@include min-screen($breakpoint-lg) {
		  @include desktop-container-padding-left;
		  min-height: 550px;
		  box-sizing: border-box;
		}
	}
	&__left {
	}
	&__right {
	}
	&__code {
	  font-size: 120px;
	  line-height: 141px;
	  font-family: 'Kudryashev Headline', sans-serif;
	  color: $c-block-beige-bg;
	  @include smoothValue('font-size', 120px, 240px, $breakpoint-xxs, $breakpoint-sm);
	  @include smoothValue('line-height', 141px, 281px, $breakpoint-xxs, $breakpoint-sm);
	  margin-bottom: 15px;
	  @include smoothValue('margin-bottom', 15px, 45px, $breakpoint-xxs, $breakpoint-sm);
	  @include min-screen($breakpoint-lg) {
		margin-bottom: 0;
		margin-right: 20px;
		line-height: 1;
	  }
	}
	&__title {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 23px;
		line-height: 1.2;
		color: #1b1b1b;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
		span {
			color: $c-burgundy;
		}
	}
	&__text {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
	  	max-width: 400px;
		margin-top: 30px;
		color: #1b1b1b;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 30px, 17px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('margin-top', 17px, 5px, $breakpoint-sm, $breakpoint-lg);
	}
	&__search {
		margin-top: 30px;
		@include smoothValue('margin-top', 30px, 42px, $breakpoint-xxs, $breakpoint-sm);
	}
	&__link {
		margin-top: 30px;
		.link-with-arrow {
			color: #1b1b1b;
			font-size: 14px;
			@include smoothValue('font-size', 14px, 18px, $breakpoint-xxs, $breakpoint-sm);
		}
	}
}

.page-404-search {
	&__wrap {
		@include min-screen($breakpoint-sm) {
			padding-top: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid $c-light-beige;
			border-top: 1px solid $c-light-beige;
		}
	}
	&__text {
		color: $c-dark-gray;
		font-family: 'Uni Neue Book', sans-serif;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-xxs, $breakpoint-sm);
		margin-bottom: 10px;
	}
  	&__search {
	  max-width: 436px;

	}
}
