.faq-page-head {
	margin-top: 35px;
	overflow: visible;
	@include smoothValue('margin-top', 35px, 30px, $breakpoint-xxs, $breakpoint-sm);
	@include smoothValue('margin-top', 30px, 50px, $breakpoint-sm, $breakpoint-lg);
	&__title {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 23px;
		line-height: 1.2;
		color: $c-burgundy;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);
	}
	&__search {
		@include tiny-section-mt;
	}
}

.faq-detail-page {
	&__question {
		margin-top: 20px;
		@include min-screen($breakpoint-sm) {
			margin-top: 35px;
		}
	}
	&__answer {
		margin-top: 20px;
		margin-bottom: 20px;
		font-weight: 400;
		font-family: 'Uni Neue Book', sans-serif;
		font-style: normal;
		color: #202020;
		@include min-screen($breakpoint-sm) {
			margin-top: 30px;
			margin-bottom: 30px;
			font-size: 20px;
		}
	}
	&__delimeter {
		display: none;
		@include min-screen($breakpoint-md) {
			display: block;
			margin-top: 35px;
		}
	}
}
