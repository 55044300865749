.article-inner {

  br+br+br {
    display: none
  };
  p {
    br {
      display: none;
    }
  }
  overflow: visible;
  font-size: 14px !important;
	span {
		font-size: 14px !important;
	}
  @include min-screen($breakpoint-sm) {
	font-size: 16px !important;
  	span {
		font-size: 16px !important;
	}
  }
  &__wrap {
	margin-top: 20px;
	@include min-screen($breakpoint-md) {
	  margin-top: 60px;
	}
  }
  & h2 {
	font-family: 'Museo Sans Cyrl', sans-serif;
	font-weight: 300;
	font-size: 20px;
	@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
	line-height: 1.4;
	color: $c-burgundy;
	margin-top: 20px;
	margin-bottom: 10px;

	@include min-screen($breakpoint-sm) {
	  margin-top: 30px;
	  margin-bottom: 15px;
	}
	@include min-screen($breakpoint-md) {
	  margin-top: 40px;
	  margin-bottom: 20px;
	}
  }
  & p {
	font-family: 'Uni Neue Book', sans-serif;
	font-size: 16px !important;
  }
  & p:not(:last-of-type) {
	margin-bottom: 10px;
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 15px;
	}
	@include min-screen($breakpoint-md) {
	  margin-bottom: 20px;
	}
  }
  &>p:first-child {
	@extend .text-17-300;
	margin-bottom: 20px;
	@include max-screen($breakpoint-md) {
	  font-weight: 250;
	}
	@include min-screen($breakpoint-sm) {
	  margin-bottom: 30px;
	}
	@include min-screen($breakpoint-md) {
	  margin-bottom: 40px;
	}
  }
  & ul, & ol {
	font-family: 'Uni Neue Book', sans-serif;
	margin-bottom: 10px;
	@include min-screen($breakpoint-sm) {
	  margin-left: 17px;
	  margin-bottom: 15px;
	  & li {
		padding-left: 33px;
	  }
	}
	@include min-screen($breakpoint-md) {
	  margin-bottom: 20px;
	}
	& li + li {
	  margin-top: 15px;
	}
	@include max-screen($breakpoint-sm - 1px) {
	  & li {
		padding-left: 16px;
		&::before {
		  left: 0;
		}
	  }
	}
  }
  & ul {
	@extend .styled-ul;
  }
  & ol {
	@extend .styled-ol;
  }
  & hr {
	border: none;
	border-top: 1px solid $c-light-beige;
	margin-top: 10px;
	@include min-screen($breakpoint-sm) {
	  margin-top: 15px;
	}
	@include min-screen($breakpoint-md) {
	  margin-top: 20px;
	}
  }
  & a {
	@extend .simple-link;
  }
  & li {
	max-width: 695px;
  }
  .program-card__image {
    img {
      @include min-screen($breakpoint-sm) {
        min-height: unset;
      }
    }
  }

	font-family: 'Uni Neue Book', sans-serif !important;
}
