.resume-review-tile {
	display: flex;
	border: 1px solid $c-light-beige;
	min-height: 420px;
	&__wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 20px 26px;
		background: url('../images/backgrounds/waves-small-right.png') 100% 0 no-repeat;
	}
	&__title {
		display: flex;
		align-items: center;
		font-size: 26px;
		font-family: 'Museo Sans Cyrl', sans-serif;
		font-weight: 100;
		line-height: 1;
		color: $c-burgundy;
		margin-bottom: 30px;

		.g-counter {
			display: inline-block;
			margin-top: 4px;
			margin-left: 10px;
		}
	}
	&__quote {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 1.6;
		font-weight: 500;
		&::before {
			content: '';
			display: inline-block;
			flex-shrink: 0;
			align-self: flex-start;
			width: 23px;
			height: 22px;
			margin-right: 5px;
			background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.96 11.336L18.488 21.996H22.336L13.808 11.336L22.336 0.623998H18.488L9.96 11.336ZM9.128 21.996H12.976L4.448 11.336L12.976 0.623998H9.128L0.6 11.336L9.128 21.996Z' fill='%23D7C5AD'/%3E%3C/svg%3E%0A");
		}
	}
	&__text {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	&__person {
		color: $c-dark-gray;
		font-family: 'Uni Neue Book', sans-serif;
		font-style: italic;
	}
	&__see-all {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
	}
}
