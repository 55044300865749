.contacts-section {
	padding-top: 30px;
	@include smoothValue('padding-top', 30px, 40px, $breakpoint-sm, $breakpoint-md);
	@include min-screen($breakpoint-lg) {
		@include desktop-container-padding();
	}

	&__title {
		@extend .title-h4;
		color: $c-burgundy;
		margin-bottom: 15px;
		@include smoothValue('margin-bottom', 15px, 20px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('margin-bottom', 20px, 30px, $breakpoint-sm, $breakpoint-md);
	}

	&__caption {
		@extend .text-17-300;
		margin-bottom: 30px;
	}
	&__link {
		@extend .simple-link;
		color: $c-burgundy;

		&:hover {
			text-decoration: none;
		}
	}
	&__tiles {
		--tile-contact-offset: 30px;
		display: flex;
		flex-wrap: wrap;
		margin-left: calc(-1 * var(--tile-contact-offset) / 2);
		margin-right: calc(-1 * var(--tile-contact-offset) / 2);
		@include min-screen($breakpoint-lg) {
			--tile-contact-offset: 66px;
		}
	}
	&__tile {
		box-sizing: border-box;
		width: 100%;
		padding: 0 calc(var(--tile-contact-offset) / 2);
		@include min-screen($breakpoint-xs) {
			width: 50%;
		}
		@include min-screen($breakpoint-md) {
			width: 1/3 * 100%;
		}
		margin-bottom: 30px;
		@include smoothValue('margin-bottom', 30px, 50px, $breakpoint-sm, $breakpoint-md);
	}
	&__item {
	}
	&__photo {
		position: relative;
		width: 100%;
		margin-bottom: 20px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $c-beige;
			opacity: 0.2;
			mix-blend-mode: hard-light;
			z-index: 1;
		}
	}
	&__name {
		@extend .title-h6;
		margin-bottom: 10px;
	}
	&__address {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		line-height: 150%;
		color: $c-dark-gray;
	}
}
