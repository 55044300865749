@mixin common-form-checkbox-radio {
	$parent-selector: &;
	display: inline-flex;
	vertical-align: top;
	position: relative;
	align-items: center;
	user-select: none;
	cursor: pointer;
	&__input {
		position: absolute;
		height: 0;
		width: 0;
		opacity: 0;
		&:checked {
			& ~ #{$parent-selector} {
				&__box {
					#{$parent-selector} {
						&__icon {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	&__icon {
		opacity: 0;
	}
	&__label {
		display: inline-block;
		vertical-align: top;
	}
}

.form-checkbox {
	$parent-selector: &;

  	.filter-item & {
	  width: 100%;
	}

	@include common-form-checkbox-radio;
	&__input {
		&:checked {
			& ~ #{$parent-selector} {
				&__box {
					background: $c-beige;
				}
			}
		}
	}
	&__box {
		width: 30px;
		height: 30px;
		background: $c-beige-form;
		transition: background 0.2s ease-out;
	}
	&__icon {
		transition: opacity 0.2s ease-out;
	}
	&__label {
		padding-left: 15px;
		font-family: 'Uni Neue', sans-serif;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.34;
		color: $c-gray-placeholder;
		a {
			color: $c-burgundy;
		}
	}
}
