.sale-info {
	$parent-selector: &;
	@include small-section-mt();

	&._article-top {
		margin-top: 25px;
		@include min-screen($breakpoint-lg) {
			margin-top: 25px;
		}
		@include max-screen($breakpoint-sm - 1px) {
			display: none;
		}
	}
	&._article-content {
		margin-top: 20px;
		overflow: visible;
		.container {
			padding: 0;
		}
		@include min-screen($breakpoint-sm - 1px) {
			display: none;
		}
	}
	&._news-bottom {
		@extend .section-mb;
		@include max-screen($breakpoint-sm - 1px) {
			display: none;
		}
	}
	&._aside {
		margin-top: 40px;
		margin-bottom: 8px;
		.container {
			padding: 0;
		}
	}
	&._mt-0 {
		margin-top: 0;
	}

	&__wrap {
		@include waves;
		position: relative;
		border: 1px solid $c-light-beige;
		padding: 12px 20px 24px;
		background-color: $c-white;
		&:before {
			z-index: 0;
		}
		._aside & {
			margin: 0;
			padding-top: 24px;
			padding-bottom: 20px;
		}
		&:not(._article-content) & {
			margin-left: -5px;
			margin-right: -5px;
		}
	  	._article-content & {
		  	padding: 20px;
		}
		@include min-screen($breakpoint-sm) {
			#{$parent-selector}:not(._aside) & {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 32px 16px;
				margin: 0;
			}
		}
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				@include desktop-container-padding;
				min-height: 180px;
				box-sizing: border-box;
				padding-top: 22px;
				padding-bottom: 22px;
				&::before {
					transform: translate(0);
				}
			}
		}
	}
	&__title {
		font-size: 23px;
		line-height: 1.5;
		font-family: 'Kudryashev Headline', sans-serif;
		color: $c-beige;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-sm, $breakpoint-lg);
		br {
			display: none;
		}
		._aside & {
			font-size: 32px;
			line-height: 1.1;
		}
		@include screen($breakpoint-sm, $breakpoint-md - 1px) {
			#{$parent-selector}:not(._aside) & {
				br {
					display: block;
				}
			}
		}
	}
	&__text {
		@extend .small-text;
		font-family: 'Uni Neue Book', sans-serif;
	  	width: 55%;
	  	@include min-screen($breakpoint-sm) {
		  	width: auto;
		}
		&._main {
			max-width: 220px;
			margin-top: 8px;
			._aside & {
				font-family: 'Uni Neue Book', sans-serif;
				font-weight: normal;
				margin-top: 5px;
				margin-bottom: 8px;
			}
			@include min-screen($breakpoint-lg) {
				#{$parent-selector}:not(._aside) & {
					max-width: 460px;
					margin-top: 0;
				}
			}
		}
		&._book-fs {
			margin-top: 14px;
			max-width: 320px;
			margin-left: auto;
			margin-right: auto;
			._aside & {
				margin-top: 12px;
				max-width: 240px;
				font-size: 12px;
				line-height: 1.34;
			}
			@include min-screen($breakpoint-lg) {
				#{$parent-selector}:not(._aside) & {
					margin-left: 0;
				}
			}
		}
		._aside & {
			font-size: 14px;
			max-width: 300px;
		}
	}
	&__left {
		._aside & {
			position: relative;
		}
		@include min-screen($breakpoint-sm) {
			#{$parent-selector}:not(._aside) & {
				width: 1/3 * 100%;
			}
		}
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				width: 50%;
			}
		}
	}
	&__image {
		position: relative;
		right: -20px;
	  	border-bottom: 1px solid $c-light-beige;
		._aside & {
			right: 0;
			&:before {
				width: auto;
				left: -20px;
				right: -20px;
			}
		}
		@include min-screen($breakpoint-sm) {
			#{$parent-selector}:not(._aside) & {
				position: absolute;
				z-index: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: flex-end;
				margin-left: auto;
				margin-right: auto;
				width: auto;
				height: 90%;
				pointer-events: none;
				&::before {
					display: none;
				}
			}
		  	border-bottom: none;
		}
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				right: auto;
				height: 100%;
				left: 40%;
			}
		}

		img {
			display: block;
			max-width: 55%;
			margin-left: auto;
			margin-right: 10px;
		  	margin-top: -70px;
			mix-blend-mode: multiply;
			._aside & {
				position: relative;
				right: -10px;
				max-width: 189px;
				margin-right: auto;
			}
			@include min-screen($breakpoint-sm) {
				#{$parent-selector}:not(._aside) & {
					max-width: 100%;
					width: auto;
					height: 90%;
					margin-left: auto;
					margin-right: auto;
				  	margin-top: 0;
				}
			}
		}
	}
	&__right {
		position: relative;
		margin-top: 14px;
		text-align: center;
		._aside & {
			margin-top: 20px;
		}
	  	._article-content & {
		  	margin-top: 8px;
		}
		@include min-screen($breakpoint-sm) {
			#{$parent-selector}:not(._aside) & {
				width: 35%;
			}
		}
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				width: 1/4 * 100%;
				text-align: left;
			}
		}
	}
	&__sale {
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				position: absolute;
				right: calc(100% + 36px);
			}
		}
	}
	&__value {
		font-family: 'Kudryashev Headline', sans-serif;
		font-size: 72px;
		line-height: 1;
		color: $c-beige;
		@include smoothValue('font-size', 72px, 94px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 94px, 110px, $breakpoint-sm, $breakpoint-lg);

		sup {
			font-size: 0.5em;
		}
		._aside & {
			font-size: 72px;
		}
	}
	&__desc {
		@extend .small-text;
		color: $c-burgundy;
		text-align: center;
		._aside & {
			margin-top: -9px;
			font-size: 12px;
			html._win & {
				margin-top: 3px;
			}
		}
	}
	&__btn {
		width: 100%;
		height: 40px;
		margin-top: 16px;
		font-size: 12px;
		._aside & {
			width: 114px;
			margin-left: auto;
			margin-right: auto;
		}
		._article-content & {
		  	margin-top: 10px;
		}
		@include min-screen($breakpoint-sm) {
			#{$parent-selector}:not(._aside) & {
				width: 144px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		@include min-screen($breakpoint-lg) {
			#{$parent-selector}:not(._aside) & {
				margin-left: 0;
			}
		}
	}
}
