//для IE 11

._ie{
	.specialization-fs-decors {
		background-color: transparent
	}

	.specialization-fs-decors__doctors ~ .specialization-fs-decors__sphere {
		z-index: 99999999;
		opacity: 0.4;
		&::before {
			content: "";
			height: 120%;
			width: 100%;
			background: rgba(240, 237, 232, 0.4);
			position: absolute;
		}
	}

	.specialization-fs__wrap {
		//min-height: 40px;
	}
}

.specialization-fs {
	$block: &;
	margin-top: 15px;
	&._compact {
		#{$block} {
			&__wrap {
				//border-bottom: 1px solid $c-light-beige;
				min-height: initial;
				padding-bottom: 20px;
				@include min-screen($breakpoint-lg) {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__info {
				@include min-screen($breakpoint-sm) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-grow: 1;
				}
			}
			&__title {
				font: 300 14px/1.4 'Museo Sans Cyrl', sans-serif;
				@include smoothValue('font-size', 14px, 20px, $breakpoint-xxs, $breakpoint-sm);
				@include smoothValue('font-size', 20px, 26px, $breakpoint-sm, $breakpoint-lg);
			  	max-width: 610px;
			}
			&__icon {
				border: 1px solid #f1f1f1;
			}
			&__btn {
				@include min-screen($breakpoint-sm) {
					margin: 0;
				}
			}
		}
	}
	&._with-title {
		& + .catalog {
			.catalog__wrap {
				border: none;
			}
		}
	}
	&._for-specialists {
		#{$block} {
			&__info {
				padding-left: 0;
			}
			&__wrap {
				height: 370px;
				@include min-screen($breakpoint-sm) {
					height: 240px;
				}
				@include smoothValue('min-height', 240px, 340px, $breakpoint-sm, $breakpoint-lg);
				@include min-screen($breakpoint-lg) {
					min-height: initial;
					height: vw(340px, $breakpoint-lg);
					box-sizing: border-box;
					max-height: 420px;
				}
			}
		}
	}
	&__wrap {
		position: relative;
		display: flex;
		margin-left: calc(-1 * var(--container-indent));
		margin-right: calc(-1 * var(--container-indent));
		padding-left: var(--container-indent);
		padding-right: var(--container-indent);
		padding-top: 20px;
		min-height: 330px;
		overflow: hidden;
		@include min-screen($breakpoint-sm) {
			padding-top: 30px;
			min-height: 300px;
		}
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
			min-height: 340px;
			margin-left: 0;
			margin-right: 0;
		}
		._v2 & {
			min-height: 210px;
			@include min-screen($breakpoint-sm) {
				min-height: 300px;
			}
			@include min-screen($breakpoint-lg) {
				min-height: 340px;
			}
		}
	}
	&__icon {
		position: relative;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $c-white;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;
		@include smoothValue('width', 50px, 70px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('width', 70px, 94px, $breakpoint-sm, $breakpoint-lg);
		@include smoothValue('height', 50px, 70px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 70px, 94px, $breakpoint-sm, $breakpoint-lg);

		&::after {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			width: 200%;
			height: 200%;
			background: url('../images/backgrounds/waves-small.png') right top no-repeat;
			background-size: cover;
			mix-blend-mode: multiply;
		}

		svg {
			width: 35px;
			height: 35px;
			@include smoothValue('width', 35px, 56px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('width', 56px, 76px, $breakpoint-sm, $breakpoint-lg);
			@include smoothValue('height', 35px, 56px, $breakpoint-xxs, $breakpoint-sm);
			@include smoothValue('height', 56px, 76px, $breakpoint-sm, $breakpoint-lg);
		}
	}
	&__info {
		padding-left: 10px;
		@include min-screen($breakpoint-sm) {
			padding-left: 20px;
		}
	}
	&__title {
	  	max-width: 800px;
		@extend .title-h2;
		font-size: 23px;
		line-height: 1.2;
		color: $c-burgundy;
		@include smoothValue('font-size', 23px, 38px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 38px, 48px, $breakpoint-sm, $breakpoint-lg);

		@include min-screen($breakpoint-lg) {
			margin-top: 10px;
		}
	  	@include screen($breakpoint-lg + 1px, 1650px) {
		  max-width: 600px;
		}
		@include max-screen($breakpoint-sm - 1px) {
			br {
				display: none;
			}
		}
		&._big {
			@include max-screen($breakpoint-md - 1px) {
				font-size: 38px;
			}
		}
	  	&._mini {
		  	@extend .title-h5;
		  	font-weight: 300;
		  	line-height: 1.4;
		}
	}
	&__btn {
		margin-top: 15px;
		min-width: 186px;
		box-sizing: border-box;
		font-size: 12px;
		height: 40px;
		@include min-screen($breakpoint-sm) {
			width: 229px;
			height: 50px;
			font-size: 14px;
		}
		@include min-screen($breakpoint-md) {
			margin-top: 20px;
		}
		@include min-screen($breakpoint-lg) {
			width: 249px;
		}
	}

	&__links {
		margin-top: 0;
		margin-left: calc(-1 * var(--container-indent));
		margin-right: calc(-1 * var(--container-indent));
		border-top: 1px solid $c-dark-beige;
		border-bottom: none;
		background-color: $c-gray;
		@include min-screen($breakpoint-lg) {
			@include desktop-container-padding;
			margin-left: 0;
			margin-right: 0;

			.container {
				padding: 0;
			}
			.tab-head__wrap {
				padding: 0;
			}
		}
	}

	&__title-wrap {
	}
}

.specialization-fs-head {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid $c-light-beige;
	@include min-screen($breakpoint-sm) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@include min-screen($breakpoint-md) {
		padding-top: 35px;
		padding-bottom: 40px;
	}
	@include min-screen($breakpoint-lg) {
		@include desktop-container-padding-left;
	}
	.specialization-fs__btn {
		@include max-screen($breakpoint-xs - 1px) {
			width: 100%;
		}
	}
	&__content {
		.title-link {
			margin-top: 0;
		}
	  	@include min-screen($breakpoint-sm) {
		  	max-width: 65%;
		}
	  	@include min-screen($breakpoint-md) {
		  	max-width: 75%;
		}
	}
	&__subtitle {
		font-family: 'Uni Neue Book', sans-serif;
		font-size: 14px;
		@include smoothValue('font-size', 14px, 18px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('font-size', 18px, 20px, $breakpoint-sm, $breakpoint-lg);
		@include min-screen($breakpoint-sm) {
			font-family: 'Museo Sans Cyrl', sans-serif;
			font-weight: 100;
		}
	}
}

.specialization-fs-decors {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #f0ede8;
	@include waves;

	img {
		width: 100%;
	}
	&__sphere {
		position: absolute;
		right: 30%;
		mix-blend-mode: multiply;
		opacity: 0.5;
		width: 380px;
		height: 100%;
		top: -30%;
		z-index: 2;
		@include smoothValue('width', 380px, 580px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('width', 580px, 880px, $breakpoint-sm, $breakpoint-lg);

		@include min-screen($breakpoint-xs) {
			right: 50%;
			top: -50%;
		}
		@include min-screen($breakpoint-sm) {
			right: auto;
			left: -40%;
		  	top: -20%;
		}
		@include min-screen($breakpoint-md) {
			left: -25%;
		}

		._v2 & {
			left: 50%;
			@include min-screen($breakpoint-md) {
				left: 30%;
			}
		}
		&._v3 {
			top: -60%;
			left: -40%;
			width: 450px;
			img {
				height: auto;
				width: 100%;
			}
			@include smoothValue('width', 450px, 700px, $breakpoint-sm, $breakpoint-lg);
			@include min-screen($breakpoint-xs) {
				left: -20%;
			}
			@include min-screen($breakpoint-sm) {
				top: -75%;
				bottom: auto;
			}
			@include min-screen($breakpoint-md) {
				left: -10%;
				top: -80%;
			}
			@include min-screen($breakpoint-lg) {
				left: -7%;
			}
		}
	}
	&__doctors {
		position: absolute;
		right: -3%;
	  	@include smoothValue('right', -3%, 0%, $breakpoint-xxs, $breakpoint-xs);
		bottom: 0;
	  	width: 90%;
	  	height: 50%;
		@include smoothValue('width', 90%, 75%, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('height', 50%, 70%, $breakpoint-xxs, $breakpoint-sm);
	  	@include smoothValue('width', 75%, 100%, $breakpoint-sm, $breakpoint-lg);
	  	@include smoothValue('height', 70%, 100%, $breakpoint-sm, $breakpoint-lg);
		img {
			position: relative;
			z-index: 10;
		  	width: 100%;
		  	height: 100%;
		  	object-fit: cover;
		  	object-position: right top;
		  	mix-blend-mode: darken;
		}
	}
	&__3-doctors {
		position: absolute;
		right: -15%;
		bottom: 0;
		width: 400px;
		@include min-screen(400px) {
			right: -5%;
		}
		@include min-screen($breakpoint-sm) {
			right: 3%;
		}
		@include min-screen($breakpoint-md) {
			right: 7%;
		}
		@include smoothValue('width', 400px, 520px, $breakpoint-xxs, $breakpoint-sm);
		@include smoothValue('width', 520px, 660px, $breakpoint-sm, $breakpoint-lg);
		img {
			mix-blend-mode: multiply;
		}
	}
	&__specialist {
		position: absolute;
		right: -15%;
		bottom: 0;
		width: 400px;
		@include min-screen($breakpoint-xs) {
			right: 0;
		}
		@include smoothValue('width', 400px, 460px, $breakpoint-xs, $breakpoint-sm);
		@include smoothValue('width', 460px, 800px, $breakpoint-sm, $breakpoint-lg);
		img {
			mix-blend-mode: multiply;
			width: 100%;
			height: auto;
		}
	}
}
